import React, {Component} from 'react'
import {StyleSheet, View, Image, Platform, TouchableWithoutFeedback} from 'react-native'
import Time from '../Time'
import {CardContainer} from 'core/components'
import {Link} from 'core/router'

export default class Card extends Component {
    render() {
        const {video, children, target, onPress, link} = this.props

        return (
            <React.Fragment>
                <Link
                    target={target}
                    onPress={onPress ? () => onPress(video) : null}
                    to={link ? link.to : 'videos/show'}
                    params={link ? link.params : {slug: video.slug}}>
                    <CardContainer background={{
                        image: video.thumbnail
                    }}>
                        <Time time={video.length.seconds} right={15} bottom={15}/>
                    </CardContainer>
                </Link>
                {children}
            </React.Fragment>
        )
    }
}

