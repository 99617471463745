import React, {useRef, useState, useEffect} from 'react'
import {ScrollView, Text, View} from 'react-native'
import {StyleSheet} from 'react-native'
import GameEventDataService from '../../services/GameEventDataService'
import HighlightItem from './Item'
import DataService from '../../services/DataService'

/**
 * HighlightBox.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function HighlightBox(props) {

    const [roomId, setRoomId] = useState(props.roomId)
    const [events, setEvents] = useState([])
    const dataService = new GameEventDataService(roomId)

    useEffect(() => {
        dataService.listen(events, setEvents)
        return () => {
            dataService.unListen()
        }
    }, [])

    const createTestData = async () => {
        dataService.addEvent('0:10', 'Doelpunt 0290', '', 'soccer', 'test');
        dataService.addEvent('0:20', 'Test', '', 'soccer', 'say what');
    }

    // useEffect(() => {
    //     createTestData()
    // }, []);

    if (!events) {
        return <></>
    }

    // console.log(events)

    return <ScrollView className={'scroll-view'} style={styles.container}>
        <>
            {
                events && events.slice(0).reverse().map((event, index) => <HighlightItem
                        key={index}
                        eventTimeInSeconds={event.meta.seekPositionSecond}
                        labelText={event.label}
                        titleText={event.title}
                        iconType={event.type}
                        clickEvent={props.clickEvent}
                        subType={props.subType}
                    />,
                )}
        </>
        <>
            {!events.length &&
            <Text className={'highlights-text'} style={styles.single} key="noHighlightsTxt">Er zijn nog geen
                highlights</Text>
            }
        </>
    </ScrollView>

}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    single: {
        textAlign: 'center'
    },
})
