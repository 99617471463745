import {StyleSheet, Text, TouchableWithoutFeedback, View} from 'react-native'

const Link = props => <TouchableWithoutFeedback {...props}>
    <View>
        <Text style={styles.link}>{props.children}</Text>
    </View>
</TouchableWithoutFeedback>;

export default Link;

const styles = StyleSheet.create({
    link: {
        fontWeight: 'bold',
        fontSize: 12,
        textAlign: 'center',
        color: '#787878',
        display: 'block'
    }
})
