import {Icons, Texts} from '@eyecons/storybook'
import {useShow} from 'ra-fetch'
import {useEffect} from 'react'

export default function Verify({user, query}) {
    const [verify, setVerify, submitVerify] = useShow('email.verify', query)

    useEffect(() => {
        submitVerify().then(() => {

        })
    }, [])

    if (verify.loading) {
        return <Icons.Icon name={'loader'}/>
    }

    return <>
        <Texts.Heading type={'h5'}>Email geverificeerd</Texts.Heading>
    </>
}
