import React from 'react'
import {StyleSheet} from 'react-native'
import {Link} from 'core/router'
import {Grid, H2} from 'core/style'

const ChannelTitle = ({to, id, center, style, icon, title}) => <Link to={to} params={{id: id}}>
    <Grid
        justify={center ? 'center' : 'flex-start'}
        style={styles.container}
        className={'video-channel-title'}>
        {icon && icon()}
        <H2>{title}</H2>
    </Grid>
</Link>;

export default ChannelTitle;

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        marginBottom: 20,
        paddingHorizontal: 10,
    }
})
