import DataService from "./DataService";

/**
 * ChatRoomDataService.
 */
class ChatRoomDataService extends DataService {

    constructor(roomId) {
        super("/room/" + roomId + '/chat')
    }

    listen(data, callback) {
        this.getRef(this.ref).child('messages').orderByValue().limitToLast(
            DataService.RESULT_LIMIT).on('child_added', (value) => {
            callback(data => [...data, this.onChildAdded(value)])
        })
    }

    unListen() {
        this.getRef(this.ref).child('messages').limitToLast(DataService.RESULT_LIMIT).off(
            'child_added',() => {})
    }

    onChildAdded(snapshot) {
        let message = snapshot.val()
        message.key = snapshot.key
        return message
    }

    sendMessage(username, message) {
        let data = {
            username: username,
            message: message,
            time: new Date().getTime().toString()
        }

        const newChatMessageKey = super.getAll().child('messages').push().key

        return super.update('/messages/' + newChatMessageKey, data)
    }
}

export default ChatRoomDataService;
