const Users = {
    UsersSection: {
        maxWidth: 400,
        marginHorizontal: 'auto',
        width: '100%',
        marginBottom: '1.75rem',
    },
}

export default Users
