import client from 'core/client/Client'
export const DESTROY_SUCCESS = 'DESTROY_SUCCESS'
import {loading, errorMessage} from 'core/store'

export const destroySuccess = (route, params, data) => {
    return {
        type: DESTROY_SUCCESS,
        route: route,
        params: params,
        data: data
    }
}

export const destroy = (route, params, token) => {
    return (dispatch) => {
        dispatch(loading(true, route))
        return client.destroy(route, params, token)
            .then((response) => {
                dispatch(destroySuccess(route, params, response))
                dispatch(loading(false, route))

                return response
            })
            .catch((error) => {
                dispatch(loading(false, route))
                return errorMessage(route, dispatch, error, token)
            })
    }
}
