import React from 'react'
import {Image, Text, View} from 'react-native'
import {StyleSheet} from 'react-native'
import {ElementDiv} from '../Elements'

/**
 * Highlight item.
 * @returns {JSX.Element}
 * @constructor
 */
export default function HighlightItem(
    {
        eventTimeInSeconds,
        labelText,
        titleText,
        clickEvent,
    }) {

    const icon = {
        uri: 'https://reactnative.dev/img/tiny_logo.png',
        width: 32,
        height: 32,
    }

    const onClickEvent = () => {
        clickEvent(eventTimeInSeconds)
    }

    return <View style={styles.container} onClick={onClickEvent}>
        <View style={styles.inner}>
            <View style={styles.event}>
                {eventTimeInSeconds && <Text key={labelText} style={styles.label}>{eventTimeInSeconds.time()}</Text>}
                <ElementDiv style={styles.info}>
                    <Text key={labelText} style={styles.title}>{labelText}</Text>
                    <Text key={titleText} style={styles.club}>{titleText}</Text>
                </ElementDiv>
            </View>
            <ElementDiv style={styles.iconBg}>
                <Image style={styles.eventIcon} source={`/static/icons/replay.svg`} />
            </ElementDiv>
        </View>
    </View>
}

const styles = StyleSheet.create({
    container: {
        borderBottomWidth: 1,
        borderBottomColor: '#C1C0D0',
        cursor: 'pointer',
    },
    inner: {
        padding: 20,
        // cursor:'pointer',
    },
    event: {
        alignSelf: 'flex-start',
        width: '100%',
    },
    info: {
        position: 'absolute',
        left: 80,
        padding: 7,
        top: '50%',
        transform: ([{translateY: '-50%'}]),
    },
    label: {
        textAlign: 'center',
        alignSelf: 'flex-start',
        justifyContent: 'flex-start',
        padding: 8,
        borderRadius: 10,
        backgroundColor: '#C1C0D0',
        color: '#10083E',
        fontSize: 12,
        fontWeight: 'bold',
    },
    title: {
        color: '#10083E',
        textAlign: 'center',
        fontSize: 16,
        fontWeight: 'bold',
    },
    club: {
        textTransform: 'uppercase',
        color: '#adb5bd',
        fontSize: 12,
    },
    iconBg: {
        position: 'absolute',
        right: 40,
        backgroundColor: '#10083E',
        padding: 4,
        borderRadius: '50%',
        height: 40,
        width: 40,
    },
    eventIcon: {
        height: 25,
        width: 25,
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: ([{translate: '-50%, -50%'}])
    },
})
