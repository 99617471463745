import React, {useState} from 'react'
import {
    FormSubmitIcon,
    FormSubmitText,
    Input,
    FormSubmit,
    Errors,
    FormTitle,
    Message,

    ElementText,

} from 'core/components'
import {connect} from 'react-redux'
import {in_iframe, page_url, redirect} from 'core/functions'
import {useApi} from 'ra-fetch'

function digits2(number) {
    return number < 10 ? `0${number}` : number
}

function usePaid(user, payment, video, query) {
    let options = in_iframe() ? {page: query.parent || page_url()} : {}
    const date = new Date()
    const today = `${digits2(date.getDate())}-${digits2(date.getMonth() + 1)}-${date.getFullYear()}`

    const model = {
        price: video.price.amount,
        description: `${video.channel.label}: ${video.title}`,
        orderable_type: video.has_event ? 'events' : 'videos',
        orderable_id: video.has_event ? video.event_id : video.id,
        options: payment.options || options,
        channel_id: video.channel.id,
        voucher_id: null,
        discounted_price: null
    }

    return useApi('ecommerce').store('orders', model)
}

const Payment = ({payment = {}, query, video, simple = false, user = {}}) => {
    const [paying, setPaying] = useState(false)
    const [order, setOrder, submitOrder] = usePaid(user, payment, video, query)
    const [voucher, setVoucher, submitVoucher] = useApi('ecommerce').store('vouchers.validate', {
        external_id: video.event_id,
        external_type: 'events',
        hash: '',
    })

    if (paying) {
        return <FormSubmit onPress={() => {
            redirect(query.parent || page_url())
        }}>Betaald? Klik hier</FormSubmit>
    }

    function send() {
        submitOrder().then(response => {
            if (response.data.url) {
                setPaying(true)
                redirect(response.data.url)
            }
        })
    }

    function sendVoucher() {
        submitVoucher().then(response => {
            if (!response.errors.length) {
                setOrder({
                    voucher_id: response.data.id,
                    discounted_price: response.data.discounted_price,
                })
            }
        })
    }

    function showPrice() {
        const price = order.data.price.price('EUR', 'nl-NL')
        const discountedPrice = order.data.discounted_price

        if (!discountedPrice) {
            return price
        }

        return <>
            <span style={{textDecoration: 'line-through'}}>{price}</span>
            <span className={'text-green-500'}>&nbsp;{discountedPrice.price('EUR', 'nl-NL')}</span>
        </>
    }

    const validateVoucher = voucher.data.hash && !order.data.voucher_id

    return <>
        {simple && <FormTitle>Kijk live voor {showPrice()}</FormTitle>}

        {
            !simple && <FormSubmitIcon icon={'ticket'} onPress={send}>
                {`Dagticket ${price}`}
                <FormSubmitText>Betaal jouw club voor toegang.</FormSubmitText>
                <FormSubmitText>Heb je een voucher? Klik dan op de link die je hebt ontvangen.</FormSubmitText>
            </FormSubmitIcon>
        }

        <ElementText
            className={'mb-0'}
            style={{
                marginTop: '1.25rem',
            }}
            bold
        >Heb je een promo code? Vul hem hier in</ElementText>
        <Input
            type={'text'}
            placeholder={'PROMO CODE'}
            onChangeText={hash => setVoucher({hash})}
            value={voucher.data.hash}
        />

        <Errors errors={order.errors.concat(voucher.errors)}/>

        {
            simple && <FormSubmit
                type={'full'}
                onPress={validateVoucher ? sendVoucher : send}
                loading={order.submitting}>
                {validateVoucher ? 'Valideren' : 'Betalen'}
            </FormSubmit>
        }

    </>
}

export default connect(state => {
        return {
            query: state.query,
        }
    },
)(Payment)
