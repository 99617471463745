import React, {Component} from 'react'
import {connect} from 'react-redux'
import {View, Text, StyleSheet} from 'react-native'

class ProgressBar extends Component {
    render() {
        const {progress} = this.props

        return (
            <View style={[styles.bar, {
                display: progress > 0 ? 'block' : 'none',
                backgroundColor: ''
            }]} progress={progress}>
                {progress > 0 && <View className={'progress'} style={[styles.progress, {
                    width: `${progress}%`,
                    backgroundColor: progress < 100 ? '#FF0062' : '#7ed456'
                }]}/>}
            </View>
        )
    }
}

const styles = StyleSheet.create({
    bar: {
        height: 5,
        width: '100%',
        position: 'fixed',
        top: 0,
        zIndex: 1100,
        backgroundColor: '#eeeeee'
    },
    progress: {
        width: '0',
        height: '100%'
    }
})

export default connect(state => {
    return {
        progress: state.progress
    }
})(ProgressBar)
