import React from 'react'
import {View, StyleSheet} from 'react-native'
import Container from './Container'
import {VideoCardOld, VideoTitle, VideoInfo, VideoLabels} from 'core/components'

const Default = props => {
    const {video} = props

    return (
        <Container style={{
            container: styles.container,
            content: styles.content,
        }} sm={6} md={3} className={'video-card-default'}>
            <VideoCardOld {...props} video={video}>
                <VideoTitle {...props} style={styles.title}/>
                <VideoInfo>
                    <VideoLabels video={video} channel category date/>
                </VideoInfo>
            </VideoCardOld>
        </Container>
    )
};

export default Default;

const styles = StyleSheet.create({
    container: {
        borderColor: '#dfdfdf',
        borderBottomWidth: 1,
        paddingHorizontal: 0,
    },
    content: {
        paddingHorizontal: 10,
        backgroundColor: 'white',
    },
    title: {
        marginTop: 17,
    }
})
