import React, {useState} from 'react'
import * as Shared from './shared'
import {Wall} from 'core/components'

/**
 * Player used for Wall videos
 * @param video
 * @param videos
 */
const PlayerWall = ({video, videos}) => {
    const [playing, setPlaying] = useState(false)
    const [current, setCurrent] = useState(video)
    const [wallState, setWallState] = useState('loaded')

    return <>
        <Wall
            current={current}
            video={video}
            videos={videos}
            play={() => setPlaying(true)}
            wallState={wallState}
            playing={playing}
            setVideo={(video) => {
                setCurrent(video)
                setPlaying(true)
                setWallState('played')
            }}/>
        <Shared.PlayerWithShare autoplay={playing} playable={playing} setPlaying={setPlaying} video={current}/>
    </>
}

export default PlayerWall
