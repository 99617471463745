const Wall = {
    WallContainer: {
        position: 'relative',
        width: '100%',
        height: '100%',
        padding: 0,
        paddingTop: '56.25%',
        overflow: 'hidden',
        backgroundColor: 'colors.blues.400'
    },
    WallPlayContainer: {
        paddingLeft: '1rem'
    },
    WallTitleContainer: {
        width: '100%',
        paddingHorizontal: '1rem'
    },
    WallTitle: {
        cursor: 'pointer',
        color: 'colors.white',
        paddingLeft: '1rem',
        paddingRight: '1rem',
        marginBottom: 0,
    },
    WallOverlay: {
      zIndex: 998
    },
    WallInterface: {
        height: '100%',
        zIndex: 999
    },
    WallPlay: {
        width: 40,
        height: 40,
        backgroundColor: 'colors.blues.200',
        color: 'colors.white',
        textAlign: 'center',
        lineHeight: '40px',
        fontSize: '1.5rem',
        fontFamily: 'fontFamilies.3',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    WallContinue: {
        fontSize: '1.5rem',
        marginBottom: 0,
        fontWeight: 'bold'
    },
    WallContinueDescription: {
        color: 'colors.grays.400',
        fontSize: '1rem',
        marginLeft: 5,
        fontWeight: '500'
    },
    WallRelated: {
        position: 'relative',
        zIndex: 10
    },
    WallRelatedWrapper: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        paddingHorizontal: '1rem',
        paddingBottom: '1rem'
    },
    WallRelatedVideo: {
        width: '11%',
        marginRight: 10
    }
}

export default Wall
