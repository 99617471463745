import React from 'react'
import {Text, StyleSheet, Image} from 'react-native'
import {Grid} from 'core/style'
import {ElementDiv} from 'core/components'

const Time = props => <ElementDiv
    className={props.className ? `${props.className} video-time` : 'video-time'}
    style={[
        {
            bottom: props.bottom || 'auto',
            right: props.right,
            top: props.top || 'auto',
            position: 'absolute',
        },
        props.style || {}
    ]}>
    <Grid>
        <ElementDiv style={[styles.timeContainer, {
            backgroundColor: props.color || 'rgba(24, 33, 55, 0.7)'
        }]}>
            <Text style={styles.time}>{props.time ? props.time.time() : '0:0'} {props.flame}</Text>
        </ElementDiv>
    </Grid>
</ElementDiv>;

export default Time;

const styles = StyleSheet.create({
    timeContainer: {
        width: 50,
        paddingVertical: 3,
        borderRadius: 4,
    },
    time: {
        color: 'white',
        fontSize: 12,
        textAlign: 'center',
        fontWeight: 'bold'
    },
    flame: {
        height: 20,
        width: 16,
        marginRight: 5
    }
})
