import React, {Component} from 'react'
import {route, Router} from 'core/router'
import {connect} from 'react-redux'
import {progressUpdate} from 'core/store'

class RouterLink extends Component {
    handleClick = (e) => {
        const {target, to, params, progressUpdate, noprogress} = this.props

        if (target === '_blank') {
            return
        }

        const router = route(to, params)

        if (!noprogress) {
            router.progress(progressUpdate)
        }

        e.preventDefault()
        router.redirect()
    }

    render() {
        const {children, className, target, style, to, params} = this.props

        return (
            <a
                href={Router.getRoutePath(to, params || {})}
                onClick={this.handleClick}
                target={target || ''}
                className={className}
                style={style}>
                {children}
            </a>
        )
    }
}

export default connect(null, dispatch => {
    return {
        progressUpdate: progress => {
            dispatch(progressUpdate(progress))
        }
    }
})(RouterLink)
