import {
    SET_STATE,
    UPLOAD_SUCCESS,
    PROGRESS_UPDATE,
    SAVE_SUCCESS,
    LOADING,
    FONT_LOADED,
    RETRIEVE_SUCCESS,
    DESTROY_SUCCESS,
} from 'core/store'

let initialState = {
    pages: [],
    user: {},
    user_nologin: {},
    loading: {
        videos_trending: false,
        videos_users: false,
        videos: false,
        categories: false,
        livestreams: false,
        playlists: false,
        user: false,
        user_nologin: false,
        users: false,
        search_videos: false,
        search_channels: false,
        livestream_videos: false,
        livestream_counts: false,
        vouchers: false,
        sport: false
    },
    progress: 0,
    font: false,
    playlist: [],
    recommended: [],
    video: {},
    video_params: {},
    videos: {
        data: [],
        links: {},
        meta: {}
    },
    events: {
        data: [],
        links: {},
        meta: {}
    },
    videos_trending: {
        data: [],
        links: {},
        meta: {}
    },
    livestream_counts: {
        data: {},
        links: {},
        meta: {}
    },
    categories: [],
    category: {},
    teams: [],
    channel: {},
    channels: [],
    users: [],
    user_channels: [],
    sport: {},
    sports: [],
    roles: [],
    scores: [],
    livestreams: [],
    playlists: [],
    payments: [],
    payment: {},
    livestream_videos: [],
    search_videos: [],
    search_channels: [],
    vouchers: [],
    redeem: {},
    query: {}
}

export function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_STATE:
            if (state.hasOwnProperty(action.key)) {
                state[action.key] = action.value
            }
            return Object.assign({}, state)
        case UPLOAD_SUCCESS:
            state.video = action.video
            return Object.assign({}, state)
        case PROGRESS_UPDATE:
            state.progress = action.progress
            return Object.assign({}, state)
        case SAVE_SUCCESS:
            if (!state.hasOwnProperty(action.route)) {
                return Object.assign({}, state)
            }
            if (Array.isArray(state[action.route])) {
                state[action.route] = state[action.route].save(action.data)
                return Object.assign({}, state)
            }

            if (state[action.route].data) {
                state[action.route].data = state[action.route].data.save(action.data)
                return Object.assign({}, state)
            }

            state[action.route] = action.data
            return Object.assign({}, state)
        case RETRIEVE_SUCCESS:
            if (!state.hasOwnProperty(action.route)) {
                return Object.assign({}, state)
            }

            if (action.loadMore) {
                let data = state[action.route].data.concat(action.data.data)
                delete action.data.data
                state[action.route] = {...action.data, data}
                return Object.assign({}, state)
            }

            if (action.append) {
                state[action.route].push(action.data)
                return Object.assign({}, state)
            }

            state[action.route] = action.data
            return Object.assign({}, state)
        case DESTROY_SUCCESS:
            if (!state.hasOwnProperty(action.route)) {
                return Object.assign({}, state)
            }
            if (Array.isArray(state[action.route])) {
                state[action.route] = state[action.route].delete(action.params.id, 'id')
                return Object.assign({}, state)
            }

            state[action.route].data = state[action.route].data.delete(action.params.id, 'id')
            return Object.assign({}, state)
        case LOADING:
            if (state.loading.hasOwnProperty(action.route)) {
                state.loading[action.route] = action.loading
            }
            return Object.assign({}, state)
        case FONT_LOADED:
            return {...state, font: action.font}
        default:
            return state
    }
}
