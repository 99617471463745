import React, {useState} from 'react'
import {connect} from 'react-redux'
import {ProfileForm, BackToLogin, PasswordFields, initialPasswordInfo, validatePassword} from 'core/components'
import {translate} from 'core/functions'
import {useLocale} from 'core/hooks'
import {useApi} from 'ra-fetch'
import {setState} from 'core/store'
import {Forms, Texts} from '@eyecons/storybook'

function initUser(email, commercial, mode) {
    const user = {
        email: mode === 'nologin' ? email : '',
        options: {
            terms: mode === 'nologin',
            commercial: Boolean((commercial && mode === 'nologin')),
        },
    }

    if (mode !== 'nologin') {
        user.password = ''
        user.password_confirmation = ''
    }

    return user
}

function useRegister(mode, query) {
    const user = initUser(query.email, query.commercial, mode)

    if (mode === 'nologin') {
        return useApi('site').store('users.nologin', user)
    }

    return useApi('auth').store('register', user)
}

const Register = ({title = 'Register', login, query = {}, setState}) => {
    const [errors, setErrors] = useState([])
    const [page, setPage] = useState('register')
    const [user, setUser, submitUser] = useRegister(query.type, query)
    const [passwordInfo, setPasswordInfo] = useState(initialPasswordInfo)
    const [Locale, locale] = useLocale(query.locale || 'nl')
    const [submitting, setSubmitting] = useState(false)

    function submit() {
        if (!user.data.options.terms) {
            return setErrors([
                {
                    message: translate(query.type === 'nologin' ? 'To watch live you have to agree with the terms and conditions' : 'To register you have to agree with the terms and conditions', locale),
                },
            ])
        }
        setErrors([])

        if (query.type !== 'nologin' && !validatePassword(user.data, passwordInfo, setPasswordInfo, locale)) {
            return
        }

        setSubmitting(true)
        submitUser({locale}).then((response) => {
            setSubmitting(false)
            if (response.errors.length) {
                return setErrors(response.errors)
            }

            if (query.type === 'nologin') {
                return setState('user_nologin', response.data)
            }

            setPage('update-profile')
        })
    }

    if (page === 'update-profile') {
        return <ProfileForm locale={locale}/>
    }

    return <>
        <div className={'relative text-center mb-4'}>
            {query.type !== 'nologin' && <Locale/>}
            {title && <Texts.Heading type={'h5'} appendClassname={'text-dark'}>{translate(title, locale)}</Texts.Heading>}
        </div>

        {query.type !== 'nologin' && <BackToLogin {...login || {}} locale={locale}/>}

        {
            query.type !== 'nologin' && <>
                <PasswordFields
                    locale={locale}
                    user={user.data}
                    setUser={setUser}
                    passwordInfo={passwordInfo}
                    setPasswordInfo={setPasswordInfo}
                />

                <Forms.Checkbox
                    className={'mb-2'}
                    id={'terms'}
                    value={user.data.options.terms}
                    onChange={terms => setUser({options: {...user.data.options, terms}})}
                >
                    {translate('I agree with the', locale) + ' '}
                    <Texts.Link target={'_blank'} href={`${process.env.NEXT_PUBLIC_STORYBOOK_SITE_URL}/terms-and-conditions`}>{translate('Terms and Conditions', locale)}</Texts.Link>
                    {' ' + translate('from Eyecons', locale)}
                </Forms.Checkbox>
            </>
        }

        {
            (!query.commercial || query.type !== 'nologin') && <Forms.Checkbox
                id={'commercial'}
                value={user.data.options.commercial}
                onChange={commercial => setUser({options: {...user.data.options, commercial}})}
            >
                {translate('I give Eyecons and its partners permission to send me e-mail messages with offers, promotions and other promotional expressions. You can undo this at any time, read our', locale) + ' '}
                <Texts.Link target={'_blank'} href={`${process.env.NEXT_PUBLIC_STORYBOOK_SITE_URL}/privacy`}>{translate('Privacy Policy', locale)}</Texts.Link>
                {' ' + translate('for more information.', locale)}
            </Forms.Checkbox>
        }

        <Forms.Buttons
            icon={query.type === 'nologin' ? null : 'chevron-right'}
            icon_position={'right'}
            type={'wide'}
            disabled={submitting}
            className={'mb-4'}
            errors={errors}
            title={query.type === 'nologin' ? translate('Accept', locale) : `${translate('Register', locale)} (1/2)`}
            submit={submit}
        />
    </>
}

export default connect(state => {
        return {
            query: state.query
        }
    }, dispatch => {
        return {
            setState: (key, value) => {
                return dispatch(setState(key, value))
            },
        }
    },
)(Register)
