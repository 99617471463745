const Footer = {
    FooterLink: {
        color: 'colors.blues.400',
        marginBottom: '.75rem'
    },
    Footer: {
        maxWidth: '100%',
        paddingVertical: '2rem',
        paddingHorizontal: '1rem',
    },
}

export default Footer
