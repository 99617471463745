import React from 'react'
import {StyleSheet, Text} from 'react-native'
import {Submit} from 'core/components'

const Button = props => <Submit {...props} style={styles.button}/>;

export default Button;

const styles = StyleSheet.create({
    button: {
        maxWidth: 200,
        marginBottom: 16,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 27
    }
})
