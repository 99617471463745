import React from'react'
import {StyleSheet, Text} from 'react-native'
import {Column} from 'core/style'

const Label = props => {
    if (props.xs) {
        return (
            <Column {...props}>
                <Text className={'video-card-label'} style={[styles.label, props.right ? styles.right : {}]}>{props.children}</Text>
            </Column>
        )
    }
    return <Text style={[styles.label, props.right ? styles.right : {}]}>{props.children}</Text>
};

export default Label;

const styles = StyleSheet.create({
    label: {
        color: '#c5c7cd',
        fontSize: 13,
        lineHeight: 15
    },
    right: {
        textAlign: 'right'
    }
})
