const Archive = {
    ArchiveMenu: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 40,
        alignItems: 'baseline',
        justifyContent: 'space-between',
    },
}

export default Archive
