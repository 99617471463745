import React from 'react'
import {StyleSheet, View, TouchableWithoutFeedback} from 'react-native'
import {Row} from 'core/style'
import {VideoLabel} from 'core/components'
import {Link} from 'core/router'

const Labels = ({video, children, channel, uploader, category, date}) => {
    const video_channel = video.channel || {}

    return (
        <Row style={styles.labels}>
            {
                channel &&
                <Link xs={6} className={'col-md-12 col-lg-6'} to={'channels/show'} params={{id: video_channel.name}}>
                    <VideoLabel>{video_channel.label}</VideoLabel>
                </Link>
            }
            {uploader && <VideoLabel xs={12}>Geupload door {video_channel.label}</VideoLabel>}
            {
                category && video.category &&
                <VideoLabel xs={6} className={'col-md-12 col-lg-6'} right>{video.category.label}</VideoLabel>
            }
            {
                date &&
                <VideoLabel
                    xs={6}
                    className={'col-md-12 col-lg-6'}
                    right={(!video.category || !category)}>{video.created_at.label}</VideoLabel>}
            {children}
        </Row>
    )
};

export default Labels;

const styles = StyleSheet.create({
    labels: {
        marginBottom: 17,
        alignItems: 'center',
    },
})
