import {logout} from 'core/store'
import {config, is_string} from 'core/functions'

function errorMessages(response) {
    let errors = []
    for (let field in response.errors) {
        errors.push({
            field,
            message: response.errors[field][0],
        })
    }
    return {...response, errors}
}

export const errorMessage = (route, dispatch, error = {}, token) => {
    let response = {message: 'Oeps er is iets fout gegaan!'}
    if (error.response && error.response.data) {
        response = is_string(error.response.data) ? {message: error.response.data} : errorMessages(error.response.data)
    }

    if (config('app.debug')) {
        console.log(route, response)
    }

    return response
}
