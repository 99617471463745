const Card = {
    Card: {
        borderRadius: 6,
        overflow: 'hidden',
        marginBottom: 10,
        width: '100%'
    },
    CardThumbnailContainer: {
        minWidth: 25,
        minHeight: 25,
        width: '100%',
        borderRadius: 6,
        overflow: 'hidden',
        backgroundColor: 'colors.blues.400'
    },
    CardThumbnail: {
        position: 'relative',
        width: '100%',
        paddingTop: '56.25%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover'
    },
    CardBody: {
        paddingVertical: '1rem',
        paddingHorizontal: '0.5rem'
    },
}

export default Card
