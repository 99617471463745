import React, {useState, useEffect} from 'react'
import {View, Text as BaseText, TouchableWithoutFeedback} from 'react-native'
import {
    RawTheme,
    Theme,
    className,
    style,
    rawStyle,
    ThemeDefaults,
    Icons,
} from 'core/style'
import {Picture} from 'core/components'
import {Link as BaseLink} from 'core/router'
import {scroll_off, scroll_on, config} from 'core/functions'
import {Waypoint} from 'react-waypoint'
import {Icons as StorybookIcons} from '@eyecons/storybook'

const headings = {
    h1: (props) => <h1 {...props}>{props.children}</h1>,
    h2: (props) => <h2 {...props}>{props.children}</h2>,
    h3: (props) => <h3 {...props}>{props.children}</h3>,
    h4: (props) => <h4 {...props}>{props.children}</h4>,
}

/**
 * Element Scrollable Container
 * @param props (type: parent)
 * @returns {*}
 * @constructor
 */
export const ElementScrollContainer = (props) => {
    let additionalProps = {}

    if (props.container) {
        additionalProps.ref = props.container
    }

    return <div
        {...additionalProps}
        style={rawStyle(props.style || {}, props)}
        className={className(`elements-scroll-container${props.type ? '-' + props.type : ''}`, props)}>
        {props.children}
    </div>
}

export const ElementScrollItem = (props) => <ElementDiv
    style={props.style}
    className={className('elements-scroll-item', props)}>
    {props.children}
</ElementDiv>

/**
 * ElementLayout types (default, space, header, dashboard)
 * @param props, {type}
 * @returns {*}
 * @constructor
 */
export const ElementLayout = (props) => <ElementDiv
    {...props}
    className={className(`elements-layout elements-layout-${props.type}`, props)}
    style={Theme[`element_layout_${props.type || 'default'}`]}>
    {props.children}
</ElementDiv>

/**
 * Heading 3
 * @param props
 * @returns {*}
 * @constructor
 */
export const ElementHeading = (props) => {
    const type = props.type || 'h1'
    const Heading = headings[type]
    let headingProps = {}

    if (props.onPress) {
        headingProps.onClick = props.onPress
    }

    return <Heading
        {...headingProps}
        style={rawStyle(RawTheme['element_' + type], props)}
        className={className(`elements-${type}${props.small ? ' elements-small' : ''}`, props)}>
        {props.children}
    </Heading>
}

/**
 * Default ElementText
 * @param props
 * @returns {*}
 * @constructor
 */
export const ElementText = (props) => {
    if (props.dangerouslySetInnerHTML) {
        return <BaseText {...props} style={style(Theme.ElementText, props)}/>
    }

    return <BaseText className={props.className || ''} style={style(Theme.ElementText, props)}>
        {props.children}
    </BaseText>
}

/**
 * Default ElementText
 * @param props
 * @returns {*}
 * @constructor
 */
export const ElementSpan = (props) => {
    return <BaseText className={props.className || ''} style={style(Theme.ElementSpan, props)}>
        {props.children}
    </BaseText>
}

export const ElementParaph = (props) => <ElementText {...props} style={Theme.ElementParaph}/>

/**
 * Default ElementLink
 * @param props
 * @returns {*}
 * @constructor
 */
export const ElementLink = (props) => <BaseLink {...props}>
    <BaseText style={Theme.ElementLink}>{props.children}</BaseText>
</BaseLink>

/**
 * Default ElementLink
 * @param props
 * @returns {*}
 * @constructor
 */
export const ElementButtonLink = (props) => <ElementLink
    {...props}
    no-container>
    <ElementText style={Theme.Submit} color-white>{props.children}</ElementText>
</ElementLink>

/**
 * Button that looks like a ElementLink
 * @param props
 * @returns {*}
 * @constructor
 */
export const ElementButton = (props) => <TouchableWithoutFeedback {...props}>
    <ElementDiv>
        <BaseText style={style(Theme.Submit, props)}>{props.children}</BaseText>
    </ElementDiv>
</TouchableWithoutFeedback>



/**
 * Button that looks like a ElementLink
 * @param props
 * @returns {*}
 * @constructor
 */
export const ElementLinkButton = (props) => <TouchableWithoutFeedback {...props}>
    <ElementDiv>
        <BaseText style={style(Theme.ElementLink, props)}>{props.children}</BaseText>
    </ElementDiv>
</TouchableWithoutFeedback>

/**
 * ElementText with link, like a question: do you want this? click here
 * @param props
 * @returns {*}
 * @constructor
 */
export const ElementTextWithLink = (props) => {
    const types = {
        link: ElementLink,
        button: ElementLinkButton,
    }

    const LinkComponent = types[props.type || 'link']

    return <>
        <ElementText style={style(Theme.ElementTextWithLink, props)}>
            {props.text}
            {props.link && <LinkComponent {...props.link}>{props.link.text}</LinkComponent>}
            {props.after && props.after}
        </ElementText>
    </>
}

/**
 * Close button that wil be placed in the top right corner of a modal
 * @param size
 * @param onClose
 * @param type (default, small)
 * @returns {*}
 * @constructor
 */
export const ElementModalClose = ({size, onClose, type}) => {
    const CloseIcon = Icons.close
    return <TouchableWithoutFeedback onPress={onClose}>
        <ElementDiv style={Theme[`element_modal_close_${type || 'default'}`]}>
            <CloseIcon size={size || 30} color={ThemeDefaults.colors.grays[300]}/>
        </ElementDiv>
    </TouchableWithoutFeedback>
}

/**
 *
 * @param props (type: default, small, middle)
 * @returns {*}
 * @constructor
 */
export const ElementModal = (props) => {
    const {type, open, children, onClose} = props
    useEffect(() => {
        open ? scroll_off() : scroll_on()

        return () => scroll_on()
    }, [open])

    return <ElementDiv
        className={className(`elements-modal elements-modal-${type || 'default'}`, props)}
        style={[Theme.ElementModal, Theme[`element_modal_${type || 'default'}`], Theme[`element_modal_${open ? 'open' : 'closed'}`]]}>
        {!type && !props['hide-close'] && <ElementModalClose onClose={onClose}/>}
        <ElementDiv
            className={className(`elements-modal-container elements-modal-container-${type || 'default'}`, props)}
            style={[Theme.ElementModalContainer, Theme[`element_modal_container_${type || 'default'}`]]}>
            {type && !props['hide-close'] && <ElementModalClose type={'small'} onClose={onClose}/>}
            {children}
        </ElementDiv>
    </ElementDiv>
}

export const ElementHr = () => <ElementDiv style={Theme.ElementHr} className={'elements-hr'}/>

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
export const ElementOldRow = props => <ElementDiv
    {...props}
    className={className('row-old', props)}
    style={style(Theme.ElementOldRow, props)}>{props.children}
</ElementDiv>

/**
 * ElementOldColumn
 * @param props (xs, sm, md, lg, xl)
 * @returns {*}
 * @constructor
 */
export const ElementOldColumn = props => {
    const sizes = ['xs', 'sm', 'md', 'lg', 'xl']
    let columns = props.className || ''

    sizes.forEach((size) => {
        if (!props[size]) {
            return
        }
        if (className) {
            columns += ' '
        }

        columns += `col-old-${size}-${props[size]}`
    })

    return <ElementDiv
        {...props}
        className={className(`column-old${columns}`, props)}
        style={style(Theme.ElementOldColumn, props)}>{props.children}</ElementDiv>

}

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
export const ElementRow = props => <ElementDiv
    {...props}
    className={className('row', props)}
    style={style(Theme.ElementRow, props)}>{props.children}
</ElementDiv>

/**
 * ElementOldColumn
 * @param props (xs, sm, md, lg, xl)
 * @returns {*}
 * @constructor
 */
export const ElementColumn = props => {
    const sizes = ['xs', 'sm', 'md', 'lg', 'xl']
    let columns = ''

    if (typeof props.col === 'number') {
        columns += 'col-' + props.col
    } else if (props.col) {
        columns += 'col'
    }

    sizes.forEach((size) => {
        if (!props[size]) {
            return
        }
        if (columns) {
            columns += ' '
        }

        columns += `col-${size}-${props[size]}`
    })

    return <ElementDiv
        {...props}
        className={className(`${columns}`, props)}>{props.children}</ElementDiv>

}

/**
 * Div Element
 * @param props
 * @returns {*}
 * @constructor
 */
export const ElementDiv = (props) => <View
    {...props}
    ref={props.elementRef}
    style={style(Theme.ElementDiv, props)}>
    {props.children}
</View>

export const ElementInfo = (props) => <ElementDiv
    {...props}
    className={className('elements-info', props)}
    style={style(Theme.ElementInfo, props)}>{props.children}</ElementDiv>

export const ElementContainer = (props) => <ElementDiv
    {...props}
    className={className(`container elements-container${props.type ? '-' + props.type : ''}`, props)}
    style={style(Theme.ElementContainer, props)}>
    {props.children}
</ElementDiv>

export const ElementBadge = (props) => <ElementText style={[Theme.ElementBadge, props.style]}>
    {props.children}
</ElementText>

/**
 * Section Element
 * @param props
 * @returns {*}
 * @constructor
 */
export const ElementSection = (props) => {
    const Type = props.row ? ElementRow : ElementDiv

    return <Type
        style={style(Theme.ElementSection, props)}
        className={className('elements-section', props)}>{props.children}</Type>
}

/**
 * Blockquote Element
 * @param props
 * @returns {*}
 * @constructor
 */
export const ElementBlockquote = (props) => <ElementText
    style={style(Theme.ElementBlockquote, props)}
    className={className('elements-blockquote', props)}>{props.children}</ElementText>
/**
 * Icon Element
 * @param props
 * @returns {*}
 * @constructor
 */
export const ElementIcon = (props) => <ElementText {...props} className={className(`icon icon-${props.type}`, props)}/>

/**
 * Content Element
 * @param props
 * @returns {*}
 * @constructor
 */
export const ElementContent = (props) => <ElementDiv
    {...props}
    className={className('elements-content', props)}
    style={style(Theme.ElementContent, props)}>{props.children}</ElementDiv>

/**
 * Press Element
 * @param props
 * @returns {*}
 * @constructor
 */
export const ElementPress = (props) => <TouchableWithoutFeedback onPress={props.onPress}>
    {
        props.container ? <ElementDiv
            style={props.style}
            className={className(props.className || '', props)}>
            {props.children}
        </ElementDiv> : props.children
    }
</TouchableWithoutFeedback>

/**
 * Drop down menu
 * @param props
 * @returns {*}
 * @constructor
 */
export const ElementDropDown = (props) => {
    const {options, filter, endpoint, endpoint_empty, query} = props
    const [open, setOpen] = useState(false)
    const MenuArrow = open ? Icons.menu_up : Icons.menu_down

    let selected = {}

    let availableOptions = options.filter((option) => {
        if (
            query[filter] === option.name ||
            !query[filter] && option.name === ''
        ) {
            selected = option
            return false
        }

        return true
    })

    return <ElementDiv style={Theme.ElementDropDown} className={className('elements-dropdown', props)}>
        <TouchableWithoutFeedback onPress={() => setOpen(!open)}>
            <ElementDiv style={Theme.ElementDropDownButton}>
                <BaseLink to={selected.name ? endpoint : endpoint_empty} params={{...query, [filter]: selected.name}}>
                    <BaseText style={Theme.ElementDropDownOption}>
                        {selected.label}
                    </BaseText>
                </BaseLink>
                <MenuArrow color={ThemeDefaults.colors.blues[400]} size={30}/>
            </ElementDiv>
        </TouchableWithoutFeedback>
        {
            open &&
            <ElementDiv style={Theme.ElementDropDownOptions} className={'dropdown-options'}>
                {
                    availableOptions.map((option) => <BaseLink
                            key={option.name}
                            to={option.name ? endpoint : endpoint_empty}
                            action={() => setOpen(false)}
                            params={{...query, [filter]: option.name}}>
                            <BaseText style={Theme.ElementDropDownOption}>{option.label}</BaseText>
                        </BaseLink>,
                    )
                }
            </ElementDiv>
        }
    </ElementDiv>
}

/**
 * Image Element
 * @param props
 * @returns {*}
 * @constructor
 */
export const ElementImage = (props) => <Picture
    {...props}
    style={rawStyle(RawTheme['element_image_' + props.type], props)}/>

/**
 * Hero Element
 * @returns {*}
 * @constructor
 */
export const ElementHero = ({scrollRef}) => {
    const heros = [
        {name: 'betnation-divisie', label: 'Betnation Divisie', image: 'he-betnation-divisie.jpg'},
        {name: 'hoofdklasse-hockey-dames', label: 'Tulp Hoofdklasse Dames', image: 'he-tulp-hoofdklasse-dames-2.jpg'},
        {name: 'hoofdklasse-hockey-heren', label: 'Tulp Hoofdklasse Heren', image: 'he-tulp-hoofdklasse-heren-2.jpg'},
        {name: 'vrouwen-eredivisie', label: 'Vrouwen Eredivisie', image: 'he-vrouwen-eredivisie-2.jpg'},
        {name: 'hoofdklasse-honkbal', label: 'Hoofdklasse Honkbal', image: 'he-hoofdklasse-honkbal.jpg'},
        {name: 'eredivisie-volleybal-mannen', label: 'Eredivisie Volleybal Mannen', image: 'he-eredivisie-volleybal-mannen.jpg'},
        {name: 'eredivisie-volleybal-dames', label: 'Eredivisie Volleybal Dames', image: 'he-eredivisie-volleybal-dames-2.jpg'},
        {name: 'eredivisie-futsal', label: 'Eredivisie Futsal', image: 'he-eredivisie-futsal.jpg'},
        {name: 'ereklasse-rugby', label: 'Ereklasse Rugby', image: 'he-ereklasse-rugby-2.jpg'},
        {name: 'kenonz-korfbal-league', label: 'Kenonz Korfbal League', image: 'he-kenonz-korfbal-league-3.jpg'},
        {name: 'korfbal-league-2', label: 'Korfbal League 2', image: 'he-korfbal-league-3.jpg'},
        {name: 'reserve-korfbal-league', label: 'Reserve Korfbal League', image: 'he-reserve-korfbal-league2.jpg'},
        {name: 'bene-league-heren', label: 'Handbal Bene League', image: 'he-handbal-bene-league.jpg'},
        {name: 'handbalnl-league', label: 'HandbalNL League', image: 'he-handbalnl-league.jpg'},
        {name: 'eredivisie-handbal-heren', label: 'Handbal Heren', image: 'he-handbal-heren-2.jpg'},
        {name: 'eredivisie-handbal-dames', label: 'Handbal Dames', image: 'he-handbal-dames-2.jpg'},
        {name: 'eredivisie-beach', label: 'Eredivisie Beach', image: 'he-eredivisie-beach.jpg'},
        {name: 'nederlandse-darts-bond', label: 'Nederlandse Darts Bond', image: 'he-nederlandse-darts-bond.jpg'},
    ]

    return <ElementDiv style={Theme.ElementHero} className={'elements-hero'}>
        <div ref={scrollRef} style={RawTheme.ElementPayoff} className={'elements-payoff'}>
            <ElementHeading className={'elements-hero-title'} color-white text-center style={RawTheme.ElementHeroTitle}>
                Jouw sport volg je hier
            </ElementHeading>
            <ElementText style={Theme.ElementHeroTagline} text-center>#Voordefans</ElementText>
        </div>


        <ElementDiv className={'elements-hero-posters'}>
            <ElementRow justify-content-center>
                {
                    heros.map((hero, index) => <ElementColumn
                        col={4}
                        md={3}
                        xl={2}
                        key={index}
                        className={'elements-hero-poster'}>
                        <BaseLink
                            to={'channels/show'}
                            params={{id: hero.name}}
                            no-container>
                            <ElementImage
                                source={`img/${hero.image}`}
                                alt={hero.label}
                                type={'fluid'}
                            />
                        </BaseLink>
                    </ElementColumn>)
                }
            </ElementRow>
        </ElementDiv>
    </ElementDiv>
}

/**
 * Channel Scroll
 * @param container
 * @param item
 * @param hideMobile
 * @param small
 * @returns {*}
 * @constructor
 */
export const ElementScroll = ({container, item, hideMobile = false, small = false}) => {
    const [scrolling, setScrolling] = useState(0)
    const [offset, setOffset] = useState(0)

    useEffect(() => {
        if (item.current) {
            const width = item.current.getBoundingClientRect().width
            setOffset(Math.round(width * 2))
        }
    }, [])

    const scroll = (direction) => {
        let amount = scrolling
        if (direction === 'left' && amount !== 0) {
            amount -= offset
        }

        if (direction === 'right' && container.current.scrollLeft === amount) {
            amount += offset
        }

        setScrolling(amount)
        container.current.scrollLeft = amount
    }

    return <>
        <ElementPress onPress={() => scroll('left')}>
            <ElementDiv className={'scroll-arrow-left'} style={[
                Theme.ChannelScrollArrowLeft,
                hideMobile ? {opacity: 0} : {},
                small ? Theme.ChannelScrollArrowSmall : {},
            ]}>
                <ElementIcon type={'chevron-left'} style={Theme.ChannelChevron} absolute-middle/>
            </ElementDiv>
        </ElementPress>

        <ElementPress onPress={() => scroll('right')}>
            <ElementDiv className={'scroll-arrow-right'} style={[
                Theme.ChannelScrollArrowRight,
                hideMobile ? {opacity: 0} : {},
                small ? Theme.ChannelScrollArrowSmall : {},
            ]}>
                <ElementIcon type={'chevron-right'} style={Theme.ChannelChevron} absolute-middle/>
            </ElementDiv>
        </ElementPress>
    </>
}


/**
 * Pagination Element
 * TODO actually use this
 * @param current_page
 * @param is_lastPage
 * @param current_route
 * @returns {*}
 * @constructor
 */
export const ElementPagination = ({current_page, is_last_page, current_route}) => <ElementDiv
    style={Theme.ElementPagination}>
    {
        current_page > 1 &&
        <Link
            to={current_route}
            params={query}
            queryParams={{page: current_page - 1}}>vorige</Link>
    }
    {
        !is_last_page &&
        <Link
            to={current_route}
            params={query}
            queryParams={{page: current_page + 1}}>volgende</Link>
    }
</ElementDiv>

/**
 * Activity Element
 * @param loading
 * @param onEnter
 * @returns {*}
 * @constructor
 */
export const ElementActivity = ({loading, onEnter}) => <ElementDiv style={Theme.ElementActivity}>
    {loading && <StorybookIcons.Icon name={'loader'}/>}
    {
        !loading &&
        <Waypoint onEnter={onEnter}/>
    }
</ElementDiv>

/**
 * Warning Element shown on top of the page
 * @param warning (text, when filled the warning is visible)
 * @param onClose
 * @returns {*}
 * @constructor
 */
export const ElementWarning = ({warning, onClose}) => {
    const warningProps = warning !== '' ? {} : {'display-none': true}
    return <ElementDiv style={Theme.ElementWarningContainer} {...warningProps}>
        <ElementDiv className={'elements-warning'} style={Theme.ElementWarning}>
            <ElementText text-center color-pinks-400>{warning}</ElementText>
            <ElementModalClose type={'warning'} onClose={onClose}/>
        </ElementDiv>
    </ElementDiv>
}

/**
 * Cookie Warning Element triggered when cookies are disabled
 * TODO check this error in wall: Failed to read the 'localStorage' property from 'Window': Access is denied for this document.
 * @returns {*}
 * @constructor
 */
export const ElementCookieWarning = () => {
    const [warning, setWarning] = useState('')

    useEffect(() => {
        try {
            localStorage.setItem('cookie-check', 1)
            localStorage.removeItem('cookie-check')
        } catch (exception) {
            setWarning('Cookies en/of local storage zijn uitgeschakeld, schakel deze in om Eyecons te kunnen gebruiken.')
        }
    }, [])

    return <ElementWarning warning={warning} onClose={() => setWarning('')}/>
}

/**
 * Message to show globally when shit hits the fan
 *
 */

export const ElementGlobalMessage = ({onClose}) => <ElementDiv
    className={'elements-global-message'}
    style={Theme.ElementGobalMessage}>
    <ElementHeading type={'h4'}>{config('app.message.title')}</ElementHeading>
    <ElementText>
        {config('app.message.content')}&nbsp;
        <BaseLink
            to={'https://eyecons.zendesk.com'}
            no-container
            target={'_blank'}
        >
            eyecons.zendesk.com
        </BaseLink>
    </ElementText>
    <ElementModalIcon type={'alert'}/>
    <ElementModalClose type={'warning'} onClose={onClose}/>
</ElementDiv>

/**
 * Icon that will be placed in the top left corner of a modal
 *
 */
export const ElementModalIcon = () => {
    const AlertIcon = Icons.alert

    return <ElementDiv className={'elements-modal-icon'} style={Theme.ElementModalIcon}>
        <AlertIcon size={24} color={ThemeDefaults.colors.pinks[300]}/>
    </ElementDiv>
}
