import React from 'react'
import {StyleSheet} from 'react-native'
import {
    FeedbackContainer,
    FeedbackTitle,
    FeedbackMessage,
    FeedbackLink,
    FeedbackButton,
    Share,
} from 'core/components'

const warnings = (warning) => {
    if (!warning) {
        return
    }

    if (typeof warning === 'string') {
        return <FeedbackMessage>{warning}</FeedbackMessage>
    }

    if (typeof warning === 'object' && warning.message) {
        return <FeedbackMessage>{warning.message}</FeedbackMessage>
    }

    for (let key in warning) {
        return warning[key].map((message, index) => <FeedbackMessage key={index}>{message}</FeedbackMessage>)
    }
}

const Feedback = ({success, warning, video}) => <React.Fragment>
    <FeedbackContainer
        open={success.open}
        type={'success'}
        onClose={success.onClose}
        hideClose={success.hideClose}>
        <FeedbackTitle>{success.title}</FeedbackTitle>
        {Boolean(success.message) && <FeedbackMessage>{success.message}</FeedbackMessage>}
        {video && <Share style={styles.share} video={video}/>}
        {success.button && <FeedbackButton onPress={success.button.onPress} title={success.button.label}/>}
        {Boolean(success.close) && <FeedbackLink onPress={success.onClose}>{success.close}</FeedbackLink>}
    </FeedbackContainer>

    {
        warning &&
        <FeedbackContainer
            open={warning.open}
            type={'warning'}
            onClose={warning.onClose}>
            <FeedbackTitle>{warning.title}</FeedbackTitle>
            {warnings(warning.message)}
            <FeedbackButton onPress={warning.onClose} title={'OK'}/>
        </FeedbackContainer>
    }
</React.Fragment>;

export default Feedback;

const styles = StyleSheet.create({
    share: {
        marginTop: 20
    }
})
