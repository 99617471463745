import React, {useEffect, useState} from 'react'
import {ElementHr, ElementInfo, ElementText, FormSubmit} from 'core/components'
import {video_hls} from '../functions'
import {Theme} from 'core/style'
import {Buttons} from '@eyecons/storybook'

async function get720p(hls) {
    const response = await fetch(hls)
    hls = await response.text()

    let manifest = hls.replace(/\s/g, '').split('#EXT-X-STREAM-INF:')
    let stream720p = false

    manifest.forEach(function (stream) {
        let stream_obj = stream.split(',')

        if (!stream_obj.includes('RESOLUTION=1280x720')) {
            return
        }

        stream_obj.forEach(value => {
            if (value.indexOf('https://') > 0) {
                stream720p = 'https://' + value.split('https://')[1]
            }
        })
    })

    return stream720p
}

export default function usePlayerQuality(video, playable) {
    const [hls720p, setHls720p] = useState(false)
    const [hls, setHls] = useState(false)

    useEffect(() => {
        if (playable) {
            get720p(video_hls(video)).then(response => setHls720p(response))
        }
    }, [playable])

    function Component() {
        return <>
            {
                playable && Boolean(hls720p) &&
                <>
                    <ElementHr/>
                    <ElementInfo>
                        <ElementText>
                            {Boolean(hls) ? 'Je heb een lagere kwaliteit geforceerd, klik op Kwaliteit herstellen om dit ongedaan te maken' : 'Heb je een Chromecast Generatie 1 of 2 en ervaar je schokkerige beeld en/of geluid? Klik op Forceer lagere kwaliteit. Chromecast G1 en G2 ondersteunen helaas geen 1080p High.'}
                        </ElementText>
                        <div className={'pt-4'}>
                            <Buttons.Button
                                type={'primary'}
                                onClick={() => setHls(hls ? false : hls720p)}>
                                {Boolean(hls) ? 'Kwaliteit herstellen' : 'Forceer lagere kwaliteit'}
                            </Buttons.Button>
                        </div>
                    </ElementInfo>
                </>
            }
        </>
    }

    return [Component, hls]
}
