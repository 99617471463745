import {errorMessage, loading} from 'core/store'
import client from 'core/client/Client'

export const SAVE_SUCCESS = 'SAVE_SUCCESS'

export const saveSuccess = (route, response) => {
    return {
        type: SAVE_SUCCESS,
        route: route,
        data: response.data,
        response
    }
}

/**
 *
 * @param route
 * @param form
 * @param token
 * @returns {Function}
 */
export const save = (route, form) => {
    return (dispatch) => {
        dispatch(loading(true, route))
        return client.save(route, form)
            .then((response) => {
                let request = dispatch(saveSuccess(route, response.data))
                dispatch(loading(false, route))
                return request
            }).catch(error => {
                dispatch(loading(false, route))
                return errorMessage(route, dispatch, error, token)
            })
    }
}
