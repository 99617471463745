import React, {useState} from 'react'
import {View, TouchableWithoutFeedback, StyleSheet, Text} from 'react-native'
import {connect} from 'react-redux'
import {Input, Warning} from 'core/style'
import {ElementModal, Submit} from 'core/components'
import {destroy} from 'core/store'

const Delete = ({entity, title, params, user, destroy, route, onClose, onDeleted}) => {
    const [confirmed, setConfirmed] = useState(false)
    const [warning, setWarning] = useState('')
    const validation = 'VERWIJDER'

    return <ElementModal
        onClose={onClose}
        type={'small'}
        open={params.id}>
        <Text style={styles.info}>Weet je zeker dat {entity} "{title}" wilt verwijderen? Schrijf dan in het
            tekstveld: {validation}</Text>
        <Input
            value={confirmed || ''}
            placeholder={'VERWIJDER'}
            style={styles.input}
            onChangeText={(text) => {
                setConfirmed(text)
            }}/>
        <Submit
            background={'#ed0040'}
            onPress={() => {
                setConfirmed('')
                destroy(params, user.token, route).then((response) => {
                    if (response.message) {
                        return setWarning(response.message)
                    }

                    if (onDeleted) {
                        onDeleted()
                    }

                    onClose()
                })
            }}
            disabled={String(confirmed) !== validation}
            title={'verwijderen'}
        />
        {Boolean(warning.length) && <Warning>{warning}</Warning>}
    </ElementModal>
}

export default connect(null, dispatch => {
    return {
        destroy: (params, token, route) => {
            return dispatch(destroy(route, params, token))
        },
    }
})(Delete)

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
    },
    info: {
        marginVertical: 15,
    },
    input: {
        color: '#ed0040',
    },
})
