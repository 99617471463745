import {StyleSheet, Text} from 'react-native'
const Title = props => <Text style={styles.title}>{props.children}</Text>;
export default Title;

const styles = StyleSheet.create({
    title: {
        fontWeight: 'bold',
        fontSize: 18,
        textAlign: 'center',
        color: '#1e2330',
        display: 'block',
        lineHeight: 22
    }
})
