import React from 'react'
import {StyleSheet} from 'react-native'
import {Column} from 'core/style'
import {ElementDiv} from 'core/components'

const Container = ({className, xs, sm, md, lg, style, children}) => <Column xs={xs || 12} {...{className, sm, md, lg}} style={[styles.container, style.container || {}]}>
    <ElementDiv style={[styles.content, style.content || {}]} className={'card video-card-container'}>
        {children}
    </ElementDiv>
</Column>;

export default Container;

const styles = StyleSheet.create({
    container: {
        marginBottom: 10,
    },
    content: {
        flexGrow: 1
    }
})
