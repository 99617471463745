export const FONT_LOADED = 'FONT_LOADED'

export const fontLoaded = () => {
    return {
        type: FONT_LOADED,
        font: true,
    }
}

export const font = () => {
    return (dispatch) => {
        return dispatch(fontLoaded())
    }
}
