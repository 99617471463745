import React, {useState} from 'react'
import {
    FormSubmit,
    Warning,
    PasswordFields,
    Message,
    validatePassword,
    initialPasswordInfo,
    ElementDiv,
    FormTitle,
} from 'core/components'
import {translate} from 'core/functions'
import {useLocale} from 'core/hooks'
import {Fetcher, useApi} from 'ra-fetch'
import {Buttons} from '@eyecons/storybook'

/**
 * Password Reset Form
 * @param query
 * @param setPage
 * @param locale
 */
export default function PasswordReset({query = {}, setPage}) {
    const isVerify = query.id && query.token && query.signature
    const [Locale, locale] = useLocale()
    const [passwordInfo, setPasswordInfo] = useState(initialPasswordInfo)
    const [password, setPassword, submitPassword] = useApi('auth').store('password.reset', {
        password: '',
        password_confirmation: '',
        token: query.token,
        email: '',
    })

    const title = query.token ? translate('Choose password', locale) : null

    const send = () => {
        // TODO add locale
        if (validatePassword(password.data, passwordInfo, setPasswordInfo, locale)) {
            if (query.id && query.token && query.signature) {
                Fetcher.api('auth').show('email.verify', query)
            }

            submitPassword({locale}).then((response) => {
                if (!response.errors.length) {
                    setPage({
                        title: translate('Success!', locale),
                        page: () => <Message>
                            {translate('You password has been successfully changed', locale)}
                            <div className={'mt-8'}>
                                <Buttons.Link
                                    type={'primary'}
                                    to={response.data.channel.id ? `${process.env.NEXT_PUBLIC_STORYBOOK_DASHBOARD_V2_URL}/videos` : '/'}>
                                    {translate('continue', locale)}
                                </Buttons.Link>
                            </div>
                        </Message>,
                    })
                }
            })
        }
    }

    return <>
        {
            title && <ElementDiv position-relative>
                <FormTitle>{translate('Choose password', locale)}</FormTitle>
                <Locale/>
            </ElementDiv>
        }

        <PasswordFields user={password.data} setUser={setPassword} passwordInfo={passwordInfo}
                        setPasswordInfo={setPasswordInfo} locale={locale}/>

        {Boolean(password.errors.length) && <Warning>{password.errors.map(error => error.message)}</Warning>}

        <FormSubmit onPress={send} loading={password.submitting}>{translate('continue', locale)}</FormSubmit>
    </>
}
