import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {save} from 'core/store'
import * as Shared from './shared'
import {Fetcher, useApi} from 'ra-fetch'

const PlayerVoucher = ({user, setUser, video, hls, hash, video_params, autoplay = false, setPaid}) => {
    const [redeem, setRedeem, submitRedeem] = useApi('ecommerce').store('redeems', {
        hash: hash,
        external_id: video.event_id,
        external_type: 'events',
    })
    const [owns, setOwns] = useState({...Fetcher.model(), loading: !!user.id})
    const [playable, setPlayable] = useState(false)

    useEffect(() => {
        if (!owns.loading) {
            setPlayable(Boolean(owns.data.owns && video.status === 'published' && !video.hidden))
        }
    }, [owns.loading])

    useEffect(() => {
        if (user.id) {
            setOwns({...owns, loading: true})
            Fetcher.api('ecommerce')
                .show('customers.paid.video', {id: user.id, orderable_id: video.event_id, orderable_type: 'events'})
                .then(response => {
                    setOwns(response)
                    setPaid(response)
                })
        }
    }, [user.id])

    if (owns.loading) {
        return <></>
    }

    return <>
        {
            video.status === 'published' && <>
                {
                    user.id &&
                    !playable &&
                    <Shared.PlayerVoucherWindow redeem={redeem} submitRedeem={() => {
                        submitRedeem().then(response => {
                            if (response.data.id) {
                                setPaid({...owns, data: {owns: true}})
                                setPlayable(video.status === 'published' && !video.hidden)
                            }
                        })
                    }}/>
                }
                {
                    !user.id &&
                    <Shared.PlayerLoginWindow setUser={setUser} video={video}/>
                }
            </>
        }

        <Shared.PlayerWithBroadcasting
            autoplay={autoplay}
            playable={playable}
            video={video}
            user={user}
            hls={hls}
            video_params={video_params}
        />
    </>
}

export default connect(state => {
    return {
        video: state.video,
        video_params: state.video_params,
    }
}, dispatch => {
    return {
        save: (route, params, token) => {
            return dispatch(save(route, params, token))
        },
    }
})(PlayerVoucher)
