const Client = require('ra-fetch').Client
const array_get = require('../functions').array_get
const config = require('../functions').config
const query = require('../functions').query
const is_server = require('../functions').is_server
const auth = require('../functions').auth

const api = {
    post: {},
    put: {},
    delete: {},
    get: {
        video: {
            endpoint: '/public/videos/{id}',
            location: 'data',
        },
        media_video: {
            endpoint: '/public/videos/{id}',
            location: 'data',
            api_url: process.env.NEXT_PUBLIC_STORYBOOK_SERVICE_MEDIA_URL
        },
        event: {
            endpoint: '/public/events/{id}',
            location: 'data',
            api_url: process.env.NEXT_PUBLIC_STORYBOOK_SERVICE_MEDIA_URL
        },
        channels: {
            endpoint: '/public/channels',
            location: 'data',
        },
        sport: {
            endpoint: '/public/sports/{name}/name',
            location: 'data',
        },
        sports: {
            endpoint: '/public/sports',
            location: 'data',
        },
        channel: {
            endpoint: '/public/channels',
            location: 'data.0',
        },
        videos: {
            endpoint: '/public/videos',
            location: '',
        },
        events: {
            endpoint: '/public/events',
            location: '',
            api_url: process.env.NEXT_PUBLIC_STORYBOOK_SERVICE_MEDIA_URL
        },
        videos_trending: {
            endpoint: '/public/videos/trending',
            location: '',
        },
        livestream_counts: {
            endpoint: '/public/events/count',
            location: '',
            api_url: process.env.NEXT_PUBLIC_STORYBOOK_SERVICE_MEDIA_URL
        },
        search_videos: {
            endpoint: '/public/videos',
            location: 'data',
        },
        search_channels: {
            endpoint: '/public/channels',
            location: 'data',
        }
    },
}

function request(type, route, filters) {
    const options  = api[type][route]
    route = options.endpoint

    let params = Object.assign({}, filters) || {}
    for (let key in params) {
        if (!params.hasOwnProperty(key) || route.indexOf(`{${key}}`) === -1) {
            continue
        }
        route = route.replace(`{${key}}`, params[key])
        delete params[key]
    }

    return `${options.api_url || config('api.url')}${type === 'get' ? route + query(params) : route}`
}

const createForm = (formData, params, key = null) => {
    for (let i in params) {
        if (!params.hasOwnProperty(i)) {
            continue
        }

        let formKey = key ? key + `[${i}]` : i

        if (
            params[i] !== null &&
            (Array.isArray(params[i]) || typeof params[i] === 'object') &&
            !(params[i] instanceof File || params[i] instanceof Date)
        ) {
            formData = createForm(formData, params[i], formKey)
            continue
        }

        // Return null values as empty string, because the back-end will receive a string "null" which is super annoying.
        formData.append(formKey, params[i] === null ? '' : params[i])
    }

    return formData
}


function form(params) {
    let formData = new FormData()
    return createForm(formData, params)
}

Client.login = function (user) {
    let headers = auth()
    return Client.post('auth/login', user, {headers})
}

Client.register = function (user) {
    let headers = auth()
    return Client.post('users', user, {headers})
}

Client.password_reset = function (data) {
    data._method = 'put'
    let headers = auth()
    return Client.post('users/password_reset', data, {headers})
}

Client.save = function (route, data) {
    let method = data.id ? 'put' : 'post'
    let params = data

    if (!is_server) {
        params = form(data)
        params.append('_method', method)
    }

    return Client.post(request(method, route, data), params)
}

Client.retrieve = function (route, filters = {}) {
    let headers = auth()
    route = filters.route || route

    return Client.get(request('get', route, filters), {headers})
        .then((response) => {
            const router = api.get[route]
            const value = array_get(response.data, router.location)

            if (value) {
                return value
            }

            return router.default || response.data
        })
}

Client.destroy = function (route, params) {
    let headers = auth()
    return Client.delete(request('delete', route, params), {headers})
        .then((response) => {
            return response
        })
}

exports.default = Client
