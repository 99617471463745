import React, {useState} from 'react'
import {useApi} from 'ra-fetch'
import {translate} from 'core/functions'
import {connect} from 'react-redux'
import {NoAccount, PasswordForget, PasswordResetSendPage, PasswordResetRequest} from 'core/components'
import {useLocale} from 'core/hooks'
import {Forms, Texts} from "@eyecons/storybook";

const Login = (props) => {
    const {register, query, setUser} = props
    const [Locale, locale] = useLocale(query.locale || 'nl')
    const [passwordReset, setPasswordReset] = useState(false)
    const [page, setPage] = useState('login')
    const [login, setLogin, submitLogin] = useApi('auth').login({
        email: '',
        password: '',
    })

    function submit() {
        submitLogin({locale}).then(response => {
            if (response.errors.length) {
                return
            }

            if (setUser) {
                return setUser(response.data)
            }

            if (query.return) {
                window.location.href = query.return
                return
            }

            window.location.href = response.redirect
        })
    }

    if (page === 'password-reset-send') {
        return <PasswordResetSendPage locale={locale}/>
    }

    return <>
        <div className={'relative text-center mb-4'}>
            <Locale/>
            <Texts.Heading type={'h5'} appendClassname={'text-dark'}>
                {translate(passwordReset ? 'Reset password' : 'Login', locale)}
            </Texts.Heading>

            {
                passwordReset &&
                <Texts.Primary small className={'text-disabled mt-4'}>{translate('Enter E-mail address to recover your password:', locale)}</Texts.Primary>
            }
        </div>

        <NoAccount {...register || {}} locale={locale}/>

        <Forms.Field>
            <Forms.Input
                placeholder={translate('E-mail address', locale)}
                type={'email'}
                onChange={email => setLogin({email})}
                value={login.data.email}
            />
        </Forms.Field>

        {
            !passwordReset &&
            <Forms.Field>
                <Forms.Input
                    className={'mb-2'}
                    type={'password'}
                    placeholder={translate('Password', locale)}
                    onChange={password => setLogin({password})}
                    value={login.data.password}
                />
                <PasswordForget locale={locale} onPress={() => {
                    setPasswordReset(true)
                }}/>
            </Forms.Field>
        }

        {
            passwordReset &&
            <PasswordResetRequest
                locale={locale}
                email={login.data.email}
                onSend={() => {
                    setPage('password-reset-send')
                    setPasswordReset(false)
                }}
            />
        }
        {
            !passwordReset &&
            <Forms.Buttons
                type={'wide'}
                errors={login.errors}
                title={translate('Login', locale)}
                submit={submit}
            />
        }

    </>
}

export default connect(state => {
        return {
            query: state.query,
        }
    }
)(Login)
