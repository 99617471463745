import React from 'react'
// import {useInView} from 'react-intersection-observer'
import {LazyLoadImage} from 'react-lazy-load-image-component'
import {cdn} from 'core/functions'

const IndexWeb = ({alt, disable_cdn, source, style, className}) => {
    // const [background, inView] = useInView({
    //     triggerOnce: true
    // })

    return <LazyLoadImage
        style={style}
        className={className}
        src={disable_cdn ? source : cdn(source)}
        visibleByDefault={true}
        alt={alt || className || ''}
    />
    // return (
    //     <img {...props} ref={background} src={props['lazyload-disabled'] || inView ? props.source : ''} />
    // )
};

export default IndexWeb;

