import React from 'react'
import {ElementDiv, ElementScrollItem, ElementImage} from 'core/components'
import {Theme, RawTheme, rawStyle, style} from 'core/style'
import {Link} from 'core/router'
import {useInView} from 'react-intersection-observer'
import {config} from 'core/functions'

export {default as CardContainer} from './Container'

export const Card = ({type, to, params, onPress, children, style}) => {
    const Type = type === 'scroll-item' ? ElementScrollItem : ElementDiv

    return <Type style={[Theme.Card, style]} className={`card${type ? ' ' + type : ''}`}>
        <Link to={to} params={params} onPress={onPress} no-container>
            {children}
        </Link>
    </Type>
}

function getSource(thumbnail) {
    if (config('app.env') === 'testing' || !thumbnail) {
        return ''
    }

    return thumbnail
}

export const CardThumbnail = ({children, thumbnail, wrapperProps}) => {
    const [wrapper, inView] = useInView({
        triggerOnce: true,
    })

    const style = {}
    const source = getSource(thumbnail)

    if (inView) {
        style.backgroundImage = `url(${source})`
    }

    return <div ref={wrapper} className={'card-thumbnail-wrapper'} style={
        rawStyle(RawTheme.CardThumbnailContainer, wrapperProps || {})
    }>
        <ElementDiv className={'card-thumbnail'} style={[Theme.CardThumbnail, style]}>
            {children}
        </ElementDiv>
    </div>
}

export const CardThumbnailImage = ({alt, children, thumbnail, wrapperProps}) => {
    const source = getSource(thumbnail)

    return <ElementDiv
        className={'card-thumbnail-wrapper'}
        style={style(Theme.CardThumbnailContainer, wrapperProps || {})}>
        <ElementImage disable_cdn source={source} alt={alt} type={'fluid'}/>
        {children}
    </ElementDiv>
}

export const CardBody = ({children}) => <ElementDiv className={'card-body'} style={Theme.CardBody}>
    {children}
</ElementDiv>
