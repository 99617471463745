const Client = require('ra-fetch').Client
const cookieCheck = require('third-party-cookie-check')
const Router = require('../routes')
const environment = require('./env/index').environment
const translations = require('./env/index').translations
const qs = require('qs')
const MobileDetect = require('mobile-detect')
const is_server = typeof window === 'undefined'
const mobile_detect = is_server ? {} : new MobileDetect(window.navigator.userAgent)
const Cookies = require('js-expansion').Cookies

// TODO add to JS expansion
const array_get = (data, key) => {
    if (typeof key === 'string') {
        key = key.split('.')
    }

    let name = key.shift()

    if ((!data[name] || data[name] === undefined) && data[name] !== '') {
        return false
    }

    data = data[name]

    if (!key.length) {
        return data
    }

    return array_get(data, key)
}

const to_date_time = (Date) => {
    return to_date(Date) + ' ' + to_time(Date)
}

const to_time = (Date) => {
    const minutes = Date.getMinutes()
    let time = Date.getHours() + ':'
    time += minutes < 10 ? '0' + minutes : minutes
    return time
}

const to_date = (Date, locale = 'nl') => {
    const year = Date.getFullYear()
    const month = Date.getMonth() + 1
    const day = Date.getDate()
    switch (locale) {
        case 'nl':
            return `${day}-${month}-${year}`
        case 'en':
            return `${year}-${month}-${day}`
    }

    return `${year}-${month}-${day}`
}

const config = (key) => {
    return array_get(environment, key)
}

const cdn = (location) => {
    return `${config('app.cdn')}/${location}`
}

const route = (name, params = {}) => {
    return config('app.url') + Router.getRoutePath(name, params)
}

const hash = () => {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
}

const popitup = (url, name) => {
    let new_window = window.open(url, name, 'height=640,width=570')
    if (window.focus) {
        new_window.focus()
    }
    return false
}

const openURLWithFallback = (url, fallbackUrl, target) => {
    const ios = !!navigator.userAgent.match(/like Mac OS X/i)

    if (!ios && !target) {
        window.location = url
    } else {
        window.open(url, target)
    }

    if (!fallbackUrl) {
        return
    }

    // Mobile detection
    let now = Date.now()
    let localAppInstallTimeout = window.setTimeout(function () {
        if (Date.now() - now < 1250) {
            window.open(fallbackUrl)
        }
    }, 1000)

    // Desktop detection
    window.addEventListener('blur', function () {
        window.clearTimeout(localAppInstallTimeout)
    })
}

const share = (type, url) => {
    const utmUrl = url + '?utm_medium=share'
    switch (type) {
        case 'facebook':
            popitup('https://www.facebook.com/sharer/sharer.php?u=' + utmUrl, 'Delen op Facebook')
            break
        case 'twitter':
            popitup('https://twitter.com/intent/tweet?status=' + utmUrl, 'Delen op Twitter')
            break
        case 'whatsapp':
            url = 'Check deze video op Eyecons.com: ' + url + '?utm_source%3Dwhatsapp%26utm_medium%3Dshare'
            openURLWithFallback(`whatsapp://send?text=${url}`, `https://wa.me/?text=${url}`)
            break
        case 'copy':
            document.execCommand('copy')
            let textField = document.createElement('textarea')
            textField.innerText = utmUrl
            textField.style.position = 'fixed'
            textField.style.top = '0'
            textField.style.left = '0'
            textField.style.width = '2em'
            textField.style.height = '2em'
            textField.style.padding = '0'
            textField.style.border = 'none'
            textField.style.outline = 'none'
            textField.style.boxShadow = 'none'
            textField.style.background = 'transparent'

            document.body.appendChild(textField)
            textField.focus()
            textField.select()

            const range = document.createRange()
            range.selectNodeContents(textField)

            const sel = window.getSelection()
            sel.removeAllRanges()
            sel.addRange(range)

            textField.setSelectionRange(0, textField.value.length)
            let result = document.execCommand('copy')

            if (!result) {
                const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0
                const copyHotkey = isMac ? '⌘C' : 'CTRL+C'
                result = prompt(`Druk ${copyHotkey}`, string)
            }

            textField.remove()
            break
    }
}

const scroll_on = () => {
    document.body.classList.remove('no-scroll')
}

const scroll_off = () => {
    document.body.classList.add('no-scroll')
}

const scroll = (type) => {
    switch (type) {
        case 'top':
            document.body.scrollTop = 0
            document.documentElement.scrollTop = 0
            break
        case 'bottom':
            window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight)
            break
    }
}

// Returns if a value is a string
const is_string = (value) => {
    return typeof value === 'string' || value instanceof String
}

const query = (filters) => {
    return filters && JSON.stringify(filters) !== '{}' ? '?' + qs.stringify(filters) : ''
}

const auth = () => {
    if (!is_server) {
        return {}
    }

    return {
        Secret: config('api.secret'),
    }
}

const in_iframe = () => {
    return window.location !== window.parent.location
}

const page_url = () => {
    return (window.location !== window.parent.location) ? document.referrer : document.location.href
}

const current_window = () => {
    return window.top || window
}

const redirect = (page) => {
    current_window().location.href = page
}

const reload = () => {
    if (in_iframe()) {
        return self.location.reload()
    }

    location.reload()
}


function isSafari() {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}
const third_party_cookies = async () => {
    if (!in_iframe()) {
        return true
    }

    if (isSafari()) {
        const {supported} = await cookieCheck()
        return supported
    }

    try {
        if (window.localStorage) {
            return true
        }
    } catch (err) {
        return false
    }
}

function translate(search, lang = 'en', replace = {}) {
    let text = translations[lang][search] || search

    for (const key in replace) {
        text = text.replace(`{{${key}}}`, replace[key])
    }

    return text
}

function video_hls(video) {
    if (video.src && video.type === 'live') {
        return video.src
    }

    const domain = 'https://cdn.jwplayer.com'
    if (video.type === 'live') {
        return `${domain}/live/events/${video.external_id}.m3u8`
    }

    return `${domain}/manifests/${video.external_id}.m3u8`
}

async function server_authorize(req, res, endpoint, body, nologin = false) {
    const response = await Client.post(`${process.env.NEXT_PUBLIC_STORYBOOK_SERVICE_AUTH_URL}/api/${endpoint}`, body, {
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${config('api.token')}`,
        },
    })

    if (!nologin) {
        const options = {
            sameSite: 'none',
            secure: true,
        }

        Cookies.withExpress(req, res).write('token', response.data.data.token, options)
    }

    return res.status(200).json(response.data)
}

function loggedIn(user) {
    if (user.id) {
        redirect(`${process.env.NEXT_PUBLIC_STORYBOOK_DASHBOARD_V2_URL}/${user.channel.id ? 'videos' : 'profile'}`)
    }
}

function is_paid(video = {}) {
    return video.price && video.price.enabled || video.has_public_plans
}


module.exports = {
    array_get,
    config,
    hash,
    share,
    route,
    scroll_on,
    scroll_off,
    scroll,
    is_server,
    is_string,
    query,
    to_date_time,
    to_date,
    to_time,
    mobile_detect,
    in_iframe,
    reload,
    cdn,
    page_url,
    current_window,
    redirect,
    third_party_cookies,
    video_hls,
    server_authorize,
    loggedIn,
    translate,
    is_paid,
    auth,
}
