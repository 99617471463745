import React, {useState} from 'react'
import {Text, StyleSheet} from 'react-native'
import {ElementModal, Submit, SwitchWithLabel} from 'core/components'
import {H2, Row, Column} from 'core/style'
import {Link} from 'core/router'

const Cookie = () => {
    const [open, setOpen] = useState(false)
    const [settings, setSettings] = useState(false)
    const [cookies, setCookies] = useState([
        {
            name: 'essential',
            label: 'ESSENTIËLE COOKIES',
            accepted: true,
            disabled: true,
        },
        {
            name: 'analytics',
            label: 'ANALYTISCHE COOKIES',
            accepted: true,
            disabled: true,
        },
        {
            name: 'personal_experience',
            label: 'PERSOONLIJKE ERVARING',
            accepted: true,
            disabled: false,
        },
        {
            name: 'personal_advertisement',
            label: 'PERSOONLIJKE ADVERTENTIES',
            accepted: true,
            disabled: false,
        },
    ])

    const setCookie = (index) => {
        let userCookies = cookies.clone()
        userCookies[index].accepted = !userCookies[index].accepted
        setCookies(userCookies)
    }

    const storeCookies = (all = false) => {
        let userCookies = cookies.clone()

        if (all) {
            userCookies = cookies.map(cookie => {
                return {...cookie, accepted: true}
            })
        }
    }

    return (
        <ElementModal type={'middle'} open={open} hide-close>
            <H2 className={'cookie-title'}>Eyecons.com gebruikt cookies</H2>
            <Text style={styles.text}>
                Door cookies werkt een site goed en veilig. Bovendien kunnen we je beter van dienst zijn. Welk
                niveau van cookies en verwerken van persoonsgegevens mogen wij gebruiken? Hierover valt alles te
                lezen in onze <Link to={'pages'} params={{id: 'privacy'}} target={'_blank'}>privacyverklaring</Link>.
            </Text>

            {
                settings &&
                cookies.map((cookie, index) =>
                    <SwitchWithLabel
                        big
                        disabled={cookie.disabled}
                        key={index}
                        id={cookie.name}
                        value={cookie.accepted}
                        label={cookie.label}
                        style={styles.cookie}
                        onPress={() => setCookie(index)}/>,
                )
            }

            <Row justify={'center'}>
                <Column xs={12} sm={6}>
                    <Submit
                        background={'#cecece'}
                        onPress={(event) => {
                            event.preventDefault()
                            settings ? storeCookies() : setSettings(true)
                        }}
                        style={styles.button}
                        className={'cookie-button-left'}
                        title={settings ? 'opslaan' : 'VOORKEUREN'}/>
                </Column>
                <Column xs={12} sm={6}>
                    <Submit
                        style={styles.button}
                        className={'cookie-button-right'}
                        onPress={(event) => {
                            event.preventDefault()
                            storeCookies(event, true)
                        }}
                        title={'alles accepteren'}/>
                </Column>
            </Row>
        </ElementModal>
    )
}

const styles = StyleSheet.create({
    text: {
        color: '#717171',
        fontSize: 15,
        marginBottom: 20,
    },
    cookie: {
        padding: 13,
        borderColor: '#e0e0e0',
        borderWidth: 1,
        borderRadius: 6,
        marginBottom: 10,
    },
    button: {
        width: '100%',
        maxWidth: 210,
    },
    checkbox: {
        marginBottom: 10,
    },
})

export default Cookie
