import React, {useState} from 'react'
import {connect} from 'react-redux'
import {StyleSheet, Image, View, Text, TouchableWithoutFeedback} from 'react-native'
import {route} from 'core/router'
import {Grid} from 'core/style'

const Filters = ({className, query, endpoint, endpoint_empty, playlists}) => {
    const [active, setActive] = useState(query && query.category ? query.category : '')

    const filter = (link) => {
        let params = query
        if (link.name) {
            params.category = link.name
        }

        if (query.access) {
            params.queryParams = {access: query.access}
            delete params.access
        }

        const router = route(link.name ? endpoint : endpoint_empty, params)

        setActive(link.name)

        router.redirect()
    }

    const links = () => {
        let links = [
            {
                name: '',
                title: 'ALLE VIDEO\'S',
            },
            {
                name: 'samenvatting',
                title: 'SAMENVATTINGEN',
            },
            {
                name: 'interview',
                title: 'INTERVIEWS',
            },
        ]

        playlists.forEach(playlist => {
            links.push({
                name: playlist.name,
                title: playlist.label.toUpperCase(),
            })
        })

        return links
    }

    return (
        <Grid className={`filters${className ? ' ' + className : ''}`} style={styles.filters}>
            {
                links().map((link, index) => {
                    return <TouchableWithoutFeedback onPress={() => filter(link)} key={index}>
                        <View className={`filter-link-container${active === link.name ? ' active' : ''}`} style={styles.container}>
                            <Text
                                className={'filter-link'}
                                style={styles.link}>{link.title}</Text>
                        </View>
                    </TouchableWithoutFeedback>
                })
            }
        </Grid>
    )
}

const styles = StyleSheet.create({
    link: {
        fontSize: 11,
        color: '#bababa',
        padding: 15,
        paddingBottom: 10,
        fontWeight: 'bold',
    },
    container: {
        cursor: 'pointer',
    },
})

export default connect(state => {
    return {
        playlists: state.playlists || [],
        loading: state.loading.playlists
    }
})(Filters)
