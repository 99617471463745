import React, {useEffect, useState} from 'react'
import {Client} from 'ra-fetch'
import {connect} from 'react-redux'
import {Forms, Texts} from "@eyecons/storybook";

const TwoFactor = ({query}) => {
    const [code, setCode] = useState('')
    const [errors, setErrors] = useState([])
    const [email, setEmail] = useState(null)
    const [loading, setLoading] = useState(true)

    function getTwoFactorUrl() {
        return `${process.env.NEXT_PUBLIC_STORYBOOK_SERVICE_AUTH_URL}/login/${query.id}/2fa?expires=${query.expires}&signature=${query.signature}`
    }

    function submit() {
        setErrors([])

        Client.post(getTwoFactorUrl(), {code}).then(response => {
            window.location.href = response.data.redirect
        }).catch(error => {
            setErrors([error.response.data])
        })
    }

    useEffect(() => {
        Client.post(getTwoFactorUrl(), {validate: true}).then(response => {
            setEmail(response.data.data.email)
            setLoading(false)
        }).catch(error => {
            setErrors([error.response.data])
            setLoading(false)
        })
    }, []);


    if (loading) {
        return null;
    }

    return <>
        <div className={'relative text-center mb-4'}>
            <Texts.Heading type={'h5'} appendClassname={'text-dark mb-4'}>
                Verificatie in 2 stappen
            </Texts.Heading>

            <Texts.Primary>We hebben net een 6-cijferige code gestuurd naar {email}. Vul deze hieronder in.</Texts.Primary>
        </div>

        <Forms.Field>
            <Forms.Input
                placeholder={'Code'}
                type={'text'}
                onChange={code => setCode(code)}
                value={code}
            />
        </Forms.Field>

        <Forms.Buttons
            type={'wide'}
            errors={errors}
            title={'Login'}
            submit={submit}
        />
    </>
}

export default connect(state => {
        return {
            query: state.query,
        }
    }
)(TwoFactor)
