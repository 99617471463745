import React, {useState, useEffect} from 'react'
import {StyleSheet} from 'react-native'
import {connect} from 'react-redux'
import {Feedback, ElementModal, ElementDiv, FormSubmit, Warning, ElementHeading, ElementText} from 'core/components'
import {save} from 'core/store'

const Create = (props) => {
    const {resource, title, description, save, user, onClose, content, initialModel, feedback, type} = props
    const [messages, setMessages] = useState([])
    const [success, setSuccess] = useState(false)
    const [disabled, setDisabled] = useState(false)
    const [open, setOpen] = useState(false)
    const [model, setModel] = useState(initialModel || {})

    const store = (close = true) => {
        setDisabled(true)
        setMessages([])
        save(resource, model, user.token).then((response) => {
            setDisabled(false)
            if (response.message) {
                return setMessages([<Warning key={1}>{response.message}</Warning>])
            }

            if (!response.data) {
                let messages = []
                for (let i in response) {
                    messages.push(<Warning key={i}>{response[i][0]}</Warning>)
                }
                return setMessages(messages)
            }

            if (close) {
                setSuccess(true)
            }
        })
    }

    const required = () => {
        let disabled = false
        if (props.required) {
            props.required.forEach((item) => {
                if (!model[item] || model[item] === '' || model[item] === 0) {
                    disabled = true
                }
            })
        }

        return disabled
    }

    useEffect(() => {
        setOpen(!!content)
    }, [content])


    const Container = type === 'no-modal' ? ElementDiv : ElementModal
    const successOnClose = onClose ? onClose : () => setSuccess(false)

    return <>
            <Feedback
                success={{
                    open: success,
                    onClose: successOnClose,
                    hideClose: true,
                    title: feedback.title,
                    message: '',
                    button: {
                        label: 'OK',
                        onPress: successOnClose,
                    },
                }}
            />
            <Container {...props} open={open && !success}>
                {title && <ElementHeading type={'h2'} text-center block-level-margin>{title}</ElementHeading>}
                {description && <ElementText text-center block-level-margin>{description}</ElementText>}

                {content && content({...props, model, setModel, store})}

                {messages.map((message) => message)}
                <FormSubmit
                    style={styles.submit}
                    onPress={store}
                    type={'secondary'}
                    disabled={disabled || required()}>
                    BEVESTIGEN
                </FormSubmit>
            </Container>
        </>
}

export default connect(null, dispatch => {
    return {
        save: (resource, livestream, token) => {
            return dispatch(save(resource, livestream, token))
        },
    }
})(Create)

const styles = StyleSheet.create({
    modal: {
        paddingHorizontal: 40,
    },
    submit: {
        marginTop: 40,
        zIndex: -1
    },
})
