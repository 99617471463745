import React from 'react'
import {ElementText, FormSubmit, Warning} from 'core/components'

export default function Redeem({redeem, submitRedeem}) {

    return <>
        <FormSubmit onPress={submitRedeem}>Voucher valideren</FormSubmit>
        <ElementText small text-center color-white>Door op Voucher valideren te klikken wissel je jouw voucher in voor
            een gratis dagticket.</ElementText>
        {
            Boolean(redeem.errors.length) && <Warning m-width-400 mb-2>
                {
                    redeem.errors.map((error, index) =>
                        <ElementText color-pinks-400 key={index}>{error.message}</ElementText>,
                    )
                }
            </Warning>
        }
    </>
}
