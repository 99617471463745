import {config} from 'core/functions'
import ReactGA from 'react-ga4'

const analytics = config('google.analytics')

export const analytics_init = (id) => {
    if (!window.GA_INITIALIZED && analytics) {
        ReactGA.initialize(id)
        window.GA_INITIALIZED = true
    }
}

export const analytics_view = (page) => {
    if (analytics) {
        ReactGA.send({ hitType: "pageview", page })
    }
}

export const analytics_event = (category, action, label, value) => {
    if (analytics) {
        let event = {
            category,
            action,
            label: String(label)
        }

        if (value) {
            event.value = value
        }

        ReactGA.event(event)
    }
}
