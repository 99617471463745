import client from 'core/client/Client'
export const RETRIEVE_SUCCESS = 'RETRIEVE_SUCCESS'
import {loading, errorMessage} from 'core/store'

export const retrieveSuccess = (route, data, loadMore, append) => {
    return {
        type: RETRIEVE_SUCCESS,
        route: route,
        data: data,
        loadMore: loadMore,
        append: append
    }
}

export const retrieve = (route, filters, token, loadMore, append, service = 'api') => {
    return (dispatch) => {
        dispatch(loading(true, route))
        return client.retrieve(route, filters, token, service)
            .then((response) => {
                dispatch(loading(false, route))
                return dispatch(retrieveSuccess(route, response, loadMore, append))
            })
            .catch((error) => {
                dispatch(loading(false, route))
                return errorMessage(route, dispatch, error, token)
            })
    }
}

export const retrieveMore = (route, filters, token) => {
    return retrieve(route, filters, token, true)
}

export const retrieveAppend = (route, filters, token) => {
    return retrieve(route, filters, token, false, true)
}
