import Forms from './Forms'
import Elements from './Elements'
import Channel from './Channel'
import Player from './Player'
import Menu from './Menu'
import Card from './Card'
import Wall from './Wall'
import Footer from './Footer'
import Videos from './Videos'
import Archive from './Archive'
import Users from './Users'
import Livestreams from './Livestreams'
import {array_get} from 'core/functions'
import {StyleSheet} from 'react-native'
import EyeIcon from 'mdi-react/EyeIcon'
import PencilIcon from 'mdi-react/PencilIcon'
import CloseIcon from 'mdi-react/CloseIcon'
import PauseCircleOutlineIcon from 'mdi-react/PauseCircleOutlineIcon'
import PlayCircleOutlineIcon from 'mdi-react/PlayCircleOutlineIcon'
import ForwardIcon from 'mdi-react/ForwardIcon'
import MenuDownIcon from 'mdi-react/MenuDownIcon'
import MenuUpIcon from 'mdi-react/MenuUpIcon'
import AlertIcon from 'mdi-react/AlertIcon'

import cookieCheck from 'third-party-cookie-check'

export const Icons = {
    eye: EyeIcon,
    pencil: PencilIcon,
    close: CloseIcon,
    play: PlayCircleOutlineIcon,
    pause: PauseCircleOutlineIcon,
    forward: ForwardIcon,
    menu_down: MenuDownIcon,
    menu_up: MenuUpIcon,
    alert: AlertIcon,
}

export const ThemeDefaults = {
    fontSizes: [12, 14, 16, 18, 22, 24, 26, 32, 36, 54, 64],
    fontFamilies: ['"gramatika-regular", sans-serif', '"gramatika-medium", sans-serif', '"gramatika-bold", sans-serif', 'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif'],
    colors: {
        white: '#fff',
        blacks: {
            100: 'rgba(0, 0, 0, 0.1)',
            200: 'rgba(0, 0, 0, 0.4)',
            300: 'rgba(0, 0, 0, 0.6)',
            400: '#000',
        },
        pinks: {
            100: 'rgba(220, 53, 69, 0.1)',
            200: '#ff0062',
            300: '#ff0943',
            400: '#dc3545',
        },
        reds: {
            100: '#e0125b',
            200: '#dc3545',
            500: '#ef4444',
        },
        grays: {
            100: '#F5F6F7',
            200: '#efeff4',
            300: '#bebebe',
            400: '#c1c0d0',
            500: '#adb5bd',
            600: '#81818e',
            700: '#777777',
            800: '#6c757d'
        },
        blues: {
            100: '#00ffec',
            200: '#00baff',
            300: '#19a4d4',
            400: '#10083e',
            500: '#1c263c',
            600: '#171520',
            700: 'rgba(16, 8, 62, 0.7)',
        },
        yellows: {
            100: '#ffba00',
            200: '#f8aa1c',
        },
        greens: {
            100: '#aac712',
        },
    },
    borderRadius: [2, 4],
    spaces: [5, 10, 15, 20, 30, 40, 60],
    borders: {
        thin: 1,
        thicc: 2,
    },
    shadows: {
        modal: {
            shadowColor: 'colors.blacks.400',
            shadowOpacity: 0.16,
            shadowOffset: {width: 0, height: 3},
            shadowRadius: 32,
        },
        menu: {
            shadowColor: 'colors.blacks.100',
            shadowOpacity: 0.1,
            shadowOffset: {width: 0, height: 2},
            shadowRadius: 20,
        },
    },
}

const RawHelpers = {
    'absolute-cover': {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        width: '100%',
        height: '100%'
    },
    'absolute-middle' : {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: [{translateX: '-50%'}, {translateY: '-50%'}]
    },
    'align-items-center': {
        alignItems: 'center',
    },
    'align-self-center': {
        alignSelf: 'center',
    },
    'align-self-end': {
        alignSelf: 'flex-end'
    },
    'bg-light': {
        backgroundColor: ThemeDefaults.colors.grays[200]
    },
    'bg-primary': {
        backgroundColor: ThemeDefaults.colors.pinks[400]
    },
    'bg-live': {
        backgroundColor: ThemeDefaults.colors.reds[500]
    },
    'bg-transition': {
        transitionDelay: '300ms',
        transitionProperty: 'background',
        transitionTimingFunction: 'ease',
    },
    'bg-transparent': {
        backgroundColor: 'transparent'
    },
    'bg-white' : {
        backgroundColor: ThemeDefaults.colors.white
    },
    'page-level-margin': {
        marginTop: '2rem'
    },
    'block-level-margin': {
        marginBottom: '1.75rem'
    },
    bold: {
        fontWeight: 'bold'
    },
    center: {
        textAlign: 'center',
    },
    'color-blues-400': {
        color: ThemeDefaults.colors.blues[400]
    },
    'color-grays-800': {
        color: ThemeDefaults.colors.grays[800]
    },
    'color-pinks-200': {
        color: ThemeDefaults.colors.pinks[200]
    },
    'color-pinks-400': {
        color: ThemeDefaults.colors.pinks[400]
    },
    'color-reds-500': {
        color: ThemeDefaults.colors.reds[500]
    },
    'color-white': {
        color: ThemeDefaults.colors.white
    },
    'd-flex': {
        display: 'flex',
    },
    'display-none': {
        display: 'none'
    },
    'display-block': {
        display: 'block'
    },
    'fade-transition': {
        transitionDelay: '300ms',
        transitionProperty: 'opacity',
        transitionTimingFunction: 'ease',
    },
    'fixed-top': {
        position: 'fixed',
        top: 0,
        right: 0,
        left: 0,
        zIndex: 1030,
    },
    'flex-column': {
        flexDirection: 'column'
    },
    'flex-fill': {
        flex: 1
    },
    'flex-row': {
        flexDirection: 'row',
    },
    'flex-shrink-0': {
        flexShrink: 0,
    },
    'flex-schrink-1': {
        flexShrink: 1,
    },
    'flex-wrap' : {
        flexWrap: 'wrap'
    },
    'full-overlay': {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 1020, // TODO get this from a variable or better the default theme
        overflow: 'auto',
        visibility: 'hidden',
        opacity: 0,
    },
    'full-overlay-open': {
        visibility: 'visible',
        opacity: 1,
    },
    'justify-content-center': {
        justifyContent: 'center',
    },
    'justify-content-between': {
        justifyContent: 'space-between',
    },
    'justify-content-end': {
        justifyContent: 'flex-end'
    },
    'make-circle': {
        borderRadius: '9999px'
    },
    'm-width-400': {
        maxWidth: 400
    },
    'mb-2' : {
        marginBottom: '0.75rem'
    },
    'pb-12' : {
        paddingBottom: '3rem'
    },
    'max-size': {
        maxWidth: '100%',
        maxHeight: '100%',
    },
    'mr-3': {
        marginRight: '1.25rem'
    },
    'mx-auto': {
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    'p-0': {
        padding: 0
    },
    'p-2': {
        padding: ThemeDefaults.spaces[0]
    },
    'p-4': {
        padding: ThemeDefaults.spaces[1]
    },
    'p-6': {
        padding: ThemeDefaults.spaces[2]
    },
    'p-8': {
        padding: ThemeDefaults.spaces[3]
    },
    'position-relative': {
        position: 'relative'
    },
    'small': {
        fontSize: '90%'
    },
    'strike-through': {
        textDecorationLine: 'line-through'
    },
    'text-center': {
        textAlign: 'center'
    },
    'text-left' : {
        textAlign: 'left'
    },
    'text-list': {
        marginLeft: 20
    },
    'transition': {
        transitionDelay: '300ms',
        transitionProperty: 'all',
        transitionTimingFunction: 'ease-in-out',
    },
    'w-100': {
        width: '100%',
    }
}



const Helpers = StyleSheet.create(RawHelpers)

function styles(value) {
    if (typeof value === 'string') {
        let realValue = array_get(ThemeDefaults, value)
        if (realValue) {
            return realValue
        }
    }

    return value
}

const fill = (fields) => {
    for (let fieldIndex in fields) {
        if (!fields.hasOwnProperty(fieldIndex)) {
            continue
        }

        let field = fields[fieldIndex]

        for (let columnIndex in field) {
            if (!field.hasOwnProperty(columnIndex)) {
                continue
            }
            let value = field[columnIndex]
            fields[fieldIndex][columnIndex] = styles(value)
        }
    }

    return fields
}

export const className = (classname, props = {}) => {
    return `${classname}${props.className ? ' ' + props.className : ''}`
}

function addStyles(props, styles) {
    for (let i in props) {
        if (props.hasOwnProperty(i) && Helpers.hasOwnProperty(i) && props[i]) {
            styles.push(Helpers[i])
        }
    }

    return styles
}

export const style = (style, props) => {
    let styles = [style]

    if (!props.style) {
        return addStyles(props, styles)
    }

    if (Array.isArray(props.style)) {
        return styles.concat(props.style)
    }

    styles.push(props.style)
    return addStyles(props, styles)
}

export const rawStyle = (style, props) => {
    let styles = props.style ? {...style, ...props.style} : style

    for (let i in props) {
        if (props.hasOwnProperty(i) && RawHelpers.hasOwnProperty(i)) {
            styles = {...styles, ...RawHelpers[i]}
        }
    }

    return styles
}

export const RawTheme = {
    ...fill(Forms),
    ...fill(Elements),
    ...fill(Player),
    ...fill(Channel),
    ...fill(Menu),
    ...fill(Card),
    ...fill(Footer),
    ...fill(Videos),
    ...fill(Livestreams),
    ...fill(Wall),
    ...fill(Archive),
    ...fill(Users),
}
export const Theme = StyleSheet.create(RawTheme)
