import React, {useRef} from 'react'
import {
    ElementDiv,
    ElementText,
    ElementHeading,
    ElementPress,
    ElementIcon,
    ElementScrollContainer,
    ElementScroll,
    VideoCard,
    PlayerWall,
} from 'core/components'
import {RawTheme, Theme} from 'core/style'

/**
 * Wall Container
 * @param children
 * @param elementRef
 * @param onLayout
 * @returns {*}
 * @constructor
 */
export const WallContainer = ({children, elementRef, onLayout}) => <ElementDiv
    className={'wall-container'}
    style={Theme.WallContainer}
    onLayout={onLayout}
    elementRef={elementRef}>
    {children}
</ElementDiv>

/**
 * Wall Player
 * @param video
 * @param videos
 * @returns {*}
 * @constructor
 */
export const WallPlayer = ({video, videos}) => {
    let options = {bigplay: 'off', sharing: 'off'}

    return <ElementDiv className={'wall-player'} absolute-cover>
        {
            <PlayerWall
                embedded={true}
                video={video}
                videos={videos}
                options={options}/>
        }
    </ElementDiv>
}

/**
 * Wall Overlay
 * @returns {*}
 * @constructor
 */
export const WallOverlay = () => <ElementDiv className={'wall-overlay'} style={Theme.WallOverlay} absolute-cover/>


export const WallPoster = (props) => <ElementDiv
    {...props}
    className={'wall-poster'}
    flex-fill
    d-flex>
    <ElementDiv
        className={`wall-title wall-title-${props.type}`}
        style={Theme.WallTitleContainer}
        align-self-center
        flex-row align-items-center>
        {props.children}
    </ElementDiv>
</ElementDiv>

export const WallPosterVideo = ({play, video = {}}) => <WallPoster justify-content-center type={'video'}>
    <ElementPress onPress={play}>
        <ElementDiv
            className={'wall-play-container'}
            style={Theme.WallPlayContainer}
            align-self-center
            flex-shrink-0>
            <ElementIcon type={'play-clean'} className={'wall-play'} style={Theme.WallPlay} make-circle/>
        </ElementDiv>
    </ElementPress>

    <ElementHeading
        onPress={play}
        className={'elements-line-clamp'}
        type={'h2'}
        style={RawTheme.WallTitle}
        align-self-center>
        {video.title}
    </ElementHeading>
</WallPoster>

export const WallPosterContinue = () => <WallPoster justify-content-center type={'continue'}>
    <ElementText
        className={'wall-poster-continue'}
        style={RawTheme.WallContinue}
        color-white>
        Verder kijken?
    </ElementText>
    <ElementText className={'wall-poster-continue-description'} style={Theme.WallContinueDescription}>
        Check deze clips
    </ElementText>
</WallPoster>

/**
 * Wall Interface
 * @param children
 * @param wallState
 * @returns {*}
 * @constructor
 */
export const WallInterface = ({children, wallState}) => {
    const styles = wallState !== 'loaded' ? {'pb-12': true} : {}

    return <ElementDiv
        {...styles}
        className={'wall-interface-container'}
        style={Theme.WallInterface}
        absolute-cover
        d-flex
        justify-content-end
        flex-column>
        {children}
    </ElementDiv>
}

function relatedVideos(videos, current, video) {
    let current_is_related = false
    let filtered = videos.filter((related) => {
        if (current.id === related.id) {
            current_is_related = true
            return false
        }

        return true
    })

    if (current_is_related) {
        filtered.push(video)
    }

    return filtered
}

/**
 * Wall Related
 * @param videos
 * @param setCurrent
 * @returns {*}
 * @constructor
 */
export const WallRelated = ({videos, setVideo}) => {
    const container = useRef(null)
    const item = useRef(null)

    return <ElementDiv
        className={'wall-related'}
        style={Theme.WallRelated}>
        <ElementDiv className={'wall-related-wrapper'} style={Theme.WallRelatedWrapper}>
            <ElementScrollContainer type={'parent'} container={container}>
                <ElementScrollContainer>
                    {
                        videos.map((related, index) => <ElementDiv
                            className={'wall-related-video'}
                            style={Theme.WallRelatedVideo}
                            key={index}>
                                <VideoCard item={item} onPress={() => setVideo(related, true)} video={related}/>
                            </ElementDiv>,
                        )
                    }
                </ElementScrollContainer>
            </ElementScrollContainer>
            <ElementScroll container={container} item={item} small/>
        </ElementDiv>
    </ElementDiv>
}

export const Wall = ({play, videos, current, video, playing, setVideo, wallState}) => {
    if (playing) {
        return <></>
    }

    return <>
        <WallOverlay/>

        <WallInterface wallState={wallState}>
            <WallPosterVideo play={play} video={current}/>
            {wallState === 'ended' && <WallPosterContinue/>}
            <WallRelated videos={videos} setVideo={setVideo} current={current} video={video}/>
        </WallInterface>
    </>
}
