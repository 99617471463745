import React from 'react'
import {useApi} from 'ra-fetch'
import {ElementButton, ElementDiv} from '../Elements'
import {TextInput} from 'react-native-web'
import {StyleSheet} from 'react-native'

export default function SaveUser({user, setUser}) {
    const [model, setModel, submitModel] = useApi('auth').update('user', {
        name: user.name,
        email: user.email,
    }, {id: user.id})

    function handleSubmit() {
        submitModel().then(response => {
            setUser(response.data)
        })
    }

    return  <ElementDiv style={styles.login}>
        <TextInput
            className={'add-username-input'}
            placeholder={'Voeg naam toe'}
            value={model.name}
            onChangeText={value => setModel({name: value})}
        />
        <ElementButton onClick={() => handleSubmit()}>Verzend</ElementButton>
    </ElementDiv>
}

const styles = StyleSheet.create({
    login: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: [{translate: '-50%, -50%'}],
        zIndex: 999,
    }
})

