import React, {useState} from 'react'
import {TextInput, Text as BaseText, TouchableWithoutFeedback, View} from 'react-native'
import {Option} from 'core/style'
import * as Styles from 'core/style/Theme'
import {
    ElementHeading,
    ElementText,
    ElementTextWithLink,
    ElementLinkButton,
    DatePicker,
    ElementDiv,
    ElementIcon,
} from 'core/components'
import {Link} from 'core/router'
import {array_get, translate} from 'core/functions'
import {Icons} from '@eyecons/storybook'

export {default as RegisterForm} from './User/Register'
export {default as LoginForm} from './User/Login'
export {default as TwoFactor} from './User/TwoFactor'
export {default as PasswordResetRequest} from './User/PasswordResetRequest'
export {default as PaymentForm} from './User/Payment'
export {default as RedeemForm} from './User/Redeem'
export {default as PasswordResetForm} from './User/PasswordReset'
export {default as UserVerifyForm} from './User/Verify'
export {default as ProfileForm} from './User/Profile'
import {Forms} from '@eyecons/storybook'

/**
 * Default Input Field
 * @param props
 */
export const Input = (props) => {
    return <TextInput
        {...props}
        className={Styles.className('input', props)}
        placeholderTextColor={Styles.ThemeDefaults.colors.grays['300']}
        style={Styles.style(Styles.Theme.Input, props)}/>
}

let final_search = ''

/**
 * Input Search
 * @param props
 */
export const InputSearch = (props) => {
    const {value, onSearch, placeholder} = props
    const [search, setSearch] = useState(value)

    const performSearch = (search) => {
        setSearch(search)
        final_search = search

        if (search === '') {
            return onSearch(search)
        }

        if (search.length <= 1) {
            return
        }

        setTimeout(() => {
            if (search === final_search) {
                onSearch(search)
            }
        }, 400)
    }

    return <Input
        className={Styles.className('input-search', props)}
        value={search}
        onChangeText={performSearch}
        type={'text'}
        placeholder={placeholder}
        style={Styles.style(Styles.Theme.InputSearch, props)}/>
}

export const SearchResults = ({results = 0}) => <ElementText
    style={Styles.Theme.MenuSearchResults}
    small>
    {results} resultaten
</ElementText>

const Check = ({onChange, label, checked}) => <TouchableWithoutFeedback
    onPress={() => onChange({target: {checked: !checked}})}>
    <View>
        <ElementText className={'input-question-label'} dangerouslySetInnerHTML={{__html: label}}/>
    </View>
</TouchableWithoutFeedback>

/**
 * Checkbox with a Question
 * @param props
 * @returns {*}
 * @constructor
 */
export const InputQuestion = (props) => <ElementDiv
    style={Styles.style(Styles.Theme.InputQuestion, props)}
    className={'input-question'}>
    <ElementDiv>
        <input {...props} type={'checkbox'} style={Styles.RawTheme.Checkbox}/>
    </ElementDiv>

    {props.html && <ElementText className={'input-question-label'} dangerouslySetInnerHTML={{__html: props.label}}/>}

    {
        !props.html && <ElementTextWithLink
            text={
                <Check onChange={props.onChange} checked={props.checked} label={props.label}/>
            }
            after={props.after ? <Check onChange={props.onChange} checked={props.checked} label={props.after}/> : null}
            link={props.link ? {...props.link, className: 'input-question-label'} : null}/>
    }
</ElementDiv>


export const InputDate = (props) =>
    <DatePicker
        {...props}
        placeholderText={props.placeholder}
        dateFormat={props.dateFormat || 'dd-MM-yyyy'}
        onChange={props.onChange}/>


export const Select = ({options, value_key, label_key, index_key, onChange, value, multiple = false, style}) => {
    const value_index = multiple ? value : options.findIndexBy(index_key || 'id', value)

    return <select
        multiple={multiple}
        value={value_index}
        style={Styles.rawStyle(Styles.RawTheme.Select, {style})}
        className={'form-control'}
        onChange={(event) => {
            let value = Number(event.target.value)

            if (!multiple) {
                return onChange(value_key ? options[value][value_key] : options[value])
            }

            let target_options = event.target.options
            let values = []
            for (let i = 0, l = target_options.length; i < l; i++) {
                if (target_options[i].selected) {
                    values.push(target_options[i].value)
                }
            }
            onChange(values)
        }}>
        {
            options.map((option, index) => <Option
                key={index}
                disabled={option.disabled}
                value={index}
                label={array_get(option, label_key || 'label')}/>,
            )
        }
    </select>
}
/**
 * Password forget button
 * @param props
 * @returns {*}
 * @constructor
 */
export const PasswordForget = (props) => <TouchableWithoutFeedback {...props}>
    <View>
        {
            <ElementText style={Styles.Theme.PasswordForget} small>
                {translate('Forgot password?', props.locale)}
            </ElementText>
        }
    </View>
</TouchableWithoutFeedback>

/**
 * Form Submit button
 * @param onPress
 * @param disabled
 * @param style
 * @param children
 * @param type
 * @param className
 * @param loading
 */
export const FormSubmit = ({onPress, disabled = false, style, children, type = '', className, loading = false}) =>
    <ElementDiv>
        <TouchableWithoutFeedback
            onPress={onPress}
            disabled={disabled || loading}>
            <ElementDiv className={'form-submit-container'}>
                <BaseText
                    style={Styles.style(Styles.Theme[`Submit${type.ucfirst()}`], {style})}
                    className={Styles.className(`form-submit${disabled ? ' form-submit-disabled' : ''}`, {className})}>
                    {children}
                </BaseText>
            </ElementDiv>
        </TouchableWithoutFeedback>
        {loading && <Icons.Icon name={'loader'} className={'mt-4'}/>}
    </ElementDiv>
/**
 * Default Form Submit Button
 * @param props
 */
export const FormSubmitIcon = ({onPress, children, icon}) => {
    return <TouchableWithoutFeedback onPress={onPress}>
        <ElementDiv style={Styles.Theme.SubmitIconContainer}>
            <ElementDiv style={Styles.Theme.SubmitIcon} d-flex align-items-center justify-content-center>
                <ElementIcon type={icon} color-pinks-200/>
            </ElementDiv>
            <BaseText style={Styles.Theme.SubmitIconButton}>
                {children}
            </BaseText>
        </ElementDiv>
    </TouchableWithoutFeedback>
}

export const FormSubmitText = ({children}) => <ElementText style={Styles.Theme.FormSubmitText}
                                                           small>{children}</ElementText>

/**
 * The Form Title
 * @param props
 */
export const FormTitle = (props) => <ElementHeading
    type={'h3'}
    {...props}
    style={Styles.RawTheme.FormTitle}>{props.children}</ElementHeading>

/**
 * Warning message
 * @param props
 */
export const Warning = (props) => <ElementText {...props} style={Styles.Theme.Warning}
                                               small>{props.children}</ElementText>

export function Errors({errors}) {
    if (!errors.length) {
        return <></>
    }

    return <Warning>
        {errors.map((error, index) => <span key={index}>{index > 0 && <br/>}{error.message}</span>)}
    </Warning>

}

/**
 * No Account link to Register
 */
export const NoAccount = (props) => <ElementTextWithLink
    {...props}
    style={Styles.Theme.NoAccount}
    small
    text={translate('No account?', props.locale) + ' '}
    link={props.type === 'button' ? {
        text: translate('Register', props.locale),
        onPress: props.onPress,
    } : {
        to: 'register',
        queryParams: {locale: props.locale},
        text: translate('Register', props.locale),
    }}/>

/**
 * Login Link to Login Page
 */
export const BackToLogin = (props) => <ElementTextWithLink
    {...props}
    style={Styles.Theme.NoAccount}
    small
    text={translate('Already registered?', props.locale) + ' '}
    link={props.type === 'button' ? {
        text: translate('Login', props.locale),
        onPress: props.onPress,
    } : {
        to: 'login',
        queryParams: {locale: props.locale},
        text: translate('Login', props.locale),
    }}/>

/**
 * Messsage
 * @param children
 * @param html
 * @param style
 */
export const Message = ({children, html = false, style = {}}) => {
    if (html) {
        return <ElementText style={Styles.style(Styles.Theme.Message, {style})}
                            dangerouslySetInnerHTML={{__html: children}}/>
    }
    return <ElementText style={Styles.style(Styles.Theme.Message, {style})}>{children}</ElementText>
}

/**
 * Password Strength Bar
 * @param style
 */
export const PasswordStrength = ({style}) => <View style={Styles.Theme.PasswordStrength}>
    <View style={[Styles.Theme.PasswordBar, style]}/>
</View>

/**
 * Verify Email Send page
 * @param locale
 * @returns {JSX.Element}
 * @constructor
 */
export const VerifyEmailSendPage = ({locale}) => <>
    <FormTitle>{translate('Check your mailbox', locale)}</FormTitle>
    <Message>{translate('We have just sent you an email to confirm your email address. Click on the link in the email to set a password and you are good to go!', locale)}</Message>
</>

/**
 * Verify Password Reset
 * @param locale
 * @returns {JSX.Element}
 * @constructor
 */
export const PasswordResetSendPage = ({locale}) => <>
    <FormTitle>{translate('Check your mailbox', locale)}</FormTitle>
    <Message>{translate('We have sent you a link to reset your password.', locale)}</Message>
</>

const popular = [
    '11111111', '22222222', '33333333', '44444444', '55555555', '66666666', '77777777', '88888888', '99999999', '10000000', '12000000', '12300000', '12340000', '12345000', '12345600', '12345670', '12345678', '123456789', '1234567890', '987654321', 'password', 'password1', 'wachtwoord', 'qwerty123', 'qwertyuiop', 'iloveyou', 'starwars', 'passw0rd', 'whatever', '!@#$%^&*', 'aa123456', '18atcskd2w', '1q2w3e4r', '3rjs1la7qe', '1q2w3e4r5t',
]

/**
 * Method to validate a password
 * @param user
 * @param passwordInfo
 * @param setPasswordInfo
 * @param locale
 * @returns {boolean}
 */
export const validatePassword = (user, passwordInfo, setPasswordInfo, locale) => {
    setPasswordInfo({...passwordInfo, message: ''})
    if (user.password !== user.password_confirmation) {
        setPasswordInfo({...passwordInfo, message: translate('Password confirmation incorrect.', locale)})
        return false
    }

    if (user.password.length < 8) {
        setPasswordInfo({...passwordInfo, message: translate('Pick a longer password.', locale)})
        return false
    }

    if (popular.search(user.password)) {
        setPasswordInfo({
            ...passwordInfo,
            message: translate('Pick a stronger password. Use a combination of letters, numbers and symbols.', locale),
        })
        return false
    }

    return true
}

/**
 * The password fields (password + password control)
 * @param passwordInfo
 * @param setPasswordInfo
 * @param user
 * @param setUser
 */
export const PasswordFields = ({passwordInfo, setPasswordInfo, user, setUser, locale}) => {
    const password = (password) => {
        setUser({password})

        if (popular.search(password) || user.password.length < 8) {
            return setPasswordInfo({
                ...passwordInfo, level: {
                    width: '33%', backgroundColor: Styles.ThemeDefaults.colors.reds[100],
                },
            })
        }

        if (!password.match(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/)) {
            return setPasswordInfo({
                ...passwordInfo,
                level: {
                    width: '66%', backgroundColor: Styles.ThemeDefaults.colors.yellows[200],
                },
            })
        }

        return setPasswordInfo({
            ...passwordInfo,
            level: {
                width: '100%', backgroundColor: Styles.ThemeDefaults.colors.greens[100],
            },
        })
    }

    return <>
        <Forms.Field>
            <Forms.Input
                type={'email'}
                placeholder={translate('E-mail address', locale)}
                onChange={email => setUser({email})}
                value={user.email}
            />
        </Forms.Field>

        <Forms.Field>
            <Forms.Input
                className={'mb-2'}
                type={'password'}
                placeholder={translate('Choose password', locale)}
                value={user.password}
                onChange={(value) => password(value)}
            />
            {passwordInfo.level.width && <PasswordStrength style={passwordInfo.level}/>}
        </Forms.Field>

        <Forms.Field>
            <Forms.Input
                type={'password'}
                placeholder={translate('Repeat password', locale)}
                value={user.password_confirmation}
                onChange={(password_confirmation) => setUser({password_confirmation})}
            />

            {passwordInfo.message !== '' && <Warning>{passwordInfo.message}</Warning>}
        </Forms.Field>
    </>
}

/**
 * Initial password information that can be pased to set password
 * @type {{level: {}, message: string}}
 */
export const initialPasswordInfo = {
    message: '',
    level: {},
}

/**
 * Form steps menu
 * @param steps
 * @param current_step
 * @param setStep
 */
export const FormSteps = ({steps, current_step, setStep}) => {
    return <View style={Styles.Theme.FormSteps}>
        {
            steps.map((step, index) => <TouchableWithoutFeedback key={index} onPress={() => setStep(step.name)}>
                    <View>
                        <ElementText
                            style={[Styles.Theme.FormStep, current_step === step.name ? Styles.Theme.FormStepActive : {}]}>
                            {step.label}
                        </ElementText>
                    </View>
                </TouchableWithoutFeedback>,
            )
        }
    </View>
}

/**
 * Form steps
 * @param current_step
 * @param step
 * @param children
 */
export const FormStep = ({current_step, step, children}) =>
    <ElementDiv style={current_step !== step ? Styles.Theme.FormStepContainerInactive : {}}>{children}</ElementDiv>

export const FormList = ({children}) => <View style={Styles.Theme.FormList}>{children}</View>

/**
 * Edit button
 * @param setEdit
 * @param item
 * @param style
 * @param to
 * @param params
 */
export const EditButton = ({to, params}) => {
    const PencilIcon = Styles.Icons.pencil
    return <Link
        to={to}
        params={params}
        style={Styles.Theme.EditButton}>
        <PencilIcon color={Styles.ThemeDefaults.colors.blues[500]}/>
    </Link>
}

export const SidebarTitle = ({children}) => <ElementText style={Styles.Theme.FormSidebarTitle}>{children}</ElementText>
export const SidebarBlock = ({children}) => <ElementDiv style={Styles.Theme.FormSidebarBlock}>{children}</ElementDiv>

export const SidebarItem = ({title, children}) => <View style={Styles.Theme.FormSidebarItem}>
    {title &&
        <SidebarBlock>
            <SidebarTitle>{title}</SidebarTitle>
        </SidebarBlock>
    }
    {children}
</View>
