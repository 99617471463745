const Player = {
    PlayerOverview: {
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'colors.blacks.300',
        // zIndex: 1000,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    PlayerMoreInfo: {
        color: 'colors.white',
        textDecorationLine: 'underline',
    },
    PlayerOverViewForm: {
        position: 'fixed',
        left: 0,
        zIndex: 1001,
        right: 0,
        top: 0,
        backgroundColor: 'colors.white',
        bottom: 0,
        overflowY: 'scroll',
    },
    PlayerOverViewFormIternal: {
        top: 74,
    },
    PlayerQuality: {
        backgroundColor: 'colors.white',
        paddingTop: 'spaces.2',
    },
    PlayerQualityButton: {
        maxWidth: 240,
        marginRight: 'auto'
    },
    PlayerLogin: {
        backgroundColor: 'colors.white',
        padding: 'spaces.2',
        maxWidth: 540,
        width: '100%',
        position: 'relative'
    },
    PlayerLoginTitle: {
        textAlign: 'center',
        marginBottom: 'spaces.2',
    },
    PlayerViewers: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        position: 'absolute',
        top: 16,
        left: 16,
        backgroundColor: 'colors.blacks.200',
        padding: 'spaces.1',
        zIndex: 999,
    },
    PlayerViewersCount: {
        fontSize: 'fonts.sizes.2',
        fontWeight: 'bold',
        color: 'colors.white',
        marginLeft: 5,
    },
    PlayerOption: {
        fontWeight: 'bold',
        color: 'colors.white',
        marginBottom: '2rem',
        display: 'block'
    },
    PlayerThumbnail: {
        width: '100%',
        paddingTop: '56.25%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
    }
}

export default Player
