import React from 'react'
import {View, StyleSheet} from 'react-native'
import Container from './Container'
import {Column, Row} from 'core/style'
import {Link} from 'core/router'
import {VideoTitle, VideoInfo, VideoLabels, CardContainer, VideoTime} from 'core/components'

const SideBar = props => {
    const {video, current} = props

    return <Container style={styles.container} xs={6} sm={4} md={3} lg={3}>
        <Row>
            <Column xs={12}>
                <Link
                    onPress={() => props.onPress(video)}
                    to={'videos/show'}
                    params={{slug: video.slug}}>
                    <CardContainer
                        minHeight={60}
                        background={{
                            image: video.thumbnail,
                        }}
                    >
                        <VideoTime time={video.length.seconds} right={15} bottom={15}/>
                    </CardContainer>
                </Link>
            </Column>
            <Column xs={12}>
                <VideoTitle
                    {...props}
                    style={styles.title}
                    color={current && current.id === video.id ? '#19a4d4' : ''}/>
                <VideoInfo style={styles.info}>
                    <VideoLabels video={video} uploader/>
                </VideoInfo>
            </Column>
        </Row>
    </Container>
};

export default SideBar;

const styles = StyleSheet.create({
    container: {
        paddingHorizontal: 5,
        marginBottom: 0,
    },
    card: {
        marginBottom: 15,
        paddingBottom: 15,
    },
    title: {
        paddingTop: 0,
    },
    info: {
        display: 'none',
        marginBottom: 0,
    },
})
