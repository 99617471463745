import DataService from './DataService'

/**
 * GameEventDataService.
 */
class GameEventDataService extends DataService {

    constructor(roomId) {
        super('/room/' + roomId + '/game')
    }

    listen(data, callback) {
        this.getRef(this.ref).child('events').orderByValue().limitToLast(
            DataService.RESULT_LIMIT).on('child_added', (value) => {
            callback(data => [...data, this.onChildAdded(value)])
        })
    }

    unListen() {
        this.getRef(this.ref).child('events').off(
            'child_added', () => {
            })
    }

    onChildAdded(snapshot) {
        let event = snapshot.val()
        event.key = snapshot.key
        return event
    }

    getRandom(min, max) {
        return Math.random() * (max - min) + min
    }

    addEvent(labelText, titleText, type, iconType, club) {
        let data = {
            label: labelText,
            title: titleText,
            type: type, //enum(match, score, timeline)
            subType: iconType,
            time: new Date().getTime().toString(),
            meta: {
                name: 'goal',
                seekPositionSecond: 11,
            },
            club: club,
        }

        /**
         * SportsEvent: {
                    name: 'Ajax vs Feyenoord',
                    homeTeam: 'Ajax',
                    awayTeam: 'Feyenoord',
                    sport: 'soccer'
                }
         */

        const newKey = super.getAll().child('events').push().key

        return super.update('/events/' + newKey, data)
    }
}

export default GameEventDataService
