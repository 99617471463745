const BunOrBurger = {
    width: '100%',
    height: 3,
    backgroundColor: 'colors.white',
    position: 'absolute',
    borderRadius: 1,
    transitionDelay: '300ms',
    transitionProperty: 'background',
    transitionTimingFunction: 'ease',
}
const Menu = {
    MenuNav: {
        height: 60,
        paddingHorizontal: '1rem', // TODO MAKE DEFAULT
        backgroundColor: 'colors.blues.400',
    },
    MenuToggle: {
        width: 30,
        height: 23,
        position: 'relative',
        cursor: 'pointer',
    },
    MenuBunFirst: {
        ...BunOrBurger,
        top: 0,
    },
    MenuBunFirstOpen: {
        transform: [{rotate: '45deg'}],
        top: 10,
    },
    MenuBunLast: {
        ...BunOrBurger,
        bottom: 0,
    },
    MenuBunLastOpen: {
        transform: [{rotate: '-45deg'}],
        bottom: 10,
    },
    MenuBurger: {
        ...BunOrBurger,
        top: 10,
    },
    MenuBurgerOpen: {
        opacity: 0,
    },
    MenuBrand: {
        height: 20,
        marginLeft: '1rem',
    },
    MenuLogo: {
        height: 'inherit',
        width: 'auto',
        display: 'block',
    },
    MenuBadge: {
        display: 'inline-block',
        paddingVertical: '.25em',
        paddingHorizontal: ' .4em',
        fontSize: '75%',
        fontWeight: '600',
        lineHeight: 12,
        textAlign: 'center',
        whiteSpace: 'nowrap',
        textAlignVertical: 'text-bottom',
        borderRadius: '10rem',
        marginLeft: '0.25rem',
        minWidth: 18,
        height: 18,
        color: 'colors.white',
    },
    MenuItem: {
        marginLeft: '2rem',
    },
    MenuItemText: {
        color: 'colors.white',
        fontWeight: '600',
        textTransform: 'uppercase',
        fontFamily: 'fontFamilies.0'
    },
    MenuSearchButton: {
        display: 'block'
    },
    MenuIcon: {
        marginLeft: '0.75rem',
    },
    MenuGlobal: {
        backgroundColor: 'colors.blues.400',
        paddingHorizontal: '1rem',
        paddingVertical: 60,
    },
    MenuContainer: {
        maxWidth: 1280,
    },
    MenuSection: {
        marginBottom: '1rem',
    },
    MenuHeader: {
        color: 'colors.blues.100',
    },
    MenuLinkContainer: {
        marginBottom: '0.75rem',
    },
    MenuLink: {
        color: 'colors.white',
    },
    MenuSearch: {
        backgroundColor: 'colors.white',
        paddingHorizontal: '1rem',
        paddingVertical: 60,
    },
    MenuSearchContainer: {
        paddingTop: '1rem',
    },
    MenuSearchInput: {
        marginBottom: '.75rem'
    },
    MenuSearchIconCloseContainer: {
        position: 'absolute',
        top: '1.25rem',
        right: 0,
    },
    MenuSearchIconClose: {
        color: 'colors.grays.500',
    },
    MenuSearchChannel: {
        marginBottom: '.75rem'
    },
    MenuSearchVideo: {
        marginBottom: '.75rem'
    },
    MenuSearchResults: {
        textTransform: 'uppercase',
        color: 'colors.grays.500',
        fontWeight: '600',
    }
}

export default Menu
