import React from 'react'
import {View, Text, StyleSheet} from 'react-native'
import {Grid} from 'core/style'
import Switch from './Switch'

const WithLabel = (props) => (
    <Grid className={`switch-with-label${props.big ? ' switch-with-label-big': ''}`} style={props.style || {}}>
        <Switch {...props}/>
        {props.label && <Text style={[styles.label, props.labelStyle || {}]}>{props.label}</Text>}
    </Grid>
)

const styles = StyleSheet.create({
    label: {
        marginLeft: 10,
    }
})

export default WithLabel
