const Submit = {
    backgroundColor: 'colors.pinks.200',
    borderRadius: 'borderRadius.1',
    width: '100%',
    color: 'colors.white',
    textAlign: 'center',
    paddingVertical: '0.375rem',
    paddingHorizontal: '1.75rem',
    marginTop: '1rem',
    marginBottom: '0.5rem',
    fontSize: 'fontSizes.1',
    cursor: 'pointer',
    fontFamily: 'fontFamilies.0',
    maxWidth: 300,
    alignSelf: 'center',
}
const FormLocale = {
    width: 30,
    height: 30,
    opacity: 0.2,
}

const Forms = {
    Input: {
        width: '100%',
        borderWidth: 1,
        borderColor: 'colors.grays.400',
        borderRadius: 'borderRadius.0',
        paddingHorizontal: '0.6rem',
        paddingVertical: '0.15rem',
        marginBottom: '0.5rem',
        backgroundColor: 'colors.white',
        fontSize: '1rem',
        fontFamily: 'fontFamilies.0',
    },
    InputSearch: {
        borderWidth: 0,
        borderColor: 'colors.grays.500',
        borderBottomWidth: 2,
        fontSize: '1.5rem',
        paddingLeft: 0,
        paddingVertical: '.5rem',
        paddingRight: '2rem',
        color: 'colors.grays.500',
        fontFamily: 'fontFamilies.0',
    },
    InputUserSearch: {
        maxWidth: 250,
        paddingVertical: 10,
        marginBottom: 0,
        textAlign: 'center',
    },
    Checkbox: {
        marginRight: 'spaces.1',
    },
    Select: {
        marginBottom: '0.75rem',
    },
    InputQuestion: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'baseline',
    },
    PasswordForget: {
        color: 'colors.blues.200',
        textAlign: 'right',
    },
    PasswordStrength: {
        width: '100%',
        marginBottom: 10,
    },
    PasswordBar: {
        height: 10,
    },
    DatePicker: {
        position: 'relative',
    },
    NoAccount: {
        textAlign: 'center',
        marginBottom: 10,
    },
    Sports: {
        paddingVertical: 10,
    },
    SportsTitle: {
        marginBottom: 10,
    },
    Submit: Submit,
    SubmitSecondary: {
        ...Submit,
        backgroundColor: 'colors.blues.300',
    },
    SubmitFull: {
        ...Submit,
        marginBottom: 0,
        maxWidth: 'auto',
        width: '100%',
    },
    FormSubmitText: {
        display: 'block',
        color: 'colors.white',
    },
    SubmitIconContainer: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: '0.75rem',
    },
    SubmitIcon: {
        width: 70,
        height: 'auto',
        backgroundColor: 'colors.white',
        borderTopLeftRadius: 'borderRadius.1',
        borderBottomLeftRadius: 'borderRadius.1',
    },
    SubmitIconButton: {
        ...Submit,
        borderBottomLeftRadius: 0,
        borderTopLeftRadius: 0,
        height: 'auto',
        marginTop: 0,
        marginBottom: 0,
        textAlign: 'left',
        fontSize: 'fontSizes.2',
        fontFamily: 'fontFamilies.2',
        maxWidth: 400,
    },
    FormTitle: {
        textAlign: 'center',
        marginBottom: 'spaces.4',
    },
    FormLang: {
        display: 'flex',
        flexDirection: 'row',
        gridColumnGap: '5px',
        alignSelf: 'center'
    },
    FormLocale: FormLocale,
    FormLocaleActive: {
        ...FormLocale,
        opacity: 1,
    },
    Warning: {
        color: 'colors.pinks.400',
        borderRadius: 'borderRadius.1',
        borderColor: 'colors.pinks.400',
        width: '100%',
        display: 'block',
        textAlign: 'center',
        borderWidth: 'borders.thicc',
        borderStyle: 'solid',
        paddingVertical: '0.75rem',
        paddingHorizontal: '1.25rem',
        marginTop: '1rem',
        backgroundColor: 'colors.pinks.100',
    },
    Message: {
        textAlign: 'center',
        maxWidth: 420,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    FormSteps: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        borderBottomWidth: 1,
        borderBottomColor: '#e0e0e0',
        marginBottom: 25,
    },
    FormStep: {
        marginRight: 30,
        paddingBottom: 10,
        fontSize: 12,
        color: '#bababa',
        fontWeight: 'bold',
    },
    FormStepActive: {
        borderBottomWidth: 2,
        borderBottomColor: '#182238',
        borderBottomStyle: 'solid',
        color: '#182238',
    },
    FormStepContainerInactive: {
        display: 'none',
    },
    EditButton: {
        display: 'inline-block',
    },
    FormScoreContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    FormScore: {
        maxWidth: 50,
        textAlign: 'center',
    },
    FormScoreLabel: {
        color: 'colors.blacks.400',
        fontWeight: 'bold',
        fontSize: 'fontSizes.1',
        marginVertical: 5,
    },
    FormList: {
        paddingHorizontal: 15,
        maxHeight: 170, // TODO this should be applied to the modal-container.
        overflowX: 'scroll',
    },
    FormSwitch: {
        alignItems: 'center',
        alignContent: 'center',
        marginBottom: 'spaces.1',
    },
    FormSwitchLabel: {
        fontSize: 'fontSizes.4',
        color: 'colors.blues.400',
        fontFamily: 'fontFamilies.3',
    },
    FormSidebarItem: {
        borderWidth: 1,
        borderColor: '#e0e0e0',
        borderRadius: 4,
        paddingVertical: 10,
        paddingHorizontal: 13,
        marginBottom: 10,
        maxHeight: 200,
        overflowY: 'scroll',
    },
    FormSidebarTitle: {
        fontWeight: 'bold',
        fontSize: 15,
        color: '#1e2330',
    },
    FormSidebarBlock: {
        marginBottom: 10,
    },
}

export default Forms
