import React from 'react'
import {StyleSheet, View} from 'react-native'

const Links = ({open, children, width, menu, style, offset}) => {

    return <View className={'hover-menu-container'} style={[
        styles.menu, {
            width: width || 170,
            display: open ? 'block' : 'none',
            top: offset
        },
        style || {},
    ]}>
        <View style={styles.background} className={'hover-menu'}>
            <div ref={menu}>
                {children}
            </div>
        </View>
    </View>
};

export default Links;

const styles = StyleSheet.create({
    menu: {
        position: 'absolute',
        paddingTop: 5,
        zIndex: 1
    },
    background: {
        paddingVertical: 15,
        paddingHorizontal: 10,
        backgroundColor: 'white',
    },
})
