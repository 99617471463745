import React, {useState} from 'react'
import {TouchableWithoutFeedback, TouchableHighlight, View, StyleSheet, Text} from 'react-native'
import {Grid, Input, H3} from 'core/style'
import FacebookIcon from 'mdi-react/FacebookIcon'
import TwitterIcon from 'mdi-react/TwitterIcon'
import CodeTagsIcon from 'mdi-react/CodeTagsIcon'
import ShareIcon from 'mdi-react/ShareIcon'
import {route, share, config} from 'core/functions'

const getEmbed = (type, id) => {
    switch (type) {
        case 'iframe':
            return '<iframe width="560" height="315" src="' + route('videos/embed', {id: id}) + '" frameBorder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>'
        case 'js':
            return `<script type="text/javascript" src="${config('app.url')}/static/js/video.min.js?id=${id}" async="true"></script>`
    }
}

const Index = ({video, style, user}) => {
    const [embed, setEmbed] = useState(false)
    const [copied, setCopied] = useState(false)
    const [embedType, setEmbedType] = useState('iframe')

    const shareVideo = (type) => {
        let shareUrl = route('videos/show', {slug: video.slug})
        share(type, shareUrl)

        if (type === 'copy') {
            setCopied(true)
        }
    }

    return <View style={[styles.container, style || {}]}>
        <Grid justify={'center'} style={styles.shareSocials}>
            <TouchableWithoutFeedback onPress={() => shareVideo('facebook')}>
                <View style={[styles.icon, styles.facebook]}>
                    <FacebookIcon color={'#FFF'}/>
                    <Text style={styles.shareTitle}>Facebook</Text>
                </View>
            </TouchableWithoutFeedback>

            <TouchableWithoutFeedback onPress={() => shareVideo('twitter')}>
                <View style={[styles.icon, styles.twitter]}>
                    <TwitterIcon color={'#FFF'}/>
                    <Text style={styles.shareTitle}>Twitter</Text>
                </View>
            </TouchableWithoutFeedback>

            <TouchableWithoutFeedback onPress={() => setEmbed(false)}>
                <View style={[styles.icon, styles.share]}>
                    <ShareIcon color={'#fff'}/>
                    <Text style={styles.shareTitle}>Delen</Text>
                </View>
            </TouchableWithoutFeedback>

            <TouchableWithoutFeedback onPress={() => setEmbed(true)}>
                <View style={[styles.icon, styles.code]}>
                    <CodeTagsIcon color={'#FFF'}/>
                    <Text style={styles.shareTitle}>Invoegen</Text>
                </View>
            </TouchableWithoutFeedback>
        </Grid>

        {
            !embed &&
            <View>
                <Input style={styles.shareInput} value={route('videos/show', {slug: video.slug})}/>
                <TouchableHighlight onPress={() => shareVideo('copy')}>
                    <View>
                        <Text style={styles.shareButton}>{copied ? 'GEKOPIEERD!' : 'KOPIEREN'}</Text>
                    </View>
                </TouchableHighlight>
            </View>
        }

        {
            embed &&
            <View style={styles.embed}>
                <Grid style={styles.embed_menu}>
                    <TouchableWithoutFeedback onPress={() => setEmbedType('iframe')}>
                        <View>
                            <H3 style={{
                                color: embedType === 'iframe' ? '#1E2241' : '#bababa',
                            }}>Embed iFrame</H3>
                        </View>
                    </TouchableWithoutFeedback>
                    <TouchableWithoutFeedback onPress={() => setEmbedType('js')}>
                        <View>
                            <H3 style={{
                                color: embedType === 'js' ? '#1E2241' : '#bababa',
                            }}>Embed Javascript</H3>
                        </View>
                    </TouchableWithoutFeedback>
                </Grid>
                <Text style={styles.codeContainer}>{getEmbed(embedType, video.id)}</Text>
            </View>
        }
    </View>
};

export default Index;

const styles = StyleSheet.create({
    container: {
        marginBottom: 40,
    },
    shareSocials: {
        alignItems: 'center',
    },
    shareTitle: {
        position: 'absolute',
        bottom: -20,
        color: '#cfcfcf',
        fontSize: 12,
        fontWeight: 'bold',
    },
    icon: {
        width: 50,
        height: 50,
        borderRadius: 50,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    facebook: {
        backgroundColor: '#3b5998',
    },
    twitter: {
        backgroundColor: '#1da1f2',
        marginLeft: 20,
    },
    share: {
        backgroundColor: '#000',
        marginLeft: 20,
    },
    code: {
        marginLeft: 20,
        backgroundColor: '#c5c7cd',
    },
    codeContainer: {
        borderColor: '#e0e0e0',
        borderWidth: 1,
        borderStyle: 'solid',
        padding: 10,
    },
    shareInput: {
        marginTop: 38,
        marginBottom: 0,
        width: '100%',
        paddingRight: 100,
    },
    shareButton: {
        color: '#1fa5d4',
        position: 'absolute',
        right: 10,
        bottom: 11,
        fontWeight: 'bold',
        fontSize: 12,
    },
    embed: {
        marginTop: 30,
    },
    embed_menu: {
        justifyContent: 'space-evenly',
        paddingVertical: 10,
    },
})
