import firebase from '../providers/firebase'
import {Cookies} from 'js-expansion'
import {config} from 'core/functions'

/**
 * MessagingDataService.
 */
class MessagingDataService {
    constructor() {
        this.messaging = MessagingDataService.supported() ? firebase.messaging() : {}
    }

    static supported() {
        return firebase.messaging.isSupported()
    }

    requestToken(setToken) {
        navigator.serviceWorker.register(`/static/js/firebase-${config('app.env')}.js`)
            .then((serviceWorkerRegistration) => {
                this.messaging.getToken({
                    vapidKey: config('firebase.vap_id_key'),
                    serviceWorkerRegistration,
                }).then(token => {
                    if (token) {
                        Cookies.write('firebase_token', token)
                        setToken(token)
                    }
                }).catch((err) => {
                })
            })

    }

    onMessageListener() {
        return new Promise((resolve) => {
            this.messaging.onMessage((payload) => {
                resolve(payload)
            })
        }).catch(error => {
            console.log(error)
        })
    }

    getToken() {
        return Cookies.read('firebase_token')
    }

    removeToken() {
        Cookies.clear('firebase_token')
    }
}

export default MessagingDataService
