import React, {useEffect, useState} from 'react'
import {View, Text, TouchableHighlight, Button, StyleSheet} from 'react-native'
import {useSpring, animated} from 'react-spring'
import {ElementDiv} from 'core/components'
import {Touchable, TouchableNativeFeedback} from 'react-native-web'

/**
 * Tabbed Pane.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function PaneTabbed(props) {
    const [tab, setTab] = useState(props.tabs[0])

    const switchTab = (name) => {
        setTab(name)
    }

    let children = React.Children.toArray(props.children)

    const getVisibilityProps = (selectedTab, currentTab) => {

        let selected = false
        if (selectedTab === currentTab) {
            selected = true
        }

        return useSpring({
            reset: true,
            to: {opacity: 0, height: '100%', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0},
            from: {opacity: 1, height: '100%', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0},
            reverse: selected,
        })
    }

    return (
        <View style={styles.container}>
            <View style={styles.header}>
                <>
                    {props.tabs.map(tabName => (
                        <TouchableHighlight
                            underlayColor="white" key={tabName} onPress={() => switchTab(tabName)}
                            style={[styles.tab, tab === tabName ? styles.active : '']}
                        >
                            <Text
                                style={{fontWeight: 'bold', textTransform: 'uppercase'}}
                                key={tabName}
                            >
                                {tabName}
                            </Text>
                        </TouchableHighlight>
                    ))}
                </>
            </View>
            <View className={'inner-tab'} style={styles.innerTab}>
                <ElementDiv style={styles.innerTabDiv}>
                    {props.tabs.map((tabName, index) => (
                        tab == tabName && <animated.div key={index} style={{
                            ...styles.inner,
                            ...getVisibilityProps(tab, tabName),
                        }}>
                            {children[index]}
                        </animated.div>
                    ))}
                </ElementDiv>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        height: '100%',
        display: 'flex',
    },
    innerTab: {
        flex: 1,
        minHeight: '350px',
        overflowX: 'scroll',
    },
    innerTabDiv: {
        display: 'flex',
        height: '100%',
    },
    inner: {
        opacity: 0.0,
        flex: 1,
        height: '100%',
    },
    header: {
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
//        position: 'sticky',
        top: 0,
        zIndex: 10,
        shadowColor: '#10083E',
        shadowOffset: {
            width: 0,
            height: 6,
        },
        shadowOpacity: 0.2,
        shadowRadius: 32,
    },
    tab: {
        padding: 20,
        borderBottomColor: 'white',
        borderBottomWidth: 2,
    },
    active: {
        borderBottomColor: '#10083E',
        display: 'block',
    },
})
