const Videos = {
    VideoLive: {
        zIndex: 1010,
        marginBottom: 30,
        backgroundColor: 'colors.blues.600',
    },
    VideoPlayer: {
        zIndex: 1,
    },
    VideoMatchResult: {
        marginBottom: 0,
    },
    VideoRecommendationsTitleFirst: {
        marginBottom: '1.75rem',
    },
    VideoRecommendationsAd: {
        margin: '20px 0'
    },
    VideoLength: {
        backgroundColor: 'colors.blues.700',
        position: 'absolute',
        bottom: '.75rem',
        right: '.75rem',
        paddingVertical: '.1rem',
        paddingHorizontal: '.4rem',
        color: 'colors.white',
        borderRadius: 3,
        fontWeight: '600',
    },
    VideoPlay: {
        fontSize: 40,
    },
    VideoSportIcon: {
        fontSize: '1.25em',
        marginRight: '.5rem',
    },
    VideoCompetition: {
        color: '#adb5bd', // TODO colors.grays.500 not working why not?
    },
    VideosTitle: {
        marginBottom: 0,
    },
    VideoLiveCard: {
        marginBottom: 10,
    },
    VideoLiveInfo: {
        position: 'absolute',
        bottom: '.1rem',
        left: '1rem',
    },
    VideoLiveTitle: {
        marginTop: '0.5rem',
        color: 'colors.white',
    },
    VideoLiveBadge: {
        fontSize: 'inherit',
        textTransform: 'uppercase',
        color: 'colors.white',
        borderRadius: 3,
        backgroundColor: 'colors.reds.500',
        alignItems: 'center',
        marginLeft: 0,
    },
}

export default Videos
