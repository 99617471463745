import React from 'react'
import {StyleSheet, Text} from 'react-native'
const Message = (props) => (
    <Text style={styles.message} dangerouslySetInnerHTML={{__html: props.children}}/>
)

const styles = StyleSheet.create({
    message: {
        fontSize: 15,
        textAlign: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
        color: '#717171',
        display: 'block',
        maxWidth: 320
    }
})

export default Message
