import thunkMiddleware from 'redux-thunk'
import { createStore, applyMiddleware } from 'redux'
import {reducer} from './reducers'

export const makeStore = (initialState, options) => {
    return createStore(
        reducer,
        initialState,
        applyMiddleware(
            thunkMiddleware,
        )
    )
};

export * from './Actions'

