import React from 'react'
import {StyleSheet, Text} from 'react-native'
import {Column, Row} from 'core/style'

export const ListTitle = ({style, children}) => <Text style={[styles.title, style || {}]}>{children}</Text>

export const ListValue = ({color, children}) => <Text
    style={[styles.text, {color: color || '#717171'}]}>
    {children}
</Text>

export const ListColumn = (props) => {
    const {text, title, styles = {}} = props
    const texts = Array.isArray(text) ? text : [text]

    return <Column {...props}>
        {
            !props.children &&
            <>
                {title && <ListTitle style={styles.title}>{title}</ListTitle>}
                {
                    texts.map((item, index) =>
                        <ListValue key={index}>{item}</ListValue>)
                }
            </>
        }
        {props.children}
    </Column>
}

export const ListRow = (props) => <Row {...props} style={[styles.container, props.style || {
    backgroundColor: props['background-color'] || 'white'
}]}>
    {props.children}
</Row>

export const ListColumnRow = (props) =>
    <Column {...props}>
        {
            props.columns.map((column, index) => <Row key={index}>
                    <Column lg={4}>
                        <ListTitle style={styles.title}>{column.title}</ListTitle>
                    </Column>
                    <Column lg={8}>
                        <ListValue
                            color={column.color || ''}>
                            {column.text}
                        </ListValue>
                    </Column>
                </Row>
            )
        }
    </Column>

export const ListButtons = (props) =>
    <Column className={'list-column-buttons'} style={styles.buttons} {...props}>
        {props.children}
    </Column>


const styles = StyleSheet.create({
    title: {
        fontWeight: 'bold',
        fontSize: 12,
        color: '#10083E',
        marginBottom: 5,
    },
    text: {
        color: '#717171',
        marginBottom: 5,
    },
    container: {
        marginBottom: 5,
        padding: 15
    },
    buttons: {
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        borderTopColor: '#dfdfdf',
        borderTopWidth: 1,
        paddingTop: 15,
        marginTop: 15,
    },
})
