const API_URL = process.env.NEXT_PUBLIC_STORYBOOK_SERVICE_BACKEND_URL
const translations = require('./translations')

const environment = {
    app: {
        url: process.env.NEXT_PUBLIC_STORYBOOK_SITE_URL,
        env: process.env.APP_ENV,
        debug: process.env.APP_DEBUG === 'true',
        port: process.env.APP_PORT || 3000,
        ratelimiting: process.env.APP_RATELIMITING === 'true',
        client: process.env.APP_CLIENT || 'jwplayer',
        cache_enabled: process.env.APP_CACHE_ENABLED === 'true',
        cache_time: process.env.APP_CACHE_TIME || 120,
        underconstruction: process.env.APP_UNDERCONSTRUCTION === 'true',
        ads: process.env.NEXT_PUBLIC_STORYBOOK_ADS === 'true',
        version: process.env.APP_VERSION || 1,
        cdn: process.env.APP_CDN,
        message: {
            enabled: process.env.APP_MESSAGE_ENABLED === 'true',
            title: process.env.APP_MESSAGE_TITLE,
            content: process.env.APP_MESSAGE_CONTENT,
        },
    },
    api: {
        url: API_URL,
        token: process.env.API_TOKEN,
        secret: process.env.API_SECRET,
    },
    player: {
        id: process.env.PLAYER_ID || 'qS44A2x1',
        ads: {
            client: process.env.PLAYER_ADS_CLIENT,
        },
    },
    advertising: {
        mmcdnScript: process.env.ADS_MMCDN_SCRIPT ?? 'https://mmcdn.nl/tags/eyecons.com/home.js',
    },
    google: {
        analytics: process.env.GOOGLE_ANALYTICS,
    },
    sentry: {
        enabled: process.env.SENTRY_ENABLED === 'true',
        dsn: process.env.SENTRY_DSN,
    },
    redis: {
        host: process.env.REDIS_HOST,
        port: process.env.REDIS_PORT,
        password: process.env.REDIS_PASSWORD,
        username: process.env.REDIS_USERNAME,
        database: process.env.REDIS_DATABASE,
        tls: {},
    },
    firebase: {
        project_id: process.env.FIREBASE_PROJECT_ID,
        app_id: process.env.FIREBASE_APP_ID,
        database_url: process.env.FIREBASE_DATABASE_URL,
        storage_bucket: process.env.FIREBASE_STORAGE_BUCKET,
        api_key: process.env.FIREBASE_API_KEY,
        auth_domain: process.env.FIREBASE_AUTH_DOMAIN,
        message_sender_id: process.env.FIREBASE_MESSAGE_SENDER_ID,
        measurement_id: process.env.FIREBASE_MEASUREMENT_ID,
        debug_token: process.env.FIREBASE_DEBUG_TOKEN,
        vap_key: process.env.FIREBASE_VAP_ID_KEY
    },
    pro: {
        enabled: process.env.PRO_ENABLED === 'true',
    }
}

module.exports = {
    API_URL,
    environment,
    translations,
}
