import NextRouter from 'next/router'
import {Router} from 'core/router'
import {scroll, config} from 'core/functions'

class Route {
    timer = null

    constructor(to, params, queryParams) {
        this.to = to
        this.params = params || {}
        this.queryParams = queryParams
    }

    progress = (progressUpdate) => {
        this.progressUpdate = progressUpdate
        NextRouter.events.on('routeChangeStart', this.routeChangeStart)
        NextRouter.events.on('routeChangeComplete', this.routeChangeEnd)
        NextRouter.events.on('routeChangeError', this.routeChangeEnd)

        return this
    }

    routeChangeEnd = () => {
        clearInterval(this.timer)
        if (
            history &&
            history.state.options &&
            !history.state.options.shallow
        ) {
            scroll('top')
        }
        this.progressUpdate(0)
        NextRouter.events.off('routeChangeStart')
        NextRouter.events.off('routeChangeComplete')
        NextRouter.events.off('routeChangeError')
    }

    routeChangeStart = () => {
        clearTimeout(this.timer)

        let progress = 0
        this.timer = setInterval(function() {
            progress += 0.1
            if (progress <= 1) {
                this.progressUpdate(progress)
            } else {
                clearInterval(this.timer)
            }
        }.bind(this), 300)
    }

    getPath() {
        return Router.getRoutePath(this.to, this.params)
    }

    getFullPath() {
        return config('app.url') + this.getPath()
    }

    redirect = () => {
        return Router.pushRoute(this.to, {...this.params, queryParams: this.queryParams})
    }

    redirectUrl = () => {
        return NextRouter.push(this.to)
    }
}

export const route = (to, params, queryParams) => {
    return new Route(to, params, queryParams)
}
