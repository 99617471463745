import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import * as Shared from './shared'

const PlayerLive = ({user, setUser, video, hls, setPlayable, video_params, autoplay = false}) => {
    const playable = Boolean(user.id && video.status === 'published' && !video.hidden && !user.too_many_sessions)

    if (setPlayable) {
        useEffect(() => {
            setPlayable(playable)
        }, [playable])
    }

    return <>
        {
            user.id && user.too_many_sessions && <Shared.PlayerTooManySessions/>
        }

        {
            !user.id && video.status === 'published' && <Shared.PlayerLoginWindow
                video={video}
                setUser={setUser}
            />
        }
        <Shared.PlayerWithBroadcasting
            autoplay={autoplay}
            playable={playable}
            user={user}
            hls={hls}
            video_params={video_params}
        />
    </>
}

export default connect(state => {
    return {
        video: state.video,
        video_params: state.video_params
    }
})(PlayerLive)
