import React from 'react'
import {connect} from 'react-redux'
import * as Shared from './shared'

function PlayerVod({user, hls, video, autoplay = false, preview = false}) {
    return <>
        <Shared.PlayerWithShare
            autoplay={autoplay}
            hls={hls}
            user={user}
            playable={true}
            video={video}
            preview={preview}
        />
    </>
}

export default connect(state => {
    return {
        video: state.video,
    }
})(PlayerVod)
