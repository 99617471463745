import React from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {to_time} from 'core/functions'

/**
 * ChatMessage.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function ChatMessage(props) {
    const TYPE_SENT = 'sent'
    const TYPE_DEFAULT = 'default'

    let messageType = (props.username === props.currentUsername) ? TYPE_SENT : TYPE_DEFAULT

    return <>
        { props.message &&
        <View style={[styles.messageWrapper, messageType === TYPE_SENT ? styles.messageWrapperSent : '']}>
            <Text style={[styles.meta, messageType === TYPE_SENT ? styles.metaSent : styles.metaDefault ] }>
                {to_time((new Date(Number(props.time))))} - {props.username}
            </Text>
            <Text style={[styles.message, messageType === TYPE_SENT ? styles.messageSent : styles.messageDefault ]}>{props.message}</Text>
        </View>
        }
    </>
}

const styles = StyleSheet.create({
    messageWrapper: {
        marginBottom: 12,
        display: 'flex',
    },
    message: {
        padding: 10,
        borderRadius: 10,
        backgroundColor: '#efeff4'
    },
    messageWrapperSent: {
    },
    messageSent: {
        color: '#ffffff',
        backgroundColor: '#1F9FFC',
        width: '70%',
        marginLeft: 'auto'
    },
    messageDefault: {
        width: '70%',
        marginRight: 0
    },
    meta: {
        color: '#C1C0D0',
        marginBottom: 4,
        fontSize: 12,
        fontWeight: 'bold',
    },
    metaSent: {
        width: '70%',
        marginLeft: 'auto'
    },
    metaDefault: {
        width: '70%',
        marginRight: 0
    }
});
