import firebase from '../providers/firebase'

/**
 * DataService.
 */
class DataService {
    static RESULT_LIMIT = 10

    constructor(database) {
        this.ref = database
        this.signin()
        this.db = this.getRef(this.ref)
    }

    firebase() {
        return this.firebase
    }

    getRef(ref) {
        return firebase.database().ref(ref)
    }

    getAll() {
        return this.db
    }

    create(object) {
        return this.db.push(object)
    }

    update(key, value) {
        return this.db.child(key).update(value)
    }

    delete(key) {
        return this.db.child(key).remove()
    }

    deleteAll() {
        return this.db.remove()
    }

    signin() {
        firebase.auth().signInAnonymously()
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                this.uid = user.uid
                // console.log('Got user with id', this.uid)
            } else {
                // User is signed out
            }
        })
    }
}

export default DataService
