const ScrollArrow = {
    position: 'absolute',
    top: 0,
    height: '100%',
    width: 80,
    backgroundColor: 'rgba(255, 255, 255, 0.8)', // TODO get from colors
    cursor: 'pointer',
}
const Channel = {
    ChannelTitleContainer: {
        width: '100%',
        marginBottom: '1rem',
    },
    ChannelTitle: {
        paddingHorizontal: '1rem',
    },
    ChannelTitleLivestreams: {
        paddingHorizontal: '0.25rem',
    },
    ChannelShortTitle: {
        color: 'colors.blues.400',
        fontWeight: '600',
        textTransform: 'uppercase',
    },
    ChannelInfo: {
        width: '80%',
        marginLeft: '.75rem',
    },
    ChannelLogo: {
        borderRadius: '50%',
        width: 60,
        height: 60,
        objectFit: 'cover'
    },
    ChannelLogoLeft: {
        marginRight: '.75rem',
    },
    ChannelLogoRight: {
        marginLeft: '.75rem',
    },
    ChannelCount: {
        lineHeight: '1.1',
        color: 'colors.grays.500',
    },
    ChannelScrollArrowSmall: {
        width: 50,
        height: 50,
        top: 20
    },
    ChannelScrollArrowLeft: {
        ...ScrollArrow,
        left: 0,
    },
    ChannelScrollArrowRight: {
        ...ScrollArrow,
        right: 0,
    },
    ChannelChevron: {
        fontSize: '2rem',
    },
    ChannelLivestreams: {
        paddingHorizontal: '1rem',
    }
}
export default Channel
