import React, {useRef, useState, useEffect} from 'react'
import {ElementDiv} from 'core/components'
import {ScrollView, Text, View} from 'react-native'
import {StyleSheet} from 'react-native'
import ChatInput from './Input'
import ChatMessage from './Message'
import ChatRoomDataService from '../../services/ChatRoomDataService'
import SaveUser from './SaveUser'

/**
 * ChatBox.
 * @param roomId
 * @param username
 * @returns {JSX.Element}
 * @constructor
 */
export default function ChatBox({user = {}, roomId, setUser}) {

    const lastMessage = useRef()
    const messageEnd = useRef()
    const [messages, setMessages] = useState([])
    const dataService = new ChatRoomDataService(roomId)
    
    useEffect(() => {
        dataService.listen(messages, setMessages)
        return () => {
            dataService.unListen()
        }
    }, [])

    const sendMessage = async (username, message) => {
        dataService.sendMessage(username, message)
    }

    useEffect(() => {
        scrollToBottom()
    }, [messages, user.name])

    useEffect(() => {
        scrollToBottom()
    })

    const scrollToBottom = () => {
        messageEnd.current?.scrollIntoView({behavior: 'smooth', block: 'end', inline: 'nearest'})
    }

    const hasUser = Boolean(user.name)

    return <View style={styles.container} className={!hasUser ? 'add-user-to-chat' : ''}>
        <ScrollView key="inner" style={styles.inner}>
            <ElementDiv>
                {
                    hasUser && messages.map((msg, index) => {
                        return (
                            <ElementDiv key={index}>
                                <Text key={lastMessage} ref={lastMessage}/>
                                <ChatMessage
                                    key={msg.key}
                                    identifier={msg.key}
                                    time={msg.time}
                                    username={msg.username}
                                    message={msg.message}
                                    currentUsername={user.name}/>
                                <div ref={messageEnd}/>
                            </ElementDiv>
                        )
                    })
                }

            </ElementDiv>

        </ScrollView>
        <ElementDiv>
            {
                hasUser &&
                <ChatInput username={user.name} sendMessage={sendMessage} />
            }
        </ElementDiv>
        {!hasUser && <SaveUser user={user} setUser={setUser}/>}
        {/*{!hasUser && <ElementButton style={styles.login} onClick={() => setLogin(true)}>Log in for use of chat</ElementButton>}*/}
    </View>
}

const styles = StyleSheet.create({
    container: {
        height: '100%',
        position: 'relative',
    },
    login: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: [{translate: '-50%, -50%'}],
        zIndex: 999,
    },
    inner: {
        padding: 16,
        flex: 1,
        overflow: 'scroll',
    },
})
