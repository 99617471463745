import {config} from 'core/functions'
import * as Sentry from '@sentry/node'
import { RewriteFrames } from '@sentry/integrations'
import getConfig from 'next/config'


export const init_sentry = () => {
    if (config('sentry.enabled')) {
        const next_config = getConfig()
        const distDir = `${next_config.serverRuntimeConfig.rootDir}/.next`
        Sentry.init({
            // release: "eyecons-front-end@2.9.9",
            environment: config('app.env'),
            enabled: true,
            integrations: [
                new RewriteFrames({
                    iteratee: (frame) => {
                        frame.filename = frame.filename.replace(distDir, 'app:///_next')
                        return frame
                    },
                }),
            ],
            dsn: config('sentry.dsn'),
            whitelistUrls: [/eyecons\.com/, /eyecons\.link/],
            tracesSampleRate: 0.2,
            beforeSend(event, hint) {
                console.log('Sentry sending ...', event)
                if (event.request.url.includes('eyecons')) {
                    return event
                }

                return false
            },
        })

        Sentry.configureScope(scope => {
            scope.setTag('app', 'site');
        });
    }
}

