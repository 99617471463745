import React from 'react'
import {View} from 'react-native'
import {Picture} from 'core/components'
import {useInView} from 'react-intersection-observer'
import {config, cdn} from 'core/functions'

const Container = (props) => {
    const [background, inView] = useInView({
        triggerOnce: true,
    })

    let style = {
        position: 'relative'
    }

    if (inView && props.background.image) {
        style.backgroundImage = `url(${props.background.image})`
        style.backgroundSize = 'cover'
        style.backgroundRepeat = 'no-repeat'
        style.backgroundPosition = 'center'
        style.position = 'relative'
        style.width = '100%'
    }

    let imageStyle = {
        width: '100%',
        height: 'auto',
        display: 'block',
        minHeight: props.minHeight || 90,
    }

    return (
        <div style={style} ref={background}>
            <Picture className={'placeholder'} style={imageStyle} source={'img/16x9.png'}/>
            {props.children}
        </div>

    )
}

export default Container
