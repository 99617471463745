import App from 'next/app'
import Head from 'next/head'
import React from 'react'
import withRedux from 'next-redux-wrapper'
import {Provider} from 'react-redux'
import {makeStore, setState} from 'core/store'
import Router from 'next/router'
import {analytics_init, analytics_view} from 'core/utils'
import {Notifications, ProgressBar, ElementGlobalMessage} from 'core/components'
import {config, cdn} from 'core/functions'
import 'core/assets/scss/index.scss'
import 'js-expansion'
import {init_sentry} from 'core/server'
import '@eyecons/storybook/dist/routes'
import '../api-routes'

init_sentry()

class MyApp extends App {
    constructor(props) {
        super(props)
        this.state = {message: true}
    }

    static canocialExceptions(route) {
        const exceptions = ['/videos/embed']
        return !exceptions.includes(route)
    }

    static async getInitialProps({Component, ctx}) {
        const {store, isServer, asPath, pathname, query} = ctx

        let pageProps = {}
        if (Component.getInitialProps) {
            pageProps = await Component.getInitialProps(ctx)
        }

        if (typeof pageProps === 'object') {
            pageProps.query = query
        }

        store.dispatch(setState('query', query))
        if(pageProps.user) {
            store.dispatch(setState('user', pageProps.user))
        }

        const path = asPath.indexOf('?') === -1 ? asPath : asPath.substring(0, asPath.indexOf('?'))

        return {
            pageProps,
            isServer,
            url: config('app.url') + path,
            canonical: MyApp.canocialExceptions(pathname),
            query
        }
    }

    analytics() {
        const google_analytics = config(`google.${this.props.pageProps.type || 'analytics'}`)
        const pathname = window.location.pathname

        if (google_analytics) {
            analytics_init(google_analytics)
            analytics_view(pathname + window.location.search)
        }
    }

    componentDidMount() {
        // window.Pusher = require('pusher-js')

        this.analytics()

        Router.onRouteChangeComplete = url => {
            analytics_view(url)
        }

        // this.unregisterServiceWorkers()
    }

    deleteCache() {
        caches.keys().then((keys) => {
            let count = 1
            keys.forEach((key) => {

                caches.delete(key).then(bool => {
                    console.log('cache deleted: ', bool)
                    if (count === keys.length) {
                        window.location.reload()
                    }

                    count++
                })
            })

        })
    }

    unregisterServiceWorkers() {
        const cleared = localStorage.getItem('pwa_cleared')

        if (cleared === 'true') {
            return
        }

        localStorage.setItem('pwa_cleared', 'true')

        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.getRegistrations().then(registrations => {
                for (let registration of registrations) {
                    registration.unregister().then(bool => {
                        console.log('unregister: ', bool)
                    })
                }

                this.deleteCache()
            })
        }
    }

    render() {
        const {Component, pageProps, store, url, canonical, query, router} = this.props

        return <>
            <Head>
                <title>EYECONS: Volg jouw favoriete sport bij Eyecons, voor de sport, voor de fans</title>
                <meta name={'description'}
                      content={'Op Eyecons.com vind je Live en On Demand video van topclubs in onder andere Voetbal, Hockey, Korfbal, Honkbal, Volleybal, Handbal en Rugby.'}/>
                <meta name="robots" content={config('app.env') === 'prod' ? 'index' : 'noindex'}/>
                <meta name="viewport"
                      content="width=device-width, initial-scale=1 initial-scale=1.0, maximum-scale=1.0, user-scalable=no"/>
                <link rel="icon" type="image/png" sizes="64x64" href={cdn('assets/head/favicon-64x64.png')}/>
                <link rel="icon" type="image/png" sizes="32x32" href={cdn('assets/head/favicon-32x32.png')}/>
                <link rel="icon" type="image/png" sizes="16x16" href={cdn('assets/head/favicon-16x16.png')}/>
                <link rel="apple-touch-icon" sizes="180x180" href={cdn('assets/head/apple-touch-icon.png')}/>
                <link rel="manifest" href="/manifest.json"/>
                <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent"/>
                <meta name="apple-mobile-web-app-capable" content="yes"/>
                <link rel="mask-icon" href={cdn('assets/head/safari-pinned-tab.svg" color="#5bbad5')}/>
                <link rel="shortcut icon" href={cdn('assets/head/favicon.ico')}/>
                <meta name="msapplication-TileColor" content="#14a2d2"/>
                <meta name="msapplication-config" content={cdn('assets/head/browserconfig.xml')}/>
                <meta name="theme-color" content="#ffffff"/>

                {canonical && <link rel={'canonical'} href={url}/>}
            </Head>
            <Provider store={store}>
                <>
                    <ProgressBar/>
                    {/*<Notifications query={query}/>*/}
                    {

                        this.state.message && config('app.message.enabled') &&
                        <ElementGlobalMessage onClose={() => this.setState({message: false})}/>

                    }
                    <Component {...pageProps}/>
                </>
            </Provider>
        </>
    }
}

export default withRedux(makeStore)(MyApp)
