import React from 'react'
import {connect} from 'react-redux'
import * as Shared from './shared'
import {useApi} from 'ra-fetch'

const PlayerNoLogin = ({user_nologin, video, query, video_params, autoplay = false}) => {
    const [exists] = useApi('auth').show('users.exists', {
        email: query.email
    })

    const playable = (user_nologin.id || exists.data.exists) && video.status === 'published'
    const showLogin = !playable && query.email && !exists.loading

    return <>
        {showLogin && video.status === 'published' && <Shared.PlayerNoLoginWindow query={query} video={video}/>}
        <Shared.PlayerWithBroadcasting
            autoplay={autoplay}
            playable={playable}
            video={video}
            user={user_nologin}
            video_params={video_params}
        />
    </>
}

export default connect(state => {
    return {
        user_nologin: state.user_nologin,
        video: state.video,
        query: state.query,
        video_params: state.video_params,
    }
})(PlayerNoLogin)
