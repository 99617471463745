import React, {useState, useRef} from 'react'
import {View, TextInput, StyleSheet} from 'react-native'
import Filter from 'bad-words'
import profanity from '../../providers/profanity'
import {Buttons, Forms} from '@eyecons/storybook'

/**
 * ChatInput.
 * @param username
 * @param sendMessage
 * @returns {JSX.Element}
 * @constructor
 */
export default function ChatInput({username, sendMessage}) {
    const inputRef = useRef()
    const [value, setValue] = useState('')
    const [message, setMessage] = useState(true)

    const onSendMessage = () => {
        if (value && message) {

            const noCharacter = value.match('[^a-zA-Z0-9]')

            if (noCharacter !== null) {
                sendMessage(username, value)
                setMessage(false)
            } else {
                sendMessage(username, filterInput(value))
                setMessage(false)
            }

            setValue('')
        }

        setTimeout(() => {
            inputRef.current.focus()
            setMessage(true)
        }, 500)

    }

    const filterInput = (value) => {
        const profanityFilter = new Filter({list: profanity})
        return profanityFilter.clean(value)
    }

    const sendOnEnter = (key) => {
        if (key === 'Enter') {
            onSendMessage()
        }
    }

    return <View style={styles.form} className={'input-chat-wrapper'}>
        <Forms.Field>
            <TextInput
                style={[styles.input, {paddingBottom: 47}]}
                value={value}
                onChangeText={newValue => setValue(newValue)}
                onKeyPress={event => sendOnEnter(event.key)}
                placeholder="Typ hier een bericht.."
                ref={inputRef}
            />
        </Forms.Field>
        <Buttons.Button type={'primary'} onClick={onSendMessage}>Versturen</Buttons.Button>
    </View>
}

const styles = StyleSheet.create({
    input: {
        padding: 10,
        borderWidth: 1,
        borderColor: '#C1C0D0',
        borderRadius: 4,
        width: '100%'
    },
    form: {
        width: '100%',
        padding: 20,
        borderRadius: 4,
        overflow: 'hidden',
        backgroundColor: '#FFFFFF',
//        position: 'sticky',
        bottom: 0,
        zIndex: 10,
    },
    button: {
        borderRadius: 4,
        marginTop: 8,
    },
    button_text: {
        paddingVertical: 10,
        paddingHorizontal: 20,
        backgroundColor: '#FF0062',
        color: '#FFFFFF',
        textAlign: 'center',
        fontWeight: 'bold',
    },
})
