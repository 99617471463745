import React, {useState, useEffect, useRef} from 'react'
import {StyleSheet} from 'react-native'
import {Waypoint} from 'react-waypoint'
import {connect} from 'react-redux'
import {retrieveMore, retrieve} from 'core/store'
import {Scroll, ElementDiv, InputSearch, SearchResults} from 'core/components'
import {H2, Grid, Column, Theme} from 'core/style'
import {Link, Router} from 'core/router'
import Default from './Default'
import SideBar from './SideBar'
import Livestream from './Livestream'
import {Icons} from '@eyecons/storybook'

const components = {
    default: Default,
    sidebar: SideBar,
    livestream: Livestream,
}

const Search = ({loadMoreVideos, pagination, search, setSearch}) => <ElementDiv
    className={'input-search-container'}
    block-level-margin>
    <InputSearch
        value={search}
        style={Theme.InputUserSearch}
        className={'input-search'}
        onSearch={search => {
            setSearch(search)
            loadMoreVideos(1, search)
        }}
        placeholder={'Zoeken..'}/>
    {Boolean(search.length) && <SearchResults results={pagination.total}/>}
</ElementDiv>

const Cards = (props) => {
    const {
        type,
        videos,
        loading,
        pagination,
        loadMore,
        notfound,
        query,
        current_route,
        retrieveMore,
        retrieve,
        params,
        token,
        className,
        horizontal,
        width,
        route,
    } = props

    const container = useRef(null)
    const [mounted, setMounted] = useState(false)
    const [search, setSearch] = useState(query.search || '')

    const Type = components[type || 'default']
    const {current_page, last_page} = pagination
    const is_last_page = current_page >= last_page

    useEffect(() => {
        setMounted(true)
    }, [])

    const loadMoreVideos = (page, search) => {
        const method = page === 1 ? retrieve : retrieveMore
        method(route, {...params, page, search}, token)

        if (!['sidebar', 'livestream'].includes(type)) {
            Router.replaceRoute(current_route, {
                ...query,
                queryParams: {
                    page,
                    search,
                },
            }, {shallow: true})
        }
    }

    const hasNextPage = () => {
        return pagination.current_page && Boolean(videos.length) && loadMore && mounted && !is_last_page
    }

    if (videos.length === 0 && notfound && !loading) {
        return <H2 className={'no-results-title'}>
            {Boolean(notfound.length) ? notfound : 'Geen video\'s gevonden'}
        </H2>
    }

    return (
        <>
            <Search search={search} setSearch={setSearch} loadMoreVideos={loadMoreVideos} pagination={pagination}/>
            <ElementDiv style={{width: width || '100%'}}>
                <div
                    className={`${type === 'livestream' ? '' : 'row'} video-cards${className ? ' ' + className : ''}`}
                    ref={container}
                    style={{
                        ...htmlStyles[type === 'livestream' ? 'livestream' : 'container'],
                        ...htmlStyles[horizontal ? 'horizontal' : 'vertical'],
                    }}>
                    {videos.map(video => <Type {...props} video={video} key={video.id}/>)}

                    {
                        horizontal || type === 'livestream' &&
                        <>
                            {
                                hasNextPage() &&
                                <Column xs={6} sm={4} style={styles.loading}>
                                    {loading && <Icons.Icon name={'loader'}/>}
                                    {
                                        !loading &&
                                        <Waypoint horizontal={true}
                                                  onEnter={() => loadMoreVideos(pagination.current_page + 1, search)}/>
                                    }
                                </Column>
                            }
                        </>
                    }
                    {horizontal && <Scroll style={styles} container={container}/>}
                </div>
            </ElementDiv>
            {
                hasNextPage() && !horizontal && type !== 'livestream' &&
                <ElementDiv style={styles.loading}>
                    {loading && <Icons.Icon name={'loader'}/>}
                    {
                        !loading &&
                        <Waypoint onEnter={() => loadMoreVideos(pagination.current_page + 1, search)}/>
                    }
                </ElementDiv>
            }

            {
                loadMore && type !== 'livestream' &&
                <Grid style={styles.pagination}>
                    {
                        current_page > 1 &&
                        <Link
                            to={current_route}
                            params={query}
                            queryParams={{page: current_page - 1}}>vorige</Link>
                    }
                    {
                        !is_last_page &&
                        <Link
                            to={current_route}
                            params={query}
                            queryParams={{page: current_page + 1}}>volgende</Link>
                    }
                </Grid>
            }
        </>
    )
}

const htmlStyles = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        scrollBehavior: 'smooth',
        marginLeft: -10,
        marginRight: -10,
    },
    livestream: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        scrollBehavior: 'smooth',
    },
    horizontal: {
        flexWrap: 'nowrap',
        overflowX: 'scroll',
    },
    vertical: {},
}

const styles = StyleSheet.create({
    pagination: {
        visibility: 'hidden',
    },
    loading: {
        marginTop: 30,
    },
    scroll_button: {
        marginTop: 20,
        position: 'absolute',
    },
    scroll_button_left: {
        left: 10,
    },
    scroll_button_right: {
        right: 10,
    },
})

export default connect((state, props) => {
    const {query, params} = props
    const route = props.route || 'videos'
    const meta = state[route].meta || {}

    const pagination = query && query.page > meta.current_page ? {
        current_page: Number(query.page),
        from: Number(query.page),
        last_page: meta.total / params.paginate,
        to: params.paginate,
        per_page: params.paginate,
    } : meta

    return {
        loading: state.loading[route],
        pagination: pagination,
        route: route,
    }
}, dispatch => {
    return {
        retrieveMore: (endpoint, params, token) => {
            return dispatch(retrieveMore(endpoint, params, token))
        },
        retrieve: (endpoint, params, token) => {
            return dispatch(retrieve(endpoint, params, token))
        },
    }
})(Cards)
