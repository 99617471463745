import React from 'react'
import {View, StyleSheet} from 'react-native'
import {VideoTitle, CardContainer} from 'core/components'
import PlayCircleOutlineIcon from 'mdi-react/PlayCircleOutlineIcon'
import {Link} from 'core/router'

const Livestream = props => {
    const {video, current} = props

    return (
        <View style={styles.container}>
            <CardContainer
                minHeight={60}
                background={{
                    image: video.thumbnail,
                }}
            >
                <View style={styles.title_container}>
                    <Link to={'videos/show'} params={{slug: video.slug}}>
                        <PlayCircleOutlineIcon size={50} color={'#fff'}/>
                        <VideoTitle disabled {...props} color={current && current.id === video.id ? '#19a4d4' : ''} style={styles.title}/>
                    </Link>
                </View>
            </CardContainer>
        </View>
    )
};

export default Livestream;

const styles = StyleSheet.create({
    container: {
        width: 384,
        height: 216,
        paddingRight: 21,
    },
    card: {
        marginBottom: 15,
        paddingBottom: 15,
    },
    title_container: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        paddingHorizontal: 20,
        paddingBottom: 20,
    },
    title: {
        marginTop: 10
    }
})
