import React, {useState, useEffect} from 'react'
import {StyleSheet, View, TouchableWithoutFeedback} from 'react-native'
import ChevronRightIcon from 'mdi-react/ChevronRightIcon'
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon'

const Index = ({container, style, color}) => {
    const [scrolling, setScrolling] = useState(0)
    const [offset, setOffset] = useState(0)

    useEffect(() => {
        if (container.current) {
            let width = container.current.getBoundingClientRect().width
            setOffset(width / 100 * 60)
        }
    }, [])

    const scroll = (direction) => {
        let amount = scrolling
        if (direction === 'left') {
            amount -= offset
        }

        if (direction === 'right') {
            amount += offset
        }

        setScrolling(amount)
        container.current.scrollLeft = amount
    }

    return <React.Fragment>
        <TouchableWithoutFeedback onPress={() => scroll('left')}>
            <View style={[
                styles.scroll_button,
                style.scroll_button || {},
                style.scroll_button_left || {}]}>
                <ChevronLeftIcon color={color || ''} size={32}/>
            </View>
        </TouchableWithoutFeedback>
        <TouchableWithoutFeedback onPress={() => scroll('right')}>
            <View style={[styles.scroll_button, style.scroll_button || {}, style.scroll_button_right || {}]}>
                <ChevronRightIcon color={color || ''} size={32}/>
            </View>
        </TouchableWithoutFeedback>
    </React.Fragment>
};

export default Index;

const styles = StyleSheet.create({
    scroll_button: {
        backgroundColor: 'white',
        borderRadius: '50%',
        padding: 10,
        width: 50
    },
})
