import React, {Component} from 'react'
import {connect} from 'react-redux'
import {route, Router} from 'core/router'
import {Column} from 'core/style'
import {progressUpdate} from 'core/store'
import {ElementDiv} from 'core/components'
import {query} from 'core/functions'

const IGNORE_ROUTE_TYPES = ['http', '#']

class Link extends Component {
    redirect = (event) => {
        const {to = '', params, noprogress, progressUpdate, target, onPress, action, type, logout, queryParams} = this.props

        if (target || event.ctrlKey || event.metaKey) {
            return true
        }

        event.preventDefault()

        if (onPress) {
            return onPress()
        }

        const router = route(to, params || {}, queryParams || {})

        if (!noprogress) {
            router.progress(progressUpdate)
        }

        if (action) {
            action()
        }

        router.redirect()
    }

    render() {
        const {children, xs, to, params, target, link, queryParams, style, className} = this.props
        const Element = xs ? Column : ElementDiv
        const getDefaultHref = () => `${Router.getRoutePath(to, params || {})}${query(queryParams)}`

        if (this.props['no-container']) {
            return <a
                {...link}
                style={style}
                className={className}
                onClick={this.redirect}
                target={target}
                href={to && IGNORE_ROUTE_TYPES.some((type) => to.startsWith(type)) ? to : getDefaultHref()}
            >
                {children}
            </a>
        }

        return <Element {...this.props}>
            <a
                {...link}
                style={{width: '100%', height: '100%'}}
                onClick={this.redirect}
                target={target}
                href={getDefaultHref()}
            >
                {children}
            </a>
        </Element>

    }
}

export default connect(null, dispatch => {
    return {
        progressUpdate: progress => {
            dispatch(progressUpdate(progress))
        },
    }
})(Link)
