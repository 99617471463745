import React from 'react'
import {View, TextInput, StyleSheet, Text, Picker} from 'react-native'
export {default as DropDown} from './DropDown'
export {default as Option} from './Option'


export const Input = props => (
    <TextInput {...props} value={props.value || ''} style={[
        styles.input,
        styles.base,
        props['text-center'] ? styles.center : {},
        props.style || {},
    ]}/>
)

export const TextArea = props => (
    <TextInput {...props} style={[styles.base, styles.input, styles.textarea]}/>
)

export const Warning = props => (
    <Text style={[styles.warning, props.style || {}]}>{props.children}</Text>
)

export const Success = props => (
    <Text style={[styles.success, props.style || {}]}>{props.children}</Text>
)

const styles = StyleSheet.create({
    base: {
        width: '100%',
        borderWidth: 1,
        borderColor: '#e0e0e0',
        borderRadius: 4,
        paddingHorizontal: 8.5,
        paddingVertical: 10,
        marginBottom: 10,
        backgroundColor: 'white',
    },
    input: {
        fontSize: 15,
    },
    textarea: {
        height: 100,
    },
    warning: {
        color: '#d53c3e',
        textAlign: 'center',
        marginTop: 20,
        display: 'block'
    },
    success: {
        color: 'green',
        textAlign: 'center',
        marginTop: 20,
        display: 'block'
    },
    center: {
        textAlign: 'center',
    }
})
