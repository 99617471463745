import React, {useEffect} from 'react'
import {useApi} from 'ra-fetch'
import {translate} from 'core/functions'
import {Forms} from '@eyecons/storybook'

export default function PasswordResetRequest({email, onSend, locale}) {
    const [password, setPassword, submitPassword] = useApi('auth').store('password.forgot', {
        email
    })

    const sendPasswordReset = () => {
        submitPassword({locale}).then((response) => {
            onSend(response)
        })
    }

    useEffect(() => {
        if (email !== password.data.email) {
            setPassword({email})
        }
    }, [email])

    return <Forms.Buttons
        type={'wide'}
        errors={password.errors}
        title={translate('Reset password', locale)}
        submit={sendPasswordReset}
    />
}
