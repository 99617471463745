import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import {retrieve} from 'core/store'
import {Theme} from 'core/style'
import {ElementText} from 'core/components'


const Livestreams = ({livestreams}) => {
    if (!livestreams) {
        return <></>
    }

    return <>
        <ElementText style={Theme.MenuBadge} className={'menu-badge'} bg-live>
            {livestreams}
        </ElementText>
    </>
}

export default connect(state => {
    return {
        livestreams: state.livestream_counts.data.count
    }
})(Livestreams)
