import React from 'react'
import {StyleSheet, Text} from 'react-native'
import {Grid} from 'core/style'
import {Link} from 'core/router'

const Title = ({video, color}) =>
    <Text style={[styles.title, color ? {color} : {}]} className={'truncate'}>
        {video.title}
    </Text>


const TitleComponent = ({video, children, onPress, style, color, disabled}) => <Grid
    justify={'space-between'}
    className={'video-card-description'}
    style={[styles.container, style || {}]}>
    {disabled && <Title video={video} color={color}/>}
    {
        !disabled &&
        <Link
            style={styles.link}
            onPress={onPress ? () => onPress(video) : null}
            to={'videos/show'}
            params={{slug: video.slug}}>
            <Title video={video} color={color}/>
        </Link>
    }
    {children}
</Grid>;


export default TitleComponent;

const styles = StyleSheet.create({
    container: {
        paddingBottom: 7,
    },
    link: {
        display: 'block',
    },
    title: {
        color: '#10083E',
        fontSize: 14,
        fontWeight: 'bold',
        paddingLeft: 5
    },
})
