import {Router} from 'ra-fetch'

let events_url = process.env.NEXT_PUBLIC_STORYBOOK_SERVICE_BACKEND_URL + '/'
events_url = events_url.replace('/api/', '')

Router.api('site', process.env.NEXT_PUBLIC_STORYBOOK_SITE_URL)
    .store('users.nologin', '/api/nologin')

Router.api('backend-web', events_url)
    .store('events', '/events')
