import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/messaging'
import {config} from 'core/functions'

if (!firebase.apps.length) {
    firebase.initializeApp({
        'projectId': config('firebase.project_id'),
        'appId': config('firebase.app_id'),
        'databaseURL': config('firebase.database_url'),
        'storageBucket': config('firebase.storage_bucket'),
        'apiKey': config('firebase.api_key'),
        'authDomain': config('firebase.auth_domain'),
        'messagingSenderId': config('firebase.message_sender_id'),
        'measurementId': config('firebase.measurement_id'),
        'debugtoken': config('firebase.debug_token'),
    })
} else {
    firebase.app() // if already initialized, use that one
}

export default firebase
