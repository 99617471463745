import React from "react";
import {ElementButtonLink, ElementDiv, PlayerMessage, PlayerModal} from "core/components";
import {Theme} from "core/style";

export default function PlayerLiveToClub({video}) {
    return <div className="text-center">
        <ElementDiv style={[
            Theme.PlayerThumbnail,
            { backgroundImage: `url(${video.thumbnail_large})` }
        ]} />
        <PlayerModal>
            <div className={'mb-4'}>
                <PlayerMessage
                    title={'Eyecons Livestream'}
                    message={'Om de livestream te bekijken, ga je naar het clubkanaal via onderstaande knop'}
                />
            </div>

            <ElementButtonLink
                to={`${process.env.NEXT_PUBLIC_STORYBOOK_SITE_URL}/kanalen/${video.channel.name}`}
                target={'_blank'}
            >
                KIJK LIVE
            </ElementButtonLink>
        </PlayerModal>
    </div>
}