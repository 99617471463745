import React, {useState} from 'react'
import {ElementDiv, ElementImage, ElementPress} from 'core/components'
import {RawTheme, Theme} from '../style'

function Option({onChange, value, locale}) {
    return <ElementPress container onPress={() => onChange(locale)} style={[

    ]}>
        <ElementImage
            disable_cdn
            source={`/static/img/${locale}.svg`}
            alt={locale}
            style={locale === value ? RawTheme.FormLocaleActive : RawTheme.FormLocale}
        />
    </ElementPress>
}

export default function useLocale(defaultLocale = 'nl') {
    const [locale, setLocale] = useState(defaultLocale)

    function Locale () {
        return <ElementDiv style={Theme.FormLang} className={'form-lang'}>
            <Option locale={'en'} value={locale} onChange={setLocale}/>
            <Option locale={'nl'} value={locale} onChange={setLocale}/>
        </ElementDiv>
    }

    return [Locale, locale]
}
