import React from 'react'
import {TouchableWithoutFeedback, Text, StyleSheet} from 'react-native'
import {ElementDiv} from 'core/components'

const Submit = (props) => (
    <TouchableWithoutFeedback
        disabled={props.disabled || false}
        onPress={props.onPress}>
        <ElementDiv
            className={props.className}
            style={[
                styles.button,
                props.disabled ? styles.disabled : {
                    backgroundColor: props.background || '#19a4d4'
                },
                props.style || {}
            ]}
        >
        <Text style={styles.title}>{props.title.toUpperCase()}</Text>
        </ElementDiv>
    </TouchableWithoutFeedback>
)

const styles = StyleSheet.create({
    button: {
        alignItems: 'center',
        paddingVertical: 12,
        paddingHorizontal: 30,
        borderRadius: 4,
        alignSelf: 'center',
        cursor: 'pointer',
        zIndex: -1
    },
    disabled: {
        backgroundColor: '#dfdfdf'
    },
    title: {
        color: 'white',
        fontWeight: 'bold',
        fontSize: 14
    }
})

export default Submit
