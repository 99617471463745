import React, {useRef} from 'react'
import {Theme, RawTheme} from 'core/style'
import {
    ElementDiv,
    ElementHeading,
    ElementScrollContainer,
    ElementScrollItem,
    ElementScroll,
    ElementSection,
    ElementColumn,
    ElementRow,
    ElementText,
    ElementInfo,
    ElementPress,
    ElementContainer,
    ElementImage,
    Card,
    CardThumbnailImage,
    VideoCard,
    VideoLiveCard,
    Select,
    Player,
} from 'core/components'
import {Link, route} from 'core/router'

/**
 * Channel Title
 * @param channel
 * @param type
 * @param size
 * @param style
 * @param disable_cdn
 * @returns {*}
 * @constructor
 */
export const ChannelTitle = ({channel, type, size, style, disable_cdn = false}) => {
    const count = channel.videos_count ? (channel.videos_count / 1000).toFixed(1) : 0
    const Channel = <ElementDiv flex-row align-items-center>
        {Boolean(channel.logo) &&
        <ElementImage disable_cdn={disable_cdn} source={channel.logo} style={RawTheme.ChannelLogo} alt={channel.name}/>}
        <ElementDiv style={Theme.ChannelInfo}>
            <ElementHeading type={size || 'h2'} style={RawTheme.ChannelHeading}>{channel.label}</ElementHeading>
            {
                Boolean(channel.videos_count) &&
                <ElementText style={Theme.ChannelCount}>
                    {count > 1 ? count + 'K' : channel.videos_count} video's
                </ElementText>
            }
        </ElementDiv>
    </ElementDiv>

    return <ElementDiv
        style={style}
        className={`channel-title${type ? ' channel-' + type : ''}`}>

        {
            channel.link &&
            <Link {...channel.link} className={'channel-link'} no-container>
                {Channel}
            </Link>
        }

        {!channel.link && Channel}
    </ElementDiv>
}

export const Channel = ({channel, videos, livestreams, size, style, disable_cdn = false}) => {
    const container = useRef(null)
    const item = useRef(null)

    return <ElementDiv className={'channel block-level-margin'} block-level-margin position-relative>
        <ElementDiv className={'channel-title-container'} style={Theme.ChannelTitleContainer}>
            <ChannelTitle disable_cdn={disable_cdn} style={[Theme.ChannelTitle, style]} channel={channel} size={size}/>
        </ElementDiv>

        {
            videos &&
            <ElementDiv position-relative>
                <ElementScrollContainer type={'parent'} container={container}>
                    <ElementScrollContainer>
                        {
                            videos.map((video, index) => <VideoCard
                                key={index}
                                video={video}
                                type={'scroll-item'}
                                item={item}/>,
                            )
                        }
                    </ElementScrollContainer>
                </ElementScrollContainer>
                {videos.length > 4 && <ElementScroll hideMobile container={container} item={item}/>}
            </ElementDiv>
        }

        {
            livestreams &&
            <ElementDiv className={'channel-livestreams'} style={Theme.ChannelLivestreams}>
                <ElementRow>
                    {livestreams.map((livestream, index) => <VideoLiveCard livestream={livestream} key={index}/>)}
                </ElementRow>
            </ElementDiv>
        }
    </ElementDiv>
}


/**
 * Channels
 * @param sport
 * @param logo
 * @param channels
 * @returns {*}
 * @constructor
 */
export const Channels = ({sport, logo, channels}) => <ElementSection>
    <ChannelTitle disable_cdn type={'competition'} size={'h3'} channel={{
        label: 'Eyecons ' + sport.label,
        logo: sport.logo,
        videos_count: sport.videos_count,
    }}/>

    <ElementRow>
        {
            channels.map((channel, index) => <ElementColumn key={index} col={6} md={3} xl={2}>
                    <Card to={'channels/show'} params={{id: channel.name}}>
                        <CardThumbnailImage alt={channel.name} thumbnail={channel.logo} wrapperProps={{'mb-2': true}}/>
                        <ElementHeading type={'h4'} text-center>{channel.label}</ElementHeading>
                    </Card>
                </ElementColumn>,
            )
        }
    </ElementRow>
</ElementSection>
