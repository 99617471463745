import {StyleSheet, View, Text} from 'react-native'
import CheckIcon from 'mdi-react/CheckIcon'
import {ElementModal} from 'core/components'

const Container = props => <ElementModal {...props} style={styles.modal} type={'small'}>
    {
        props.type &&
        <View style={[
            styles.icon,
            styles[props.type] || {}
        ]}>
            {props.type === 'success' && <CheckIcon size={40} color={'#7ed456'}/>}
            {props.type === 'warning' && <Text style={styles.warning_icon}>!</Text>}
        </View>
    }
    {props.children}
</ElementModal>;

export default Container;

const styles = StyleSheet.create({
    icon: {
        marginHorizontal: 'auto',
        width: 70,
        height: 70,
        borderRadius: 70,
        borderWidth: 2,
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 20
    },
    success: {
        borderColor: '#7ed456',
    },
    warning: {
        borderColor: '#d53c3e',
    },
    warning_icon: {
        color: '#d53c3e',
        fontSize: 32,
        fontWeight: 'bold'
    }
})
