const Router = require('nextjs-dynamic-routes')
const router = new Router()

function addInitialSlash(str) {
    return !!str.match(/^\//) ? str : `/${str}`
}

function add(route) {
    const page = route.page || addInitialSlash(route.name)
    const cache = route.cache !== false
    router.routes.push({...route, page, cache})
}

add({
    name: 'index',
    pattern: '/',
    cache: true
})

add({
    name: 'wall',
    pattern: '/wall/:id',
    page: '/wall',
    cache: true
})

add({
    name: 'channels',
    pattern: '/kanalen',
    cache: true
})

add({
    name: 'channels/show',
    pattern: '/kanalen/:id',
    cache: true
})

add({
    name: 'orders/show',
    pattern: '/orders/:id',
    cache: false
})

add({
    name: 'sports/show',
    pattern: '/sports/:name',
    cache: false
})

add({
    name: 'channels/show/team',
    pattern: '/kanalen/:id/:team',
    page: '/channels/show',
    cache: true
})

add({
    name: 'channels/show/team/category',
    pattern: '/kanalen/:id/:team/:category',
    page: '/channels/show',
    cache: false
})

add({
  name: 'graphics',
  pattern: '/graphics/:channelId',
  page: '/graphics',
  cache: false,
})

add({
    name: 'pro',
    pattern: '/pro',
    cache: true
})

add({
    name: 'live',
    pattern: '/live',
    page: '/videos/livestreams',
    cache: true
})

add({
    name: 'events/show',
    pattern: '/events/:slug',
    cache: true
})

add({
    name: 'events/embed',
    pattern: '/events/:id/embed',
    cache: true
})

add({
    name: 'live/show',
    pattern: '/live/:slug',
    cache: true
})

add({
    name: 'live/embed',
    pattern: '/live/:id/embed',
    cache: true
})

add({
    name: 'videos/livestreams',
    pattern: '/videos/livestreams',
    cache: true
})

add({
    name: 'subscriptions',
    pattern: '/subscriptions',
    cache: true
})

add({
    name: 'subscriptions/create',
    pattern: '/subscriptions/:id/create',
    cache: true
})

add({
    name: 'videos/trending',
    pattern: '/videos/trending',
    cache: true
})

add({
    name: 'videos/livestreams',
    pattern: '/videos/livestreams',
    cache: true
})

add({
    name: 'videos/new',
    pattern: '/videos/nieuw',
    cache: true
})

add({
    name: 'videos/search',
    pattern: '/videos/search',
    cache: false
})

add({
    name: 'videos/show',
    pattern: '/videos/:slug',
    cache: true
})

add({
    name: 'videos/private',
    pattern: '/videos/:hash/private',
    cache: false
})

add({
    name: 'videos/preview',
    pattern: '/videos/:id/preview',
    cache: false
})

add({
    name: 'videos/embed',
    pattern: '/videos/:id/embed',
    cache: true
})

add({
    name: 'users/verify',
    pattern: '/users/verify/:id/:token',
    page: '/users/verify',
    cache: false
})

add({
    name: 'users/password_reset',
    pattern: '/users/password_reset/:token',
    page: '/users/password_reset',
    cache: false
})

add({
    name: 'login',
    pattern: '/login',
    cache: false
})

add({
    name: '2fa',
    pattern: '/login/:id/2fa',
    cache: false
})

add({
    name: 'register',
    pattern: '/register',
    cache: false
})

add({
    name: 'health',
    pattern: '/health',
    cache: false
})

add({
    name: 'sentry_sample_error',
    pattern: '/sentry_sample_error',
    cache: false
})

add({
    name: 'pages',
    pattern: '/:id',
    cache: true
})

module.exports = router
