import React, {useEffect, useState} from 'react'
import {Notifications as BaseNotifications} from '@eyecons/storybook'
import MessagingDataService from 'core/services/MessagingDataService'

export const Notification = ({notification, onClose}) => {
    return <BaseNotifications.Notification
        type={notification.type}
        open={true}
        onClose={onClose}
        title={notification.title}
        image={notification.image}
        link={notification.link}
        description={notification.description}
    />
}

function initNotifications(query) {
    let notifications = []

    if (query.verified) {
        notifications.push({
            type: 'success',
            title: 'Email Verificatie',
            description: 'Je account is nu geverifieerd!',
        })
    }

    return notifications
}

let stored = []

export const Notifications = ({query}) => {
    const [notifications, setNotifications] = useState([])
    stored = notifications

    useEffect(() => {
        setNotifications(initNotifications(query))

        if (!MessagingDataService.supported()) {
           return
        }

        const firebase = new MessagingDataService()

        firebase.onMessageListener().then(payload => {
            const newNotifications = stored.clone()
            const notification = {
                title: payload.notification.title,
                description: payload.notification.body,
                image: payload.notification.image,
            }

            if (payload.data && payload.data.link_href && payload.data.link_label) {
                notification.link = {
                    href: payload.data.link_href,
                    label: payload.data.link_label,
                }
            }

            newNotifications.push(notification)
            setNotifications(newNotifications)
        }).catch(err => console.log('failed: ', err))

        navigator.permissions.query({name: 'notifications'}).then(function (result) {
            if (result.state === 'prompt') {
                firebase.removeToken()
            }
        })
    }, [])

    if (!notifications.length) {
        return <></>
    }

    return <BaseNotifications.Overlay>
        {
            notifications.map((notification, index) => <Notification
                notification={notification}
                onClose={() => {
                    const newNotifications = notifications.clone().delete(notification.title, 'title')
                    setNotifications(newNotifications)
                }}
                key={index}/>,
            )
        }
    </BaseNotifications.Overlay>
}
