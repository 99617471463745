import {errorMessage} from './Message'
import client from 'core/client/Client'
export const UPLOAD_SUCCESS = 'UPLOAD_SUCCESS'
export const PROGRESS_UPDATE = 'PROGRESS_UPDATE'

export const uploadSuccess = (video) => {
    return {
        type: UPLOAD_SUCCESS,
        video: video,
    }
}

export const progressUpdate = (progress) => {
    return {
        type: PROGRESS_UPDATE,
        progress: progress,
    }
}

const onProgress = (loaded, total, dispatch) => {
    let progress = 0

    if (total > 0) {
        progress = Math.round(loaded / total * 100)
    }

    return dispatch(progressUpdate(progress))
}

/**
 * Upload video
 * @param file
 * @param options
 * @returns {Function}
 */
export const upload = (file, options) => {
    const clients = {
        jwplayer: jwplayer,
    }
    return (dispatch) => clients[options.client](file, options, dispatch)
}

export const jwplayer = (file, options, dispatch) => {
    const aws = options.aws
    const req = new XMLHttpRequest()

    req.open('put', aws.endpoint, true)
    req.onload = () => {
        client.save('videos', {
            type: 'vod',
            channel_id: options.channel_id,
            user_id: options.user_id,
            external_id: options.external.id
        }, options.token).then((response) => dispatch(uploadSuccess(response.data.data)))
    }
    req.upload.onprogress = (event) => onProgress(event.loaded, event.total, dispatch)
    req.send(file)
}
