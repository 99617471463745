import React, {useEffect} from 'react'
import * as Shared from './shared'
import {connect} from 'react-redux'

const PlayerAdmin = ({user, video, setPlayable, autoplay = false}) => {
    const playable = Boolean(video.status === 'published')

    if (setPlayable) {
        useEffect(() => {
            setPlayable(playable)
        }, [playable])
    }

    return <>
        <Shared.PlayerWithBroadcasting
            autoplay={autoplay}
            playable={playable}
            user={user}
            is_admin
            video_params={{
                id: video.id,
                status: ['published', 'draft'],
                recommended: true,
                details: true,
                resource: 'VideoFull',
                ads: true,
                exclude_hidden_channels: 0,
                exclude_hidden: 0
            }}/>
    </>
}

export default connect(state => {
    return {
        video: state.video,
    }
})(PlayerAdmin)
