import {cdn} from 'core/functions'

const ModalContainerOverlay = {
    alignSelf: 'center',
    backgroundColor: 'colors.white',
    shadowColor: 'rgba(0, 0, 0, 0.4)',
    shadowRadius: 24,
    paddingHorizontal: 20,
    paddingBottom: 30,
    paddingTop: 40,
}

const ModalClose = {
    position: 'absolute',
    zIndex: 1060
}

const Elements = {
    element_image_fluid: {
        maxWidth: '100%',
        height: 'auto',
    },
    element_container_sm: {
        maxWidth: 576,
    },
    element_container_md: {
        maxWidth: 768,
    },
    element_container_lg: {},
    element_scroll_container_parent: {
        width: '100%',
        overflowX: 'scroll',
        position: 'relative',
    },
    ElementScrollItem: {
        marginLeft: 5,
        marginRight: 5,
        width: '50vw',
    },
    element_layout_default: {
        paddingTop: 80,

    },
    element_layout_header: {
        backgroundColor: 'colors.white',
        marginBottom: 'spaces.2',
        position: 'relative',
        zIndex: 999,
    },
    element_layout_space: {
        paddingTop: 170,

    },
    element_h1: {
        fontSize: '2rem', // TODO get this from a fontSizes
        fontFamily: 'fontFamilies.2',
        color: 'colors.blues.400',
        fontWeight: 'normal',
        marginBottom: '.5em',
        lineHeight: '1.25em'
    },
    element_h2: {
        fontSize: '1.5rem', // TODO get this from a fontSizes
        fontFamily: 'fontFamilies.2',
        fontWeight: 'normal',
        color: 'colors.blues.400',
        marginBottom: '.5em',
        lineHeight: '1.25em'
    },
    element_h3: {
        fontSize: '1.25rem', // TODO get this from a fontSizes
        fontFamily: 'fontFamilies.2',
        color: 'colors.blues.400',
        marginBottom: '.5em',
        fontWeight: 'normal',
        lineHeight: '1.25em'
    },
    element_h4: {
        fontSize: '1rem', // TODO get this from a fontSizes
        fontFamily: 'fontFamilies.2',
        color: 'colors.blues.400',
        marginBottom: '.5em',
        fontWeight: 'normal',
        lineHeight: '1.25em'
    },
    ElementText: {
        fontFamily: 'fontFamilies.0',
        color: 'colors.grays.600',
        fontSize: '1rem', // TODO get this from a fontSizes
        lineHeight: '1.5'
    },
    ElementSpan: {

    },
    ElementParaph: {
        marginBottom: '1rem', // TODO get this from a default
        color: 'colors.grays.800'
    },
    ElementTextWithLink: {
        color: 'colors.grays.600',
        display: 'block',
        fontSize: '1rem', // TODO get this from a fontSizes
    },
    ElementLink: {
        color: 'colors.blues.200',
    },
    ElementModal: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1004,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        paddingHorizontal: 20,
        paddingVertical: 60,
        overflowY: 'scroll',
        overflowX: 'hidden',
    },
    element_modal_default: {
        backgroundColor: 'colors.white',
    },
    element_modal_small: {
        backgroundColor: 'colors.blacks.200',
    },
    element_modal_middle: {
        backgroundColor: 'colors.blacks.200',
    },
    element_modal_full: {
        paddingHorizontal: 0,
        paddingVertical: 0,
        backgroundColor: 'colors.white'
    },
    element_modal_closed: {
        visibility: 'hidden',
        opacity: 0,
    },
    element_modal_open: {
        visibility: 'visible',
        opacity: 1,
    },
    ElementModalContainer: {
        width: '100%',
        position: 'relative',
    },
    element_modal_container_default: {
        maxWidth: 1000,
    },
    element_modal_container_small: {
        ...ModalContainerOverlay,
        maxWidth: 400,
    },
    element_modal_container_middle: {
        ...ModalContainerOverlay,
        maxWidth: 540,
    },
    element_modal_container_large: {
        ...ModalContainerOverlay,
        maxWidth: 800,
    },
    element_modal_close_default: {
        ...ModalClose,
        top: 10,
        right: 10,
    },
    element_modal_close_small: {
        ...ModalClose,
        top: 10,
        right: 10,
    },
    element_modal_close_warning: {
        ...ModalClose,
        top: '0.5rem',
        right: '0.5rem',
    },
    ElementHr: {
        display: 'block',
        height: 1,
        width: '100%',
        backgroundColor: '#F3F4F6'
    },
    ElementOldRow: {
        display: 'flex',
        flexWrap: 'wrap',
        marginRight: -10,
        marginLeft: -10,
        flexDirection: 'row',
    },
    ElementOldColumn: {
        flexGrow: 1,
        maxWidth: '100%',
        width: '100%',
        paddingHorizontal: 10,
    },
    ElementRow: {
        // display: 'flex',
        // flexWrap: 'wrap',
        // marginRight: -5,
        // marginLeft: -5,
        flexDirection: 'row',
    },
    ElementColumn: {
        // flexBasis: 0,
        // flexGrow: 1,
        // maxWidth: '100%',
    },
    ElementFilter: {
        marginTop: 'spaces.2',
        maxWidth: 300,
    },
    ElementDropDown: {
        width: '100%',
        position: 'relative',
        alignItems: 'center',
    },
    ElementDropDownButton: {
        borderColor: 'colors.grays.400',
        borderBottomWidth: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        cursor: 'pointer',
        width: '100%',
    },
    ElementDropDownOptions: {
        position: 'absolute',
        top: 55,
        zIndex: 999,
        backgroundColor: 'colors.white',
        width: '100%',
        paddingLeft: 13,
        shadowColor: 'colors.blacks.100',
        shadowOpacity: 0.1,
        shadowOffset: {width: 0, height: 2},
        shadowRadius: 10,
    },
    ElementDropDownOption: {
        fontSize: 'fontSizes.3',
        fontWeight: 'bold',
        paddingVertical: 13,
        color: 'colors.blues.400',
        display: 'block',
    },
    ElementDiv: {
        position: 'static'
    },
    ElementContainer: {
        width: '100%',
        paddingRight: 5,
        paddingLeft: 5,
        marginRight: 'auto',
        marginLeft: 'auto',
    },
    ElementSection: {
        paddingVertical: '1rem' // TODO get this from default padding
    },
    ElementBlockquote: {
        marginHorizontal: 'auto',
        fontSize: '2rem',
        color: 'colors.grays.500',
        lineHeight: 'normal',
        display: 'inline-block',
    },
    ElementContent: {},
    ElementHero: {
        width: '100%',
        backgroundImage: `url(${cdn('img/eyecons-hero-e.png')})`,
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundColor: 'colors.blues.400',
    },
    ElementHeroTitle: {
        paddingTop: 45
    },
    ElementHeroCompetitions: {
        marginBottom: -80,
    },
    ElementHeroPoster: {
        width: '25vw',
        minWidth: 150,
        maxWidth: 300,
    },
    ElementHeroTagline: {
        display: 'block',
        textTransform: 'uppercase',
        color: 'colors.blues.100',
        fontWeight: '600',
        letterSpacing: '.1em',
        textAlign: 'center',
    },
    ElementPayoff: {
        padding: '3rem',
        paddingBottom: '2rem'
    },
    ElementChannel: {
        marginBottom: 'spaces.3',
    },
    ElementActivity: {
        marginTop: 30
    },
    ElementPagination: {
        visibility: 'hidden'
    },
    ElementWarningContainer: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        borderBottomWidth: 1,
        backgroundColor: 'colors.white',
        borderColor: 'colors.pinks.400',
        zIndex: 1040
    },
    ElementWarning: {
        backgroundColor: 'colors.pinks.100',
        padding: '1rem',
    },
    ElementInfo: {
        paddingHorizontal: '1rem'
    },
    ElementBadge: {
        paddingVertical: '.25em',
        paddingHorizontal: '.4em',
        fontSize: '75%',
        fontWeight: '600',
        lineHeight: '1',
        textAlign: 'center',
        whiteSpace: 'nowrap',
        borderRadius: '10rem',
        marginLeft: '0.25rem',
    },
    ElementGobalMessage: {
        position: 'fixed',
        width: 420,
        maxWidth: 'calc(100% - 40px)',
        bottom: 20,
        right: 20,
        backgroundColor: 'colors.white',
        borderColor: 'colors.grays.400',
        borderWidth: 1,
        shadowColor: 'rgba(0, 0, 0, 0.4)',
        shadowRadius: 24,
        paddingVertical: '1rem',
        paddingHorizontal: '3rem',
        borderRadius: '.5rem',
        zIndex: 1010
    },
    ElementModalIcon : {
        position: 'absolute',
        top: 12,
        left: 12,
    }
}

export default Elements
