import React from 'react'
import * as Shared from './shared'

/**
 * Player Simple for pages like /manual and /about
 * @param video
 * @param id
 * @param autoplay
 */
const PlayerSimple = ({user, video, id, autoplay = false}) => <Shared.JwPlayer
    autoplay={autoplay}
    id={id}
    user={user}
    playable={true}
    video={video}/>

export default PlayerSimple
