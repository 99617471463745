import React, {useEffect, useState} from 'react'
import {Fetcher} from 'ra-fetch'
import {connect} from 'react-redux'
import * as Shared from './shared'

const PlayerPayment = (
    {
        paid,
        setPaid,
        user,
        setUser,
        video,
        hls,
        setPlayable,
        video_params,
        autoplay = false,
        is_embed = false
    },
) => {
    const playable = Boolean(!paid.loading && paid.data.owns && video.status === 'published' && !video.hidden)

    if (setPlayable) {
        useEffect(() => {
            setPlayable(playable)
        }, [playable])
    }

    useEffect(() => {
        if(user.id) {
            setPaid({...paid, loading: true})
            Fetcher.api('ecommerce')
                .show('customers.paid.video', {
                    id: user.id,
                    orderable_type: video.has_event ? 'events' : 'videos',
                    orderable_id: video.has_event ? video.event_id : video.id,
                })
                .then(response => setPaid(response))
        }
    }, [user.id])

    return <>
        {
            !paid.loading &&
            video.status === 'published' && <>
                {
                    !paid.data.owns && user.id && <Shared.PlayerPaymentWindow
                        user={user}
                        video={video}
                        is_embed={is_embed}
                    />
                }
                {!user.id && <Shared.PlayerLoginWindow setUser={setUser} video={video}/>}
            </>
        }

        <Shared.PlayerWithBroadcasting
            autoplay={autoplay}
            playable={playable}
            video={video}
            user={user}
            hls={hls}
            video_params={video_params}
        />
    </>
}

export default connect(state => {
    return {
        video: state.video,
        video_params: state.video_params,
    }
})(PlayerPayment)
