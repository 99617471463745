import React, {useState, useEffect} from 'react'
import {VerifyEmailSendPage} from 'core/components'
import {translate} from 'core/functions'
import {useLocale} from 'core/hooks'
import {useApi} from 'ra-fetch'
import {Forms, Texts, Users, Helpers} from '@eyecons/storybook'

function isValidDate(dateString) {
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    return regex.test(dateString);
}

export default function Profile({locale}) {
    const [loading, setLoading] = useState(true)
    const [advertising, setAdvertising] = useState(false)
    const [page, setPage] = useState('register')
    const [user, setUser, submitUser] = useApi('auth').update('user', {
        name: '',
        birthday: '',
        options: {
            advertising_disabled: false
        }
    })
    const [submitting, setSubmitting] = useState(false)

    useEffect(() => {
        const update = JSON.parse(localStorage.getItem('user') || '{}')
        setUser({...user.data, ...update})
        setLoading(false)
        setAdvertising(Helpers.verify_age(update))
    }, [])


    function submit() {
        setSubmitting(true)
        submitUser({locale}).then((response) => {
            setSubmitting(false)
            if (!response.errors.length) {
                localStorage.removeItem('user')
                setPage('verify-email')
            }
        })
    }

    if (loading) {
        return <></>
    }

    if (page === 'verify-email') {
        return <VerifyEmailSendPage locale={locale}/>
    }

    return <>
        <div className={'text-center mb-4'}>
            <Texts.Heading type={'h5'} appendClassname={'mb-4 text-dark'}>
                {translate('One last question..', locale)}
            </Texts.Heading>

            <Texts.Primary className={'text-disabled'}>
                {translate('To comply with the legal guidelines of gambling advertisements, we ask you to fill in some information.', locale)}
            </Texts.Primary>
        </div>

        <Forms.Field>
            <Forms.Label htmlFor={'name'}>{translate('Name', locale)}</Forms.Label>
            <Forms.Input
                id={'name'}
                placeholder={translate('E.g.', locale) + ' Jan'}
                onChange={name => setUser({name})}
                value={user.data.name}
            />
        </Forms.Field>

        <Forms.Field>
            <Users.FormBirthday
                withLabel
                value={user.data.birthday}
                onChange={birthday => {
                    setUser({
                        birthday,
                        options: {
                            ...user.data.options,
                            advertising_disabled: !Helpers.verify_age({birthday})
                        }
                    })

                    if (isValidDate(birthday)) {
                        setAdvertising(Helpers.verify_age({birthday}))
                    }
                }}
            />
        </Forms.Field>

        {
            advertising && <Forms.Field>
                <Forms.Checkbox
                    id={'advertising_disabled'}
                    checked={user.data.options.advertising_disabled}
                    onChange={() => setUser({
                        options: {
                            ...user.data.options,
                            advertising_disabled: !user.data.options.advertising_disabled
                        }
                    })}
                >
                    {translate('I choose an experience without online gambling advertisements.', locale)}
                </Forms.Checkbox>
            </Forms.Field>
        }

        <Forms.Buttons
            icon={'chevron-right'}
            icon_position={'right'}
            type={'wide'}
            disabled={submitting}
            className={'mb-4'}
            errors={user.errors}
            title={`${translate('Update profile', locale)} (2/2)`}
            submit={submit}
        />

        <Texts.Primary className={'text-center text-disabled'}>
            {translate('The age must be entered truthfully.', locale)}
        </Texts.Primary>
    </>
}