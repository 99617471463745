import React from 'react'
import {Ads} from '@eyecons/storybook'
import {config} from 'core/functions'

export function AdsScript() {
    const src = config('advertising.mmcdnScript')
    return <script src={src} async/>
}

export function Ad({type, keywords, className, mobile = 'alpha'}) {
    // TODO if Ads work fine from Storybook remove code below, and ads.scss
    return <Ads.Ad className={className} type={type} mobile={mobile} keywords={keywords}/>

    // if (process.env.NEXT_PUBLIC_STORYBOOK_ADS !== 'true') {
    //     return <></>
    // }

    const leaderboard = type.includes('leaderboard')

    // TODO waar is deze unused const voor?
    const MMDATA = {content: [keywords]}

    return <div className={'videos-ads'}>
        <div className={`videos-ads-wrapper ${leaderboard ? 'videos-ads-leader' : ''} ${className}`}>
            <div className={'storybook-ads-ad'}>
                <div id={`eyecons_ros_alpha_${type ? type : 'rectangle-halfpage'}`}/>
                <div id={`eyecons_ros_${mobile}_mobile`}/>
            </div>
        </div>
    </div>
}
