import React, {useRef} from 'react'
import {Text, ScrollDiv, StyleSheet, Platform} from 'react-native'
import {Picture, Scroll, VideoChannelTitle, ElementDiv} from 'core/components'

export const Layout = props => {
    const container = useRef(null)
    const scrollable = props['horizontal-scroll']
    let paddingTop = props.menu ? 74 : 0
    let paddingBottom = 0

    if (props['space-top']) {
        paddingTop += 20
    }

    if (props['space-bottom']) {
        paddingBottom += 20
    }

    if (props['big-space-bottom']) {
        paddingBottom += 135
    }

    const scroll = scrollable ? {
        overflowX: 'scroll',
    } : {}

    return <ElementDiv
        className={`layout${props.className ? ' ' + props.className : ''}`}
        style={[
            styles.layout,
            props.white ? styles.layout_white : styles.layout_gray,
            {
                paddingTop: paddingTop,
                paddingBottom: paddingBottom,
                minHeight: props['full-height'] ? '100%' : 'auto',
            },
            props.style || {},
        ]}>
        {
            scrollable &&
            <Container2 style={styles.scroll_container}>
                <>
                    <ElementDiv style={styles.title_container}>
                        <VideoChannelTitle {...props.scroll}/>
                    </ElementDiv>
                    <Scroll color={'white'} style={styles} container={container}/>
                </>
            </Container2>
        }
        <div ref={container} style={scroll}>
            {props.children}
        </div>
    </ElementDiv>
}

export const Container = props => (
    <ElementDiv
        {...props}
        className={`container${props.className ? ' ' + props.className : ''}`}
        style={[
            styles.container,
            Platform.OS !== 'web' ? StyleSheet.absoluteFill : {},
            props.gray ? styles.gray : {},
            props.style || {},
        ]}>
        {props.children}
    </ElementDiv>
)

export const Container2 = props => (
    <ElementDiv
        {...props}
        className={`container2${props.className ? ' ' + props.className : ''}`}
        style={[
            Platform.OS !== 'web' ? StyleSheet.absoluteFill : {},
            props.style || {},
        ]}>
        {props.children}
    </ElementDiv>
)

export const Grid = props => (
    <ElementDiv {...props} style={[
        styles.grid, {
            display: props.display || 'flex',
            flexDirection: props.flexDirection || 'row',
            flexWrap: props.wrap || 'nowrap',
            justifyContent: props.justify || 'flex-start',
            alignContent: 'center',
        },
        props.style || {},
    ]}>{props.children}</ElementDiv>
)

export const Row = props => (
    <ElementDiv
        {...props}
        className={`row${props.className ? ' ' + props.className : ''}`}
        style={[styles.row, props.style || {}]}>{props.children}</ElementDiv>
)

export const Column = props => {
    const sizes = ['xs', 'sm', 'md', 'lg', 'xl']
    let className = props.className || ''

    sizes.forEach((size) => {
        if (!props[size]) {
            return
        }
        if (className) {
            className += ' '
        }

        className += `col-old-${size}-${props[size]}`
    })

    return (
        <ElementDiv
            {...props}
            className={`column-old ${className}`}
            style={[styles.col, props.style || {}]}>{props.children}</ElementDiv>
    )
}

export const Label = props => (
    <Text {...props} style={[styles.label, props.style || {}]}>{props.children}</Text>
)

export const TrendingIcon = props => (
    <Picture
        alt={'trending'}
        style={{
            height: 60,
            width: 60,
            marginRight: 10,
            ...props.style,
        }}
        source={'img/fire.png'}/>
)

export const LiveIcon = props => (
    <Picture
        alt={'live'}
        style={{
            height: 40,
            width: 40,
            marginRight: 10,
            ...props.style,
        }}
        source={'img/live.png'}/>
)


const styles = StyleSheet.create({
    layout: {},
    layout_gray: {
        backgroundColor: '#F5F6F7',
    },
    layout_white: {
        backgroundColor: 'white',
    },
    container: {
        width: '100%',
        paddingTop: 70,
    },
    title_container: {
        marginRight: 'auto',
    },
    scroll_container: {
        display: 'flex',
        flexDirection: 'row',
        marginVertical: 20,
        justifyContent: 'flex-end'
    },
    scroll_button: {
        backgroundColor: '#19a4d4',
    },
    scroll_button_left: {
        marginRight: 5,
    },
    scroll_button_right: {
        marginLeft: 5,
    },
    small_container: {
        width: '100%',
        maxWidth: '992px',
        marginRight: 'auto',
        marginLeft: 'auto',
        paddingHorizontal: 15,
    },
    gray: {
        backgroundColor: '#F5F6F7',
        paddingHorizontal: 15,
    },
    header: {
        paddingVertical: 15,
        backgroundColor: 'white',
        paddingHorizontal: 40,
    },
    col: {
        flexGrow: 1,
        maxWidth: '100%',
        position: 'relative',
        width: '100%',
        paddingHorizontal: 10,
    },
    newContent: {
        paddingHorizontal: 10,
    },
    content: {
        paddingTop: 15,
        paddingHorizontal: 15,
        paddingBottom: 25,
    },
    content_lg: {
        paddingBottom: 25,
    },
    grid: {
        width: '100%',
    },
    row: {
        display: 'flex',
        flexWrap: 'wrap',
        marginRight: -10,
        marginLeft: -10,
        flexDirection: 'row',
    },
    label: {
        color: '#747474',
        fontSize: 15,
    },
    content_page: {
        maxWidth: 904,
    },
    description: {
        fontSize: 15,
        color: '#717171',
        lineHeight: 22,
        marginBottom: 20,
    },
    list: {
        marginBottom: 20,
        marginLeft: 30,
    },
    list_item: {
        fontSize: 15,
        color: '#717171',
        lineHeight: 22,
    },
    list_symbol: {
        fontSize: 15,
        color: '#717171',
        lineHeight: 22,
        marginRight: 10,
    },
    italic: {
        fontStyle: 'italic',
    },
})
