import {Fetcher} from 'ra-fetch'
import {cdn, config, video_hls} from 'core/functions'

export default class JWPlayer {
    constructor(user, video) {
        this.video = video
        this.playlist = []
        this.user = user
        this.playlist_current = 1
        this.preview = false
    }

    setPlaylist(hls) {
        if (this.video.ads && !this.preview) {
            this.video.ads.forEach(ad => {
                this.playlist.push({
                    file: video_hls(ad),
                })
            })
        }

        const webVttUrl = `${process.env.NEXT_PUBLIC_STORYBOOK_SERVICE_MEDIA_URL}/video_chapters/${this.video.external_id}/webvtt`

        this.playlist.push({
            file: hls || video_hls(this.video),
            mediaid: this.video.external_id,
            image: this.video.thumbnail_large,
            tracks: [{
                file: webVttUrl,
                kind: 'chapters',
            }],
        })
    }

    options(autoplay) {
        let options = {
            playlist: this.playlist,
            autostart: autoplay,
            skin: {
                url: cdn('css/jwplayer.css'),
                name: 'eyecons',
            },
            related: {
                displayMode: 'none',
            },
            nextUpDisplay: false,
            liveTimeout: 0,
            cast: {
                appid: '5123280D',
            },
        }

        if (this.video.channel && this.video.channel.watermark) {
            options.logo = {
                file: this.video.channel.watermark,
                link: 'https://eyecons.com',
                hide: true,
                position: 'bottom-right',
            }
        }

        if (this.video.playout && !this.preview) {
            options.advertising = {
                client: config('player.ads.client'),
                tag: this.video.playout,
                rules: {
                    frequency: 0,
                },
            }
        }

        return options
    }

    init(player_id, autoplay, hls, preview) {
        this.setPlaylist(hls)
        jwplayer(player_id).setup(this.options(autoplay))
        jwplayer().stopCasting()
        this.preview = preview

        return this
    }

    store(type, weight, amount) {
        let teams = []

        if (this.video.team) {
            teams.push({
                id: this.video.team.id,
                club_id: this.video.team.club.id,
            })
        }

        if (this.video.opponent) {
            teams.push({
                id: this.video.opponent.id,
                club_id: this.video.opponent.club.id,
            })
        }

        Fetcher.api('backend-web').store('events', {
            type,
            weight,
            amount,
            teams,
            paid: Boolean(this.video.paid ? this.video.paid.enabled : false),
            subscription: Boolean(this.video.has_public_plans),
            channel_id: this.video.channel.id,
            sport_id: this.video.sport.id,
            category_id: this.video.category ? this.video.category.id : null,
            video_type: this.video.type,
            owner: this.video.has_event ? 'events' : 'videos',
            video_id: this.video.id,
            user_id: this.user.id,
        })
    }

    events(pause, query = {}) {
        this.play()

        this.cast()
        this.complete(pause)
        this.percentage()
        this.offset(query)

        return this
    }

    offset(query) {
        if (query.start) {
            jwplayer().on('firstFrame', () => {
                jwplayer().seek(query.start)
            })
        }
    }

    play() {
        let played = false
        jwplayer().on('play', play => {
            if (play.playReason === 'playlist') {
                this.playlist_current++
            }

            if (!played && this.playlist_current === this.playlist.length) {
                this.store('play', this.video.type === 'live' ? 3 : 1)
                played = true
            }
        })
    }

    pause(setPlaying) {
        jwplayer().on('pause', () => {
            setPlaying?.(false)
        })

        return this
    }

    cast() {
        jwplayer().on('cast', cast => {
            if (cast.active && this.playlist_current === this.playlist.length) {
                this.store('cast', 2)
            }
        })
    }

    complete(pause) {
        jwplayer().on('complete', () => {
            if (this.playlist_current === this.playlist.length) {
                pause?.()
                this.store('complete', 3)
            }
        })

    }

    percentage() {
        let percentages = [25, 50, 75]
        jwplayer().on('time', (time) => {
            if (this.playlist_current !== this.playlist.length) {
                return
            }

            if (!percentages[0]) {
                return
            }

            const percentage = Math.round((Math.round(time.position) / time.duration) * 100)

            if (percentage >= percentages[0]) {
                const weights = {25: 1, 50: 2, 75: 3}
                const current = percentages.shift()
                this.store('percentage', weights[current], current)
            }
        })
    }

    error(onError) {
        jwplayer().on('error', (error) => {
            onError?.(error)
        })
    }

    static seek(seek) {
        jwplayer().seek(seek)
    }

    static remove() {
        jwplayer().stopCasting()
        jwplayer().remove()
    }
}
