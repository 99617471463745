const Livestreams = {
    LivestreamPlayer: {
        maxWidth: 1000,
        width: '100%',
        position: 'relative',
    },
    LivestreamPlayerChat: {
        width: '100%',
        zIndex: 1,
        position: 'relative',
    },
    LivestreamChat: {
        backgroundColor: 'white',
        boxShadow: 'inset 0px -12px 15px -3px rgba(0,0,0,0.25)',
    },
    LivestreamsDetails: {
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 10,
        paddingBottom: 30,
    },
}

export default Livestreams
